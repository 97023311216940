import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
	ChildElement,
	ParentElement,
	UsersCategories,
} from "../../../../../api/business-page/types";
import Advertising from "../../../../../Components/Сommercial/Advertising";
import { Desktop, Mobile } from "../../../../../responsive";
import { FilterPanel } from "../FilterPanel/FilterPanel";
import { SelectRubrics } from "../SelectRubrics/SelectRubrics";
import styles from "./SellerRubrics.module.css";

type Props = {
	setGrid3: (val: boolean) => void;
	setRow: (val: boolean) => void;
	row: boolean;
	userRubrics: UsersCategories;
	setSort: (value: string) => void;
	setCategoryId: (value: number) => void;
	categoryId: number;
	catIds: number[];
	handleCatIds: (val: number) => void;
};

export const SellerRubrics = (props: Props) => {
	const {
		setGrid3,
		setRow,
		row,
		userRubrics,
		setSort,
		setCategoryId,
		categoryId,
		catIds,
		handleCatIds,
	} = props;
	const { t } = useTranslation();
	const [sorting, setSeeSorting] = useState(false);
	const menuRef: React.Ref<any> = useRef(null);
	const [openFilters, setOpenFilters] = useState<boolean>(false);

	const handleClickFilterButton = () => {
		setOpenFilters(!openFilters);
	};

	const categories = userRubrics?.parent
		? Object.keys(userRubrics.parent).map((parentKey) => {
				const parentCategory: ParentElement = userRubrics.parent[parentKey];

				const subcategories =
					userRubrics.child?.[parentKey]?.map((child: ChildElement) => ({
						id: child.id,
						name: child.name,
						count: parseInt(child.count, 10),
						urlSuffix: child.urlSuffix,
					})) || [];

				return {
					id: parseInt(parentCategory.id, 10),
					name: parentCategory.name,
					count: subcategories.reduce(
						(sum, subcategory) => sum + subcategory.count,
						0
					),
					subcategories,
				};
			})
		: [];

	return (
		<>
			<Mobile>
				<div className={styles.mobileRubrics}>
					<SelectRubrics
						catIds={catIds}
						handleCatIds={handleCatIds}
						categories={categories.map((c) => c.subcategories).flat()}
					/>
					<FilterPanel
						sorting={sorting}
						row={row}
						setRow={setRow}
						setGrid3={setGrid3}
						setSeeSorting={setSeeSorting}
						menuRef={menuRef}
						setSorting={setSort}
						handleClickFilterButton={handleClickFilterButton}
					/>
				</div>
			</Mobile>
			<Desktop>
				<nav className={styles.categoryList}>
					<ul>
						{categories.map((category, index) => (
							<li key={index} className={styles.categoryItem}>
								<p className={styles.category}>{category.name}</p>
								{category.subcategories && (
									<ul className={styles.subcategories}>
										{category.subcategories.map((subcategory, subIndex) => (
											<li
												key={subIndex}
												className={styles.subcategory}
												onClick={() => {
													setCategoryId(
														categoryId === Number(subcategory.id) ? 0 : Number(subcategory.id)
													);
												}}
											>
												<span
													className={`${styles.subcategoryLink} ${
														categoryId === Number(subcategory.id) ? styles.active : ""
													}`}
												>
													{subcategory.name}
												</span>
												{subcategory.count > 0 && (
													<span className={styles.count}>
														{subcategory.count}
													</span>
												)}
											</li>

										))}
									</ul>
								)}
							</li>
						))}
					</ul>
				</nav>
			</Desktop>
			<Desktop>
				<div className={styles.adverts}>
					<Advertising idAD={7} />
				</div>
			</Desktop>
		</>
	);
};