import Badge from "@material-ui/core/Badge";
import { createStyles, withStyles } from "@material-ui/core/styles";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useGetBusinessUserPageData } from "../../../CustomHooks/api/business-page/useBusinessPage";
import { StateType } from "../../../redux/redux-store";
import { setLogoutUser } from "../../../redux/user_Reducer";
import { numberAddSpace } from "../../../supporting";
import PersonalCabinetSvgSelector from "../PersonalCabinetSvgSelector";
import s from "./CabinetNavBarMobile.module.css";
import {
	getStatusClassName,
	getStatusName,
} from "../PersonalCabinetNavBar/PersonalCabinetNavBar";

const CabinetNavBarMobile = () => {
	const { data: businessPageData } = useGetBusinessUserPageData("");
	const [activeAds, setActiveAds] = useState(false);
	//const [activeFavorite, setActiveFavorite] = useState(false);
	const userStatic = useSelector(
		(state: StateType) => state.userData.userStatic
	);
	const staticInfo = userStatic?.userCountNotices[0] || {};
	const dispatch: any = useDispatch();
	const { t } = useTranslation();
	const logout = useSelector(() => setLogoutUser);

	const handleLogout = () => {
		dispatch(logout());
	};

	const StyledBadge = withStyles(() =>
		createStyles({
			badge: {
				width: "21px",
				height: "16px",
				right: 15,
				top: 2,
				paddingBottom: "3px",
				color: "#7D7D7D",
				fontFamily: "var(--myMainFontRegular)",
				fontSize: "14px",
			},
		})
	)(Badge);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<div className={s.mobileBarContainer}>
			<Menu
				menuItemStyles={{
					button: ({ level }) => {
						if (level === 0 || level === 1) {
							return {
								padding: "0px 36px 0px 16px",
								height: "50px",
								"&:hover": {},
							};
						}
					},
					/*subMenuContent:({ level,open }) => {
                                  if (level === 0 || level === 1)
                                  return {
                                      marginLeft:"25px",
                                      paddingLeft:"0",
                                      paddingBottom:"5px",
                                      height:"41px",
                                      // "& li":{
                                      //     borderBottom:"1px solid #DCDCDC",
                                      // }
          
                              }*/
				}}
				className={s.menu}
				renderExpandIcon={({ open }) => (
					<svg
						style={{ rotate: open ? "" : "180deg", transition: "300ms" }}
						width="13"
						height="7"
						viewBox="0 0 13 7"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M0.820312 5.91797L6.37405 1.22356L11.9474 5.91797"
							stroke="#797979"
						/>
					</svg>
				)}
				closeOnClick={true}
			>
				<MenuItem
					// suffix={
					// 	<span className={s.suffixStyle}>
					// 		{/* {userStatic?.userBonus === null
					// 				? numberAddSpace(+userStatic.userBalance)
					// 				: numberAddSpace(
					// 						+userStatic.userBonus + +userStatic.userBalance
					// 					)}{" "}
					// 			грн */}
					// 	</span>
					// }
					component={
						<Link to={`/business-page/`}>
							<span className={s.navMenuItemLeft}>
								{/* <PersonalCabinetSvgSelector id={"businessProfile"} /> */}
								<span>{t("Управління бізнес-профілем")}</span>
							</span>
						</Link>
					}
					icon={<PersonalCabinetSvgSelector id={"businessProfile"} />}
				>
					<span className={s.navMenuItemLeft}>
						{/* <PersonalCabinetSvgSelector id={"businessProfile"} /> */}
						<span>{t("Управління бізнес-профілем")}</span>
					</span>
					<p
						className={`${s.status} ${getStatusClassName(businessPageData?.status ?? "")}`}
					>
						Статус: {getStatusName(businessPageData?.status ?? "")}
					</p>
				</MenuItem>
				<SubMenu
					suffix={
						!activeAds ? (
							<StyledBadge
								overlap="rectangular"
								badgeContent={userStatic?.userCountNotices[0]?.summary || "0"}
								max={99}
							/>
						) : (
							""
						)
					}
					icon={<PersonalCabinetSvgSelector id={"myADs"} />}
					label={t("Мої оголошення")}
					className={s.subMenu}
					open={activeAds}
					onOpenChange={(open) => {
						setActiveAds(!open);
						setActiveAds(open);
					}}
				>
					{" "}
					<div className={s.subMenuUl} style={{ marginLeft: "25px" }}>
						<MenuItem
							suffix={
								<span className={s.suffixStyle}>
									{staticInfo?.active || "0"}
								</span>
							}
							component={<Link to="ads/active" />}
							className={s.subMenuItem}
						>
							{t("Активні")}
						</MenuItem>
						<MenuItem
							suffix={
								<span className={s.suffixStyle}>
									{staticInfo?.waitModer || "0"}
								</span>
							}
							component={<Link to="ads/under_review" />}
							className={s.subMenuItem}
						>
							{t("На перевірці")}
						</MenuItem>
						<MenuItem
							suffix={
								<span className={s.suffixStyle}>
									{staticInfo?.rejected || "0"}
								</span>
							}
							component={<Link to="ads/rejected" />}
							className={s.subMenuItem}
						>
							{t("Відхилені")}
						</MenuItem>
						<MenuItem
							suffix={
								<span className={s.suffixStyle}>
									{staticInfo?.noActive || "0"}
								</span>
							}
							component={<Link to="ads/no_active" />}
							className={s.subMenuItem}
						>
							{t("Неактивні")}
						</MenuItem>
					</div>
				</SubMenu>

				{/* <SubMenu onOpenChange={(open)=>
                        setActiveBalance(open)
                    } open={activeBalance}
                    suffix={!activeAds ?<StyledBadge overlap="rectangular" badgeContent={`${userStatic?.userCountNotices[0]?.summary} грн` || "0"} max={99} /> :""}
                              icon={<PersonalCabinetSvgSelector id={"balance"}/>} label="Баланс"
                              className={s.subMenu}>
                        <div className={s.subMenuUl} style={{marginLeft:"25px",}}>
                            <MenuItem suffix={<span className={s.suffixStyle}>6</span>}
                                      component={<Link to="balance/insurance_amount" />}
                                      className={s.subMenuItem}>Поповнити</MenuItem>
                            <MenuItem suffix={<span className={s.suffixStyle}>6</span>}
                                      component={<Link to="balance" />}
                                      className={s.subMenuItem}>Звіти</MenuItem>
                        </div>
                    </SubMenu>*/}
				<MenuItem
					suffix={
						<span className={s.suffixStyle}>
							{userStatic?.userBonus === null
								? numberAddSpace(+userStatic.userBalance)
								: numberAddSpace(
										+userStatic.userBonus + +userStatic.userBalance
									)}{" "}
							грн
						</span>
					}
					component={<Link to="balance" />}
					icon={<PersonalCabinetSvgSelector id={"balance"} />}
				>
					Баланс
				</MenuItem>
				{/*<div className={s.balanceWrap}>
                        <div className={s.balanceLeftBlock}>
                        <PersonalCabinetSvgSelector id={"balance"}/>
                        <p className={s.balanceTitle}>Баланс</p>
                        </div>
                        <p className={s.balanceValue}>{formattingPrice(userStatic?.userBalance || "0")} грн</p>
                    </div>*/}

				{/*<SubMenu
					onOpenChange={(open) => setActiveFavorite(open)}
					open={activeFavorite}
					suffix={
						!activeFavorite ? (
							<StyledBadge
								overlap="rectangular"
								badgeContent={userStatic?.userCountFavorites}
								max={99}
							/>
						) : (
							""
						)
					}
					icon={<PersonalCabinetSvgSelector id={"favorite"} />}
					label={t("Обрані")}
					className={s.subMenu}
				>
					<div className={s.subMenuUl} style={{ marginLeft: "25px" }}>
						<MenuItem
							suffix={
								<span className={s.suffixStyle}>
									{userStatic?.userCountFavorites}
								</span>
							}
							component={<Link to="favorite" />}
							className={s.subMenuItem}
						>
							{t("Оголошення")}
						</MenuItem>
						<MenuItem
							suffix={<span className={s.suffixStyle}>0</span>}
							//   component={<Link to="balance" />}
							className={s.subMenuItem}
						>
							Візитки
						</MenuItem>
					</div>
				</SubMenu>*/}

				<MenuItem
					icon={<PersonalCabinetSvgSelector id={"favorite"} />}
					className={s.subMenu}
					component={<Link to="recently_watched" />}
				>
					{t("Обрані оголошення")}
				</MenuItem>
				<MenuItem
					icon={<PersonalCabinetSvgSelector id={"seeProducts"} />}
					className={s.subMenu}
					component={<Link to="recently_watched" />}
				>
					{t("Переглянуті товари")}
				</MenuItem>

				<MenuItem
					icon={<PersonalCabinetSvgSelector id={"exitAcc"} />}
					className={`${s.subMenu} ${s.navMenuItemExit}`}
					onClick={handleLogout}
					component={<Link to="/" />}
				>
					{t("Вихід з акаунту")}
				</MenuItem>
			</Menu>
		</div>
	);
};

export default CabinetNavBarMobile;
