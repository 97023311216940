import axios from "axios";
import i18n from "i18next";
import { SomeCategoriesApi } from "../types/categoriesTypes";
import { CreateDataType, UserNoticeListParams } from "../types/noticeTypes";
import { NoticeIndexFilter } from "../types/publicNoticeTypes";
import {
	PaymentGetData,
	userCredentialsType,
	userRegisterType,
	valuesForChangeUserContactsData,
} from "../types/userTypes";

export const instance = axios.create({
	withCredentials: true,
	baseURL: process.env.REACT_APP_API_BASE_URL,

	/*baseURL: 'https://api.bago.ua/v1',*/
	/* baseURL: 'https://api.dev.bago.ua/v1',*/
	/* baseURL: 'https://api.stage.bago.ua/v1',*/
	/* baseURL: 'https://api.bago.loc/v1',*/

	headers: {
		"Content-Type": "multipart/form-data",
	},
});
instance.interceptors.request.use((config) => {
	const token =
		localStorage.getItem("JWTBago") !== "" &&
		localStorage.getItem("JWTBago") !== null
			? localStorage.getItem("JWTBago")
			: sessionStorage.getItem("JWTBago");
	config.headers.Authorization = `Bearer ${token}`;
	config.headers["Accept-Language"] = i18n.language;
	return config;
});

export const userAPI = {
	registration(userData: userRegisterType) {
		return instance.post(`user/registration`, userData);
	},
	login(credentials?: userCredentialsType) {
		return instance.post(`user/login`, credentials);
	},
	authNetwork(params: { code: string; clientName: string }) {
		return instance.post(
			`user/auth/network?code=${params.code}&clientName=${params.clientName}`
		);
	},
	authNetworkApple(
		code: string,
		id_token: string,
		user?: { name: { firstName: string; lastName: string }; email: string }
	) {
		return instance.post(`user/auth/sign-with-apple`, { code, id_token, user });
	},
	authNetworkLink(params: { code: string; clientName: string }) {
		return instance.post(
			`user/auth/link-network?code=${params.code}&clientName=${params.clientName}`
		);
	},
	authNetworkLinkApple(params: { code: string }) {
		return instance.post(`user/auth/link-with-apple`, { code: params.code });
	},
	changeUserEmail(values: { new_email: string }) {
		return instance.post(`user/change-email`, { new_email: values.new_email });
	},
	verifyEmail(codeParam: string) {
		return instance.get(`user/activate?code=${codeParam}`);
	},
	userView() {
		return instance.get(`user/view`, {});
	},
	getUserStatistic() {
		return instance.get(`user-stat/data`);
	},
	changeUserName(values: valuesForChangeUserContactsData) {
		instance.post(`user/messenger`, {
			messenger: "viber",
			active: values.viber === 1 ? 1 : 0,
		});
		instance.post(`user/messenger`, {
			messenger: "telegram",
			active: values.telegram === 1 ? 1 : 0,
		});
		instance.post(`user/messenger`, {
			messenger: "whatsapp",
			active: values.whatsapp === 1 ? 1 : 0,
		});
		return instance.post(`user/change-name`, {
			name: values.name,
			last_name: values?.last_name,
			parent_name: values?.parent_name,
		});
	},
	changeUserPhone(values: { new_phone: string }) {
		return instance.post(`user/change-phone`, { new_phone: values.new_phone });
	},
	approveNewPhone(code: string) {
		return instance.post(`user/approve-new-phone`, { code });
	},
	changeUserPassword(values: { password: string }) {
		return instance.post(`user/change-password`, {
			new_password: values.password,
		});
	},
	changeUserRestricted(values: { is_restricted: number }) {
		return instance.post(`user/restricted`, {
			is_restricted: values.is_restricted === 1 ? 1 : 0,
		});
	},
	changeUserPhoto(values: { photo_file: File | null }) {
		return instance.post(`user/photo`, { photo_file: values.photo_file });
	},
	getSmsCode(phone: string) {
		return instance.post(`/user/get-sms-code`, { phone: phone });
	},
	getEnterSmsCode(code: number, phone: string) {
		return instance.post(`user/enter-sms-code`, { code, phone });
	},
	getForgotPassCode(phone: string, captcha: string) {
		return instance.post(`user/forgot-password`, { phone, captcha });
	},
	setRestoredPass(code: string, password_new: string, sms_code?: string) {
		return instance.post(`user/set-password?code=${code}`, {
			password_new,
			sms_code: sms_code || "",
		});
	},
	addBalance(sum: string) {
		return instance.post(`payment/make-liqpay`, { sum: +sum });
	},
	deleteBagoClient(password?: string) {
		return instance.post(`user/delete`, { password });
	},
	paymentIndex(paymentData?: PaymentGetData) {
		return instance.get(
			`payment/index?${paymentData?.page ? `page=${paymentData.page}` : ""}${
				paymentData?.perPage ? `&per-page=${paymentData.perPage}` : ""
			}`
		);
	},
	applyPromocode(name: string) {
		return instance.post("users-promo/apply", { name });
	},
};
export const categoryAPI = {
	getAllCategories() {
		return instance.get(`category/list-for-notice`);
	},
	getSomeCategories({
		category_id,
		page,
		perPage,
		name,
		active,
		parentId,
		level,
	}: SomeCategoriesApi) {
		return instance.get(
			`category/index?${page ? "page=" + page + "&" : ""}${
				perPage ? "per-page=" + perPage + "&" : ""
			}${name ? "name=" + name + "&" : ""}${
				category_id ? "category_id=" + category_id + "&" : ""
			}${active ? "active=" + active + "&" : ""}${
				parentId ? "parent_id=" + parentId + "&" : ""
			}${level ? "level=" + level + "&" : ""}`
		);
	},
	getRubricsList(withLinks: string) {
		return instance.get(`category/rubric-list?withLinks=${withLinks}`);
	},
	getCategoryFilters(categoryId: number | null) {
		return instance.get(`category/filters?id=${categoryId}`);
	},
	getCategoryDependFilters(categoryId: number | null,propertyValueId: number | null) {
		return instance.get(`category/depended-filters?id=${categoryId}&propertyValueId=${propertyValueId}`);
	},
	getCategoryBlocks(categoryId: number | null) {
		return instance.get(`category/blocs?id=${categoryId}`);
	},
	getCategoryView(categoryId: number, expand?: string | string[]) {
		const expandUrl = Array.isArray(expand)
			? `&expand=${expand.map((l: string) => l)}`
			: `&expand=${expand}`;
		return instance.get(`category/view?id=${categoryId}${expandUrl}`);
	},
	getCategoryViewBySlug(slug: string, expand?: string | string[]) {
		const expandUrl = Array.isArray(expand)
			? `&expand=${expand.map((l: string) => l)}`
			: `&expand=${expand}`;
		return instance.get(`category/view-by-slug?slug=${slug}${expandUrl}`);
	},
};
export const settlementAPI = {
	getSettlementsSearch(searchLetters: string, format?: number) {
		return instance.get(
			`settlement/search?q=${searchLetters}${format ? "&format=" + format : ""}`
		);
	},
	getSettlementByLocation(location: { latitude: string; longitude: string }) {
		return instance.get(
			`settlement/search-by-coordinates?latitude=${location.latitude}&longitude=${location.longitude}`
		);
	},
	getAllDistrict(perPage: string, page?: string, name?: string) {
		return instance.get(
			`area/index?per-page=${perPage}${page ? `&page=${page}` : ""}${name ? `&description=${name}` : ""}`
		);
	},
	getSettlementIndex(onlyCities?: number, description?: string | null) {
		return instance.get(
			`settlement/index?${description ? `&description=${description}` : ""}${
				onlyCities ? `&onlyCities=${onlyCities}` : ""
			}`
		);
	},
};
export const publicNotice = {
	getAllNoticesByUserId(userId: number, perPage: number, page: number, sortType?:string, category_id?: number, catIds?: number[]) {
		return instance.get(`notice/index`, {
			params: {
				users_id: userId,
				"per-page": perPage,
				page,
				not_show_other: 0,
				sortType,
				category_id,
				catIds
			},
		});
	},
	getAllPublicNotices(type?: number) {
		if (type) {
			return instance.get(`notice/index?type=${type}`);
		}
		return instance.get(`notice/index`);
	},
	getPublicNoticeView(id?: string, itemSlug?: string, expand?: string) {
		if (id) {
			return instance.get(`notice/view?id=${id || ""}`);
		} else if (itemSlug) {
			return instance.get(
				`notice/view-by-slug?slug=${itemSlug || ""}&expand=notice_properties,ownerContacts,seeNow,ownerBPData`
			);
		}
	},
	getFilteredNotices({
		category_id,
		minPrice,
		maxPrice,
		page,
		perPage,
		type,
		sortType,
		settlement_id,
		onlyFavorite,
		onlyViewed,
		catIds,
		filters,
		filtersRange,
		name,
		saveQuery,
		areaId,
		sortByState,
		onlyTop,
		excludeTop,
	}: NoticeIndexFilter) {
		const catIdsLink =
			(catIds &&
				catIds
					.map((id: number) => (id === 0 ? "" : `catIds%5B%5D=${id}`))
					.join("&")) ||
			"";
		const filtersLink =
			(filters &&
				filters
					.map((id: any) => id !== 0 && `filters%5B%5D=${id}`)
					.join("&")) ||
			"";
		const params = [
			category_id ? `category_id=${category_id}` : "",
			sortType ? `sortType=${sortType}` : "",
			sortByState && sortByState !== "0" ? `state=${sortByState}` : "",
			settlement_id ? `settlement_id=${settlement_id}` : "",
			areaId ? `areaId=${areaId}` : "",
			minPrice !== undefined ? `minPrice=${minPrice}` : "",
			maxPrice !== undefined ? `maxPrice=${maxPrice}` : "",
			page ? `page=${page}` : "",
			perPage ? `per-page=${perPage}` : "",
			saveQuery && saveQuery.trim() ? `saveQuery=${saveQuery}` : "",
			onlyFavorite ? `onlyFavorite=${Number(onlyFavorite)}` : "",
			onlyViewed ? `onlyViewed=${Number(onlyViewed)}` : "",
			filtersRange ? filtersRange : "",
			name ? `name=${name}&searchModeWithCategories=1` : "",
			onlyTop === 1 && type !== 1 ? `onlyTop=${onlyTop}` : "",
			type !== undefined && (onlyTop !== 1 || type === 1) ? `type=${type}` : "",
			excludeTop ? `excludeTop=${excludeTop}` : "",
		]
			.filter(Boolean)
			.join("&");

		// Добавляем catIdsLink и filtersLink только если params не пуст
		const queryString = params
			? `${params}${catIdsLink ? `&${catIdsLink}` : ""}${filtersLink ? `&${filtersLink}` : ""}`
			: `${catIdsLink || ""}${filtersLink || ""}`;

		return instance.get(`notice/index?${queryString}`);

		/*if(isSearch) {
            return instance.get(`notice/index?${category_id
                ?"category_id=" + category_id + "&" : ""}${sortType
                ? "sortType=" + sortType + "&" : ""}${sortByState && sortByState !=="0"
                ? "state=" + sortByState + "&" : ""}${settlement_id
                ? "settlement_id=" + settlement_id + "&" : ""}${areaId
                ? "areaId=" + areaId + "&" : ""}${minPrice !==undefined
                ? "minPrice=" + minPrice + "&" : ""}${maxPrice !==undefined
                ? "maxPrice=" + maxPrice + "&" : ""}${page
                ? "page=" + page + "&" : ""}${perPage
                ? "per-page=" + perPage + "&" : ""}${name
                ? "name=" + name + "&"+"searchModeWithCategories=1&" : ""}${type && onlyTop !==1
                ? "type=" + type + "&" : ""}${saveQuery && saveQuery !==""
                ? "saveQuery=" + saveQuery + "&" : ""}${onlyFavorite
                ? "onlyFavorite=" + +onlyFavorite + "&" : ""}${onlyViewed
                ? "onlyViewed=" + +onlyViewed + "&" : ""}${filtersRange
                ? filtersRange + "&" : ""}
                ${catIdsLink && catIdsLink}${filtersLink && filtersLink}`)
        } else {
            return instance.get(`notice/index?${category_id
                ?"category_id=" + category_id + "&" : ""}${sortType
                ? "sortType=" + sortType + "&" : ""}${sortByState && sortByState!=="0"
                ? "state=" + sortByState + "&" : ""}${settlement_id
                ? "settlement_id=" + settlement_id + "&" : ""}${areaId
                ? "areaId=" + areaId + "&" : ""} ${minPrice !==undefined
                ? "minPrice=" + minPrice + "&" : ""}${maxPrice !==undefined
                ? "maxPrice=" + maxPrice + "&" : ""}${page
                ? "page=" + page + "&" : ""}${perPage
                ? "per-page=" + perPage + "&" : ""}${type && onlyTop !==1 && type !==0
                ? "type=" + type + "&" : ""}${saveQuery && saveQuery !==""
                ? "saveQuery=" + saveQuery + "&" : ""}${onlyFavorite
                ? "onlyFavorite=" + +onlyFavorite + "&" : ""}${onlyViewed
                ? "onlyViewed=" + +onlyViewed + "&" : ""}${filtersRange
                ? filtersRange + "&" : ""}${name
                ? "name=" + name + "&"+"searchModeWithCategories=1&" : ""}${onlyTop
                ? "onlyTop=" + onlyTop + "&" : ""}${catIdsLink && catIdsLink}${filtersLink && filtersLink}`)

        }*/
	},
	addOrRemoveNoticeInFavorite(id: number, action: string) {
		if (action === "add") {
			return instance.post(`notice/add-to-favorite?id=${id}`);
		} else {
			return instance.post(`notice/delete-from-favorite?id=${id}`);
		}
	},
	deleteAllFromFavorite() {
		return instance.post(`notice/delete-all-from-favorite`);
	},
	deleteAllFromRecentlyWatched() {
		return instance.post(`notice/delete-all-from-viewed`);
	},
};
export const userNotice = {
	createUpdateNotice(createData: CreateDataType, type: string) {
		return instance.post(`/user-notice/${type}`, createData);
	},
	getMeNotice({ page, perPage, status, catIds }: UserNoticeListParams) {
		const catIdsLink =
			(catIds &&
				catIds
					.map((id: number) => id !== 0 && `catIds%5B%5D=${id}`)
					.join("&")) ||
			"";
		return instance.get(
			`user-notice/index?expand=categoryBlocs&${
				page ? "page=" + page + "&" : ""
			}${perPage ? "per-page=" + perPage + "&" : ""}${
				status ? "status=" + status + "&" : ""
			}${catIdsLink}`
		);
	},
	sendPhoto(photo: File, noticeType?: number | string) {
		return instance.post(`user-notice/store-file`, { image: photo, noticeType: noticeType });
	},
	removePhotoTmp(name: string) {
		return instance.post(`user-notice/delete-file`, { name: name });
	},
	/*getNoticeViewById(id: number) {
        return instance.get(`user-notice/view?id=${id}`)
    },*/
	getEditNoticeData(id: number) {
		return instance.get(`user-notice/data-for-edit?id=${id}`);
	},
	changeOneField(id: number, fieldName: string, price: string) {
		return instance.post(`user-notice/change-one-field?id=${id}`, {
			fieldName: fieldName,
			value: price,
		});
	},
	editOnlyAutoContinue(id: number, auto_continue: number) {
		return instance.post(`user-notice/auto-continue?id=${id}`, {
			auto_continue: auto_continue,
		});
	},
	getImagesByURL(url: string) {
		return fetch(url);
	},
	changeUserNoticeStatus(id: number, status: number) {
		return instance.post(`user-notice/change-stay?id=${id}`, { stay: status });
	},
	deleteNotice(id: string) {
		return instance.post(`user-notice/delete?id=${id}`);
	},
	buyService(noticeId: number, serviceId: number) {
		return instance.post(`user-notice/buy-service?id=${noticeId}`, {
			servicePriceId: serviceId,
		});
	},
	offService(noticeId: number) {
		return instance.post(`user-notice/stop-auto-lift?id=${noticeId}`);
	},
};
export const pagesSeo = {
	getSeoPages() {
		return instance.get(`seo-pages/index`);
	},
};
export const mainSearch = {
	getNoticesAndCatByWord(letters: string) {
		return instance.get(`notice/search?q=${letters}`);
	},
	getHistorySearches(letters?: string) {
		return instance.get(
			`/users-query/get-search-queries${letters ? `?q=${letters}` : ""}`
		);
	},
	clearHistorySearches() {
		return instance.post(`users-query/clear-search-queries`);
	},
	getSearchPlace() {
		return instance.get(`users-place-query/get-search-queries`);
	},
	clearHistoryPlaces() {
		return instance.post(`users-place-query/clear-search-queries`);
	},
};
export const staticPageAPI = {
	getPageForFooter() {
		return instance.get("/simple-page/index?placeId=10");
	},
	getOnePage(slug: string) {
		return instance.get(`/simple-page/view?slug=${slug}&expand=text`);
	},
};
export const autoSelectionAPI = {
	callAutoSelection(values: string) {
		return instance.get(`/user-notice/category-hint?q=${values}`);
	},
};
export const userComplaints = {
	getComplaints() {
		return instance.get(`/complaint/list`);
	},
	createComplaints(
		mainId: number,
		category_id: number,
		cause_id: number,
		describe: string,
		users_id: string
	) {
		if (users_id) {
			return instance.post(`/complaint/create`, {
				category_id,
				to_users_id: users_id,
				cause_id,
				describe,
			});
		} else if (mainId) {
			return instance.post(`/complaint/create`, {
				to_notice_id: mainId,
				category_id,
				cause_id,
				describe,
			});
		}
	},
};
export const advertising = {
	getAdvertising(id: number, isMobile?: 1) {
		return instance.get(`/advert-bloc/index?siteId=${id}
        ${isMobile ? `&isMobile=${isMobile}` : ""}`);
	},
};
export const priceAPI = {
	getIndexPrice() {
		return instance.get(`price/index`);
	},
};
