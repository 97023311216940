import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable } from "react-beautiful-dnd";
import { FileRejection, useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import StrictModeDroppable from "../../../Components/DropZone/StrictModeDroppable";
import CabinetAccordionSvgSelector from "../../../Components/PersonalCabinet/PersonalCabinetAccordion/CabinetAccordionSvgSelector";
import fish from "../../../images/itemCardImage.jpg";
import {
	removeImagesToTmp,
	sendImagesToTmp,
	setImagesList,
} from "../../../redux/notice_Reducer";
import { StateType } from "../../../redux/redux-store";
import s from "../CreateBoardAD.module.css";
import { type Image } from "../../../types/noticeTypes";

type Props = {
	setFieldValue: (
		field: string,
		value: any,
		shouldValidate?: boolean | undefined
	) => void;
	singleImage?: boolean;
};

const ChangePhotoDropZone: React.FC<Props> = ({ setFieldValue, singleImage = false }) => {
	const [error, setError] = useState("");
	const { t } = useTranslation();
	const dispatch: any = useDispatch();
	const images = useSelector((state: StateType) => state.noticeData.images);
	const imagesTmp = useSelector(
		(state: StateType) => state.noticeData.tmpImages
	);


	// Store object URLs in images and avoid recreating them
	const onDrop = (acceptedFiles: File[], fileRejections: FileRejection[]) => {
		const filteredFile = fileRejections.find(file =>
			file.errors.length === 1 && file.errors[0].code === 'too-many-files'
		);
		const files: File[] = singleImage && filteredFile
			? [filteredFile.file]
			: acceptedFiles;
		const newImages = files.map((file: File) => {
			return {
				id: new Date().getTime().toString() + Math.random(),
				file,
			} as Image;
		});

		const updatedImages = singleImage ? newImages : [...images, ...newImages];
		const noticeType = singleImage ? 1 : 0 // 1 - WORK notice type, 0 - default
		dispatch(sendImagesToTmp(newImages, setError, images, noticeType));
		dispatch(setImagesList(updatedImages));
		setFieldValue("imageNames", updatedImages);
	};

	const { getRootProps, getInputProps } = useDropzone({
		onDrop,
		accept: {
			"image/png": [".png"],
			"image/jpg": [".jpg"],
			"image/jpeg": [".jpeg"],
			"image/gif": [".gif"],
			"image/webp": [".webp"],
		},
		maxFiles: singleImage ? 1 : undefined,
	});

	const removeImage = (id: string) => {
		const imageToRemove = images.find((image) => image.id === id);
		if (imageToRemove && imageToRemove.imageUrl) {
			URL.revokeObjectURL(imageToRemove.imageUrl); // Revoke object URL to prevent memory leaks
		}
		const newImages = images.filter((image) => image.id !== id);
		dispatch(setImagesList(newImages));
		setFieldValue("imageNames", newImages);

		const removableImg = imagesTmp.find((img) => img.tmpFileName === id);
		if (removableImg) {
			dispatch(removeImagesToTmp(removableImg.tmpFileName, setError));
		}
	};

	const onDragEnd = (result: any) => {
		if (!result.destination) return;
		const newImages = Array.from(images);
		const [movedImage] = newImages.splice(result.source.index, 1);
		newImages.splice(result.destination.index, 0, movedImage);
		dispatch(setImagesList(newImages));
		setFieldValue("imageNames", newImages);
	};

	const clearZone = () => {
		dispatch(setImagesList([]));
		setFieldValue("imageNames", []);
	};

	// Clear dropzone when change from multiple to single image
	useEffect(() => {
		if (singleImage && images.length > 1) {
			clearZone();
		}
	}, [singleImage]);

	// Cleanup object URLs when component unmounts
	useEffect(() => {
		return () => {
			images.forEach((image) => {
				if (image.imageUrl) {
					URL.revokeObjectURL(image.imageUrl);
				}
			});
		};
	}, [images]);

	return (
		<>
			<div className={s.dropZoneContainer}>
				<div {...getRootProps({ className: s.dropZoneTop })}>
					<div className="dropzone">
						<input {...getInputProps()} />
						<p className={s.dropZoneInfo}>
							{t("Перетягніть фотографії сюди.")}
						</p>
					</div>
					<div className={s.checkPhotoSvgContainer}>
						<CabinetAccordionSvgSelector id={"checkPhoto"} />
						<span className={s.svgDescription}>{t("Додати фото")}</span>
					</div>
				</div>
				{images.length > 0 && !singleImage && (
					<div className={s.photoDescription}>
						{t(
							`Перша фотографія буде головною, щоб змінити порядок - пересуньте фото.`
						)}
					</div>
				)}
				<DragDropContext onDragEnd={onDragEnd}>
					<StrictModeDroppable droppableId="imageListDesc">
						{(provided) => (
							<div
								{...provided.droppableProps}
								ref={provided.innerRef}
								className={s.imageList}
								id={"imageList"}
							>
								{images.map((image, index) => (
									<Draggable
										key={image.id}
										draggableId={image.id}
										index={index}
									>
										{(provided, snapshot) => (
											<div
												ref={provided.innerRef}
												{...provided.draggableProps}
												{...provided.dragHandleProps}
												className={`${s.imageItem} ${
													snapshot.isDragging ? "dragging" : ""
												}`}
											>
												<div className={s.imageContainer}>
													<img
														src={
															image?.file instanceof File
																? URL.createObjectURL(image?.file)
																: fish
														}
														alt="image"
														onClick={(e) => {
															e.stopPropagation();
														}}
													/>
												</div>
												<button
													className={s.deleteButton}
													onClick={(e) => {
														e.stopPropagation();
														removeImage(image.id);
													}}
												>
													{t("Видалити")}
												</button>
											</div>
										)}
									</Draggable>
								))}
								{provided.placeholder}
							</div>
						)}
					</StrictModeDroppable>
				</DragDropContext>
			</div>
			{error && (
				<span className={s.error}>
					{error || t("Помилка під час завантаження файлу")}
				</span>
			)}
		</>
	);
};

export default ChangePhotoDropZone;
