import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { NoticeFilterType } from "../../types/noticeTypes";
import { valuesCreateAD } from "../../types/userTypes";
import style from "./FilterComponent.module.css";
import s from "../../pages/CreateBoardAD/CreateBoardAD.module.css";
import ArrowsAndCross from "../../common/ArrowsAndCross/ArrowsAndCross";
import Select from "react-select";
import useFilterHandle from "../../CustomHooks/useFilterHandle";

type Props = {
	filter: NoticeFilterType | any;
	filtersOneIs: [];
	values?: valuesCreateAD;
};
const FilterTypeDropdownSelect = ({ filter, filtersOneIs }: Props) => {
	const { i18n, t } = useTranslation();
	const defaultValue: any = filtersOneIs.find(
		(e: { id: string; name: string; filter: string }) =>
			e.filter === filter.inputName
	);
	const defOption = filter.propertyValues.find((propertyValue: any) =>
		propertyValue.id === defaultValue?.id ? defaultValue.id : ""
	);
	const defValue = [
		{
			label:
				i18n.language === "ru" ? defOption?.name_ru : defOption?.name || "",
			value: defOption?.id || "",
		},
	];
	const { handleFilter } = useFilterHandle();
	const [value, setValue] = useState(defValue);
	const options = filter.propertyValues.map((e: any) => ({
		label: i18n.language === "ru" ? e.name_ru : e.name,
		value: e.id,
		filter: filter.inputName,
		apiUrlDependedFilters:e.apiUrlDependedFilters
	}));

	return (
		<div className={`${style.container} ${style.dropdownSelect}`}>
			<div className={style.name}>
				{!filter?.needs ? (
					filter?.propertyName
				) : (
					<p>
						{filter?.propertyName} <span style={{ color: "red" }}>*</span>
					</p>
				)}
			</div>
			<div className={style.selectContainer}>
				<Select
					isClearable={false}
					isSearchable={true}
					options={options}
					isMulti={filter?.type !== 1}
					value={value}
					onChange={(newValue: any) => {
						setValue([{ label: newValue.label, value: newValue.value }]);
						handleFilter({
							element: {
								id: newValue.value,
								name: newValue.label,
								name_ru: newValue.label,
								apiUrlDependedFilters:newValue.apiUrlDependedFilters
							},
							filter,
						});
					}}
					components={{
						IndicatorSeparator: () => null,
						DropdownIndicator: ({ selectProps }) => (
							<span
								className={s.dropdownArrow}
								style={{
									transform: selectProps.menuIsOpen ? "rotate(180deg)" : "",
									/* marginTop: selectProps.menuIsOpen ? "5px" : "",*/
									transition: "400ms",
								}}
							>
								<ArrowsAndCross id={"arrowForSelect"} />
							</span>
						),
					}}
					/*placeholder={`${t('Введіть назву міста на рідній мові')}...`}*/
					styles={{
						control: (baseStyles) => ({
							...baseStyles,
							padding: "0 15px 0 18px ",
							borderColor: "#E7E7E7",
							maxWidth: "100%",
							maxHeight: "40px",
							fontSize: "16px",
							borderRadius: "4px",
						}),
						singleValue: (styles) => {
							return {
								...styles,
								borderRadius: "4px",
								fontFamily: "var(--myMainFontRegular)",
								fontSize: "16px",
								color: "#727272",
							};
						},
						input: (styles) => {
							return {
								...styles,
								margin: "0",
								padding: "0",
							};
						},
						option: (base) => ({
							...base,
							boxShadow: "0px 4px 30px rgba(0, 0, 0, 0.06)",
							borderRadius: "5px",
						}),
					}}
				/>
			</div>
		</div>
	);
};

export default FilterTypeDropdownSelect;
