import React, { useEffect, useState } from "react";
import s from "./SearchFilters.module.css";
import { useTranslation } from "react-i18next";
import {
	Filter,
	FiltersValue,
	PropertyValues,
} from "../../types/publicNoticeTypes";
import FilterFrontHead from "./FilterFrontHead/FilterFrontHead";
import useSearchFilterHandle from "../../CustomHooks/useSearchFilterHandle";
import useClearSearchFilter from "../../CustomHooks/useClearSearchFilter";

type Props = {
	filter: Filter;
	setFiltersValues: any;
	filtersValues: FiltersValue[];
	showOpened: number;
	setLastChangedFilter: (b: number) => void;
	lastChangedFilter: number;
};
const FilterFrontButton = ({
	filter,
	filtersValues,
	setFiltersValues,
	showOpened,
	setLastChangedFilter,
	lastChangedFilter,
}: Props) => {
	const { i18n, t } = useTranslation();
	const {handleClickSearchFilter} = useSearchFilterHandle()
	const {clearFilter} = useClearSearchFilter(filtersValues,filter,setFiltersValues)
	const [isExpanded, setExpanded] = useState(showOpened === 1);

	const [isNeedClear, setIsNeedClear] = useState(false);
	const checked = filtersValues.findIndex(
		(f: FiltersValue) => f.filter === filter.inputName
	);
	useEffect(() => {
		if (checked !== -1) {
			setIsNeedClear(true);
		} else {
			setIsNeedClear(false);
		}
	}, [checked]);
	return (
		<div
			className={s.filterContainer}
			style={{
				background: isExpanded ? "#F8F8F8" : "",
			}}
		>
			<FilterFrontHead
				name={filter.propertyName}
				parentName={filter?.parent_name}
				isExpanded={isExpanded}
				setExpanded={setExpanded}
				isNeedClear={isNeedClear}
				clearFilter={clearFilter}
			/>
			{isExpanded && (
				<div className={s.searchResult}>
					<ul
						className={`${s.sizeList} ${s.grid}`}
						style={{ gridTemplateColumns: `repeat(${filter.columns},1fr)` }}
					>
						{filter?.propertyValues.length &&
							filter.propertyValues.map((value: PropertyValues) => {
								const isActive = filtersValues.find(
									(f: FiltersValue) =>
										f.filter === filter.inputName &&
										f.value === value.id.toString()
								);
								// if(+value.countItem.countItems > 0 || filter.id ===lastChangedFilter)
								return (
									<li
										className={`${s.resultListItem} ${isActive ? s.active : ""}`}
										key={value.id}
									>
										<button
											onClick={() => {
												setLastChangedFilter(filter.id);
												handleClickSearchFilter(
													+value?.id || 0,
													filtersValues,
													filter,
													setFiltersValues
												);
											}}
										>
											{i18n.language === "ru" ? value.name_ru : value.name}
										</button>
										<span className={s.buttonCounter}>
											{!isActive ? value.countItem.countItems : ""}
										</span>
										{/*{counters.map((c:CounterType)=>{
                                  if(+c.propertyValueId === +value.id){
                                      return <span className={s.buttonCounter} key={c.propertyId}>{c.countItems}</span>
                                  }
                                  else {
                                      return <span key={c.propertyId}></span>
                                  }
                              })}*/}
									</li>
								);
							})}
					</ul>
				</div>
			)}
			{/*<div className={s.bottomPanel}>
              <span>Показати все</span>
            </div>*/}
		</div>
	);
};

export default FilterFrontButton;
