import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Pagination } from "swiper";
import s from "./CategoryBoardSecondLevel/CategoryBoardFirstLevel.module.css";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { StateType } from "../../redux/redux-store";
import {
	setThirdCategoryList,
	getAllRubricsList,
	setThorCategoryList,
	getViewThirdCategory,
} from "../../redux/category_Reducer";
import { useNavigate } from "react-router-dom";
import CreateBoardADSvg from "../../pages/CreateBoardAD/Components/CreateBoardADSvg";
import TopTitleBlock from "../TopTitleBlockMobileAllPages/TopTitleBlock";
import { OneRubricType } from "../../types/categoriesTypes";

const ShowCategoryTemplate = ({
	item,
	handleClickElement,
}: {
	item: any;
	handleClickElement: any;
}) => {
	return (
		<div className={s.catalogItem} onClick={() => handleClickElement(item)}>
			<div className={s.imageContainer}>
				<img src={item?.icon || ""} alt="icon" />
			</div>
			<p>{item?.name || ""}</p>
		</div>
	);
};

const CategorySecondLevelSlider = ({
	categoriesList,
}: {
	categoriesList: [];
}) => {
	const [isOpen, setIsOpen] = useState(false);
	const { i18n } = useTranslation();
	const thirdStepElementsFromRedux = useSelector(
		(state: StateType) => state.categoryData.thirdLevelCategories
	);
	const currentCategory = useSelector(
		(state: StateType) => state.categoryData.view
	);
	const set3LevelCategories = useSelector(() => setThirdCategoryList);
	const dispatch: any = useDispatch();
	const navigate = useNavigate();
	const getAllCategories = useSelector(() => getAllRubricsList);
	const set4LevelCategories = useSelector(() => setThorCategoryList);
	const arrayRubrics = useSelector(
		(state: StateType) => state.categoryData.allRubrics
	);
	//const [currentCategoryInfo, setCurrentCategoryInfo] = useState({name: "", alias: "", id: ""})
	const [thirdCategoryAlias, setThirdCategoryAlias] = useState("");
	const [thirdStepElements, setThirdStepElements] = useState([]);
	const [openList, setOpenList] = useState<number | null>(null);
	const thirdCategory = useSelector(
		(state: StateType) => state.categoryData.thirdCategoryView
	);
	const [breadCrumbsLinks, setBreadCrumbsLinks] = useState<any[]>([]);
	const [category3levelPath, setCategory3levelPath] = useState<any>("");
	const getThirdCategory = useSelector(() => getViewThirdCategory);

	const handleClick = (r: OneRubricType) => {
		if (r.isForNotice === "0" && r.filter_link !== null) {
			handleClickElement(r);
		} else if (r.link_alias !== null) {
			const alias = r.link_alias;
			const language = i18n.language === "ru" ? "" : "/ua";
			navigate(
				`${language}/c/${currentCategory?.alias}/c2${
					Boolean(thirdCategory) ? "/" + thirdCategory?.alias : "/" + alias
				}${
					thirdCategoryAlias
						? "/" + thirdCategoryAlias + "/" + alias
						: "/" + alias
				}`
			);
		} else if (r.isForNotice !== "0") {
			handleClickElement(r);
		}
	};

	const handleClickElement = (item: any) => {
		if (item.filter_link !== null) {
			window.open(item.filter_link, "_blank");
		} else if (item.link_cat_id !== null && item.links_data) {
			const updateTree = item.links_data.tree.slice(1);
			handleRedirectToParents(updateTree);
		} else if (item.has_children_active !== 0 && item.is_last_level === 0) {
			handleClickCategory(item.id.toString(), item.name, item.alias);
		} else {
			const alias = item.alias;
			const language = i18n.language === "ru" ? "" : "/ua";
			navigate(
				`${language}/c/${currentCategory?.alias}/c2${
					Boolean(thirdCategory) ? "/" + thirdCategory?.alias : "/" + alias
				}${thirdCategoryAlias ? "/" + thirdCategoryAlias + "/" + alias : "/" + alias}`
			);
		}
	};

	const handleRedirectToParents = (tree: string[]) => {
		const noticeUrl = tree?.map((s: string) => s).join("/");
		navigate(`c2/${noticeUrl}`);
	};
	const handleClickCategory = (id: number | null, name: string, alias: string) => {
		dispatch(set3LevelCategories(id));
		dispatch(set4LevelCategories(null));
		navigate(`c2/${alias}`);
	};

	useEffect(() => {
		setBreadCrumbsLinks([
			{
				name: currentCategory.name,
				alias: `${currentCategory.alias}`,
				active: true,
			},
		]);
		const parts = location.pathname.split("/");
		// Ищем индекс метки 'c2'
		const indexC2 = parts.indexOf("c2");
		if (indexC2 !== -1 && indexC2 + 1 < parts.length) {
			setCategory3levelPath(parts[indexC2 + 1]);
			dispatch(getThirdCategory("", parts[indexC2 + 1]));
			if (thirdCategory?.id) {
				dispatch(set3LevelCategories(thirdCategory?.id ?? ""));
				setBreadCrumbsLinks([
					{
						name: currentCategory.name,
						alias: `${currentCategory.alias}`,
						active: false,
					},
					{
						name: thirdCategory?.name ?? "",
						alias: thirdCategory?.alias ?? "",
						active: true,
					},
				]);
			}
		}
	}, [location.pathname, thirdCategory?.id, currentCategory.alias]);

	useEffect(() => {
		dispatch(getAllCategories("1"));
	}, [thirdStepElementsFromRedux, i18n.language]);

	/* function chunkArray(array: OneCategoryType[], chunkSize: number): any {
    return Array.from(
      { length: Math.ceil(array.length / chunkSize) },
      (_, index) =>
        array.slice(index * chunkSize, index * chunkSize + chunkSize),
    );
  }*/

	// const filteredCategoriesListBy18Plus = categoriesList.filter((item: OneCategoryType) => item.plus_18 === 0)

	const handleThirdStep = (rubric: OneRubricType) => {
		const data = arrayRubrics.level4.filter(
			(r2: OneRubricType) => r2.parent_id === rubric?.id
		);
		setThirdCategoryAlias(rubric?.alias);
		setThirdStepElements(data);
		checkedLastCategory(+rubric?.isForNotice);

		if (openList == rubric?.id) {
			setOpenList(0);
		} else {
			setOpenList(rubric?.id);
		}
	};

	const checkedLastCategory = (isLast: number) => {
		if (isLast) {
			setIsOpen(false);
		}
	};

	if (category3levelPath) {
		return (
			<>
				<TopTitleBlock
					title={currentCategory?.name || ""}
					handleBack={setCategory3levelPath}
				/>
				<div className={s.categorySwiperContainer}>
					{thirdStepElementsFromRedux.map((item: OneRubricType) => (
						<div key={item?.id}>
							<div
								className={s.compainListItemWrap}
								onClick={() => {
									handleThirdStep(item);
									if (
										item?.isForNotice == "1" ||
										item.link_alias !== null ||
										item?.filter_link
									) {
										handleClick(item);
									}
								}}
							>
								<p className={s.compainListItem_text}>{item.name}</p>
								<div
									className={`${s.arrowSvg} ${openList == item.id && s.arrowSvgActive}`}
								>
									{!(
										item?.isForNotice == "1" ||
										item.link_alias !== null ||
										item?.filter_link
									) && <CreateBoardADSvg id="arrowDown" />}
								</div>
							</div>
							<div>
								{openList == item.id &&
									thirdStepElements.map((list: OneRubricType) => (
										<div
											key={list.id}
											className={s.openListItemWrap}
											onClick={() => handleClick(list)}
										>
											<p className={s.openListItem}>{list.name}</p>
										</div>
									))}
							</div>
						</div>
					))}
				</div>
			</>
		);
	}

	return (
		<>
			<TopTitleBlock title={currentCategory?.name || ""} />
			<div className={s.categorySwiperContainer}>
				<Swiper
					slidesPerView={4}
					slidesPerGroup={4}
					spaceBetween={20}
					freeMode={true}
					pagination={{
						clickable: true,
					}}
					modules={[FreeMode, Pagination]}
					className={"swiperForMainCategory"}
				>
					{categoriesList?.map((item: any) => (
						<SwiperSlide key={item.id} className={s.swiperSlide}>
							<ShowCategoryTemplate
								item={item}
								handleClickElement={handleClickElement}
							/>
						</SwiperSlide>
					))}
				</Swiper>
			</div>
		</>
	);
};

export default CategorySecondLevelSlider;
