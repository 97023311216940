import s from "./ListOutputTemplateForProductCardMobile.module.css";
import SearchCardSvgSelector from "../Cards/FavoriteSearchCard/SearchCardSvgSelector";
import { Link } from "react-router-dom";
import { formattingPrice } from "../../supporting";
import { useTranslation } from "react-i18next";
import FavoriteHeartSelector from "../Cards/FavoriteHeartSelector/FavoriteHeartSelector";
import { useSelector } from "react-redux";
import { StateType } from "../../redux/redux-store";

const CardTemplate = ({ notice }: { notice?: any }) => {
	const userView = useSelector((state: StateType) => state.userData.user);
	const noticeTree = notice?.tree?.map((s: string) => s);
	const noticeUrl = noticeTree?.join("/");
	const { t } = useTranslation();

	return (
		<div key={notice?.id} className={s.сardContainer}>
			<Link
				to={`/o/${noticeUrl ? noticeUrl + "/" : ""}${notice?.slug}`}
				className={s.cardImage}
			>
				{notice?.imagePath || notice?.noticeImages?.length ? (
					<img
						src={notice?.imagePath || notice?.noticeImages?.[0]?.originalImageUrl}
						alt={notice?.slug || "img"}
					/>
				) : (
					// <></>
					<></>
				)}
				{notice?.top_days && (
					<div className={s.topMobileFlag}>
						<SearchCardSvgSelector id={"topFlagLittle"} />
					</div>
				)}
			</Link>

			<div className={s.infoContainer}>
				<div className={s.cardName}>
					<h4>{notice?.name || ""} </h4>
				</div>
				<div className={s.cardPrice}>
					{notice?.price && notice.price !== "0.00"
						? `${formattingPrice(notice?.price)} грн`
						: t("Безкоштовно")}
				</div>
				<div className={s.cardLocation}>
					<span>
						{notice?.short_settlement_name || notice?.settlement_name || ""}
					</span>
					<div className={s.topHeart}>
						{(notice?.users_id || notice?.ownerId) &&
						+(+notice?.users_id || +notice?.ownerId) !== userView.id ? (
							<FavoriteHeartSelector
								isFavorite={notice?.isFavorite || 0}
								id={+notice?.id || +notice?.notice_id || 0}
								userId={+notice?.users_id || +notice?.ownerId || 0}
							/>
						) : (
							<></>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default CardTemplate;
