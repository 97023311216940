import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import { userAPI } from "../../../api/api";
import privat from "../../../images/privat.jpg";
import { StateType } from "../../../redux/redux-store";
import { getAddBalance, setUserPaymentList } from "../../../redux/user_Reducer";
import {
	Desktop,
	Mobile,
	MobileBalanceTableMax,
	MobileBalanceTableMin,
} from "../../../responsive";
import { numberAddSpace } from "../../../supporting";
import { OnePaymentInfoElement } from "../../../types/userTypes";
import MonthYearFull from "../../TimeAndDate/MonthYearFull";
import OnlyTime from "../../TimeAndDate/OnlyTime";
import PublishedYear from "../../TimeAndDate/PublishedYear";
import SecondsToDate from "../../TimeAndDate/SecondsToDate";
import PersonalCabinetSvgSelector from "../PersonalCabinetSvgSelector";
import s from "./MyBalance.module.css";
import MyBalanceTint from "./MyBalanceTint/MyBalanceTint";
import { Promocode } from "./Promocode/Promocode";
import PurposeOfPayment from "./PurposeOfPayment";

type Data = {
	pages: PaymentResponse[];
	items: any;
};

const MyBalance = ({ type }: { type: string }) => {
	const [inputSum, setInputSum] = useState("");
	const [errorInputSum, setErrorInputSum] = useState<string | null>(null);
	const [expanded, setExpanded] = useState(false);
	const [isApplyPromocode, setIsApplyPromocode] = useState(false);
	const [openTint, setOpenTint] = useState(false);
	const { i18n, t } = useTranslation();
	const userStatic = useSelector(
		(state: StateType) => state.userData.userStatic
	);
	const userId = useSelector((state: StateType) => state.userData.user.id);
	const paymentList = useSelector(
		(state: StateType) => state.userData.paymentList
	);
	const addBalance = useSelector(() => getAddBalance);
	const setPayments = useSelector(() => setUserPaymentList);
	const dispatch: any = useDispatch();

	const fetchPayments = async (page = 1, perPage = 50) => {
		const { data } = await userAPI.paymentIndex({
			page: page,
			perPage: perPage,
		});
		return data?.payments;
	};

	const { data, fetchNextPage, hasNextPage, refetch } = useInfiniteQuery({
		queryKey: ["payments"],
		initialPageParam: 1,
		queryFn: ({ pageParam }) => fetchPayments(pageParam),
		enabled: expanded,
		getNextPageParam: (lastPage, allPages) => {
			const currentPage = allPages.length;
			return currentPage < lastPage?._meta.pageCount
				? currentPage + 1
				: undefined;
		},
	});

	const loadMore = () => {
		fetchNextPage();
	};

	useEffect(() => {
		if (expanded) {
			dispatch(setPayments(data));
		}
	}, [expanded, data]);

	const handleBalance = () => {
		if (+inputSum > 0) {
			dispatch(addBalance(inputSum));
			setInputSum("");
			setErrorInputSum(null);
		} else {
			setErrorInputSum(t("Введіть суму"));
		}
	};
	const handleInputChange = (event: any) => {
		let newValue = event.target.value;
		// Заменяем запятые на точки
		newValue = newValue.replace(/,/g, ".");
		// Удаляем все символы, кроме цифр и точек
		newValue = newValue.replace(/[^\d.]/g, "");
		// Ограничиваем длину строки до 11 символов
		newValue = newValue.slice(0, 11);
		// Отделяем целую и дробную части
		const [integerPart, decimalPart = ""] = newValue.split(".");
		// Ограничиваем количество символов после точки до 2
		const limitedDecimalPart = decimalPart.slice(0, 2);
		// Формируем новое значение
		let formattedValue = `${integerPart}${limitedDecimalPart ? "." : ""}${limitedDecimalPart}`;
		// Обновляем состояние с отформатированным значением

		if (formattedValue.length > 9) {
			formattedValue = formattedValue.slice(0, 9);
		}
		// Ограничиваем общее количество символов до 9
		setInputSum(formattedValue);
	};

	return (
		<div className={s.myBalanceContainer}>
			<div className={s.myBalanceInfoCardContainer}>
				<div className={s.cardTitle}>Баланс</div>
				<div className={s.infoPanel}>
					<Desktop>
						<div className={s.infoPanelLeft}>
							Ваш ID: <span className={s.userId}>{userId}</span>
						</div>
					</Desktop>
					<Mobile>
						<div className={s.infoPanelLeft}>
							<div>Ваш ID:</div>
							<div className={s.userId}>{userId}</div>
						</div>
					</Mobile>
					<div className={s.infoPanelRight}>
						<div className={s.optionNames}>
							<div className={s.optionNames_name}>{t("Приватний рахунок")}</div>
							<div className={s.optionNames_name}>{t("Бонуси")}</div>
							{/*<div className={s.optionNames_insurance}>
                                <Link to={"/personal_cabinet/balance/insurance_amount"}>{t("Страхова сума")}:</Link>
                            </div>*/}
						</div>
						<div className={s.optionsData}>
							<div className={s.optionsData_sum}>
								<span>{numberAddSpace(+userStatic?.userBalance)} грн</span>
								{/*  <PersonalCabinetSvgSelector id={"questionMark"}/>*/}
							</div>
							<div className={s.optionsData_bonuses}>
								<span>
									{userStatic.userBonus === null
										? "0"
										: numberAddSpace(+userStatic?.userBonus)}{" "}
									бнс
								</span>
								{/*<PersonalCabinetSvgSelector id={"questionMark"}/>*/}
							</div>
						</div>
					</div>
				</div>
				<div className={`${s.infoPanelRight} ${s.infoPanelRightSecond}`}>
					<div className={s.optionNames}>
						<div className={s.totalPaymentInfoText}>{t("Ваш баланс")}</div>
					</div>
					<div className={s.optionsData}>
						<div className={s.totalPaymentInfoNumbers}>
							{userStatic?.userBonus === null
								? numberAddSpace(+userStatic.userBalance)
								: numberAddSpace(
										+userStatic.userBonus + +userStatic.userBalance
									)}{" "}
							грн
							<div
								title={"info"}
								className={s.infoQuestionMark}
								onClick={() => setOpenTint(!openTint)}
							>
								<PersonalCabinetSvgSelector id={"questionMark!"} />
							</div>
						</div>
					</div>
				</div>
			</div>
			{
				type === "main" && (
					<>
						<div className={s.blockGreyWrapper}>
							{openTint && <MyBalanceTint close={setOpenTint} />}
							<div className={s.topUpAccount}>
								<span>{t("Поповнити Особистий рахунок")}</span>
								<div
									className={`${s.inputContainer} ${errorInputSum ? s.errorInputSum : ""}`}
								>
									<input
										value={inputSum}
										type={"number"}
										placeholder={"0"}
										onChange={(e) => handleInputChange(e)}
									/>
									<span className={s.errorInputSum}>{errorInputSum}</span>
								</div>{" "}
								грн
							</div>

							<div className={s.payMethodeContainer}>
								<button
									onClick={handleBalance}
									className={`${s.payButton} ${s.payButtonOne}`}
								>
									<span className={s.payButton_logo}>
										<img src={privat} alt="visa" />
									</span>
									<span className={s.payButton_TopUp}>{t("Поповнити")}</span>
								</button>
								{/*<button onClick={handleBalance} className={s.payButton}>
                  <span className={s.payButton_logo}>
                      <img src={visa} alt="visa"/>
                  </span>
                            <span className={s.payButton_info}>
                        <span className={s.payButton_infoTitle}>VISA/Mastercard</span>
                        <span className={s.payButton_infoSum}>Сумма: <span
                            className={s.payButton_infoSumNumber}>{inputSum} грн.</span></span>
                    </span>
                        </button>*/}
								{/*<button onClick={handleBalance} className={s.payButton}>
                  <span className={s.payButton_logo}>
                      <img src={privat} alt="visa"/>
                  </span>
                            <span className={s.payButton_info}>
                        <span className={s.payButton_infoTitle}>Приват24</span>
                        <span className={s.payButton_infoSum}>Сумма: <span
                            className={s.payButton_infoSumNumber}>{inputSum} грн</span></span>
                    </span>
                        </button>*/}
								{/* <button onClick={handleBalance} className={s.payButton}>
                            <span className={s.payButton_logo}>
                                <img src={mono} alt="visa"/>
                                </span>
                            <span className={s.payButton_info}>
                        <span className={s.payButton_infoTitle}>Монобанк</span>
                        <span className={s.payButton_infoSum}>Сумма:
                            <span className={s.payButton_infoSumNumber}>{inputSum} грн</span>
                        </span>
                    </span>
                        </button>*/}
							</div>
							<div className={s.borderLineElement} />
							<Promocode setIsApplyPromocode={() => refetch()} />
							<div className={s.seeReportContainer}>
								<Accordion expanded={expanded}>
									<AccordionSummary
										onClick={() => setExpanded(!expanded)}
										style={{ padding: "0 23px" }}
										className={s.accordionSummary}
										expandIcon={
											<span>
												<PersonalCabinetSvgSelector id={"accordionArrow"} />
											</span>
										}
										id="panel1a-header"
									>
										<div className={s.reportTableTop}>
											<h4 onClick={() => setExpanded(!expanded)}>
												{t("Переглянути звіти")}
											</h4>
											{/*<div className={s.reportTableTopSelects}>
                                        <Select defaultValue={{
                                            value: "1",
                                            label: "Рiк"
                                        }} components={{
                                            IndicatorSeparator: () => null,
                                        }}
                                                className={s.tableTopSelect}/>
                                        <Select defaultValue={{
                                            value: "2",
                                            label: "Мiсяць"
                                        }} components={{
                                            IndicatorSeparator: () => null,
                                        }}
                                                className={s.tableTopSelect}/>
                                    </div>*/}
										</div>
									</AccordionSummary>
									<AccordionDetails className={s.accordionDetails}>
										<div>
											<div className={s.reportTableTitle}>
												<div
													className={`${s.tableSubElement} ${s.tableTitleData}`}
												>
													{t("Дата")}
												</div>
												<MobileBalanceTableMin>
													<div
														className={`${s.tableSubElement} ${s.tableTitleTransaction}`}
													>
														{t("Транзакція")}
													</div>
												</MobileBalanceTableMin>

												<div
													className={`${s.tableSubElement} ${s.tableTitleSum}`}
												>
													{t("Сума")}
												</div>
												<MobileBalanceTableMin>
													<div
														className={`${s.tableSubElement} ${s.tableTitleName}`}
													>
														{t("Призначення")}
													</div>
												</MobileBalanceTableMin>
											</div>
											{paymentList.length > 0 &&
												paymentList?.map((monthItem: any) => (
													<div key={monthItem}>
														<div className={s.monthTableContainer}>
															<MonthYearFull seconds={monthItem[0]} />
														</div>
														{monthItem[1]?.map((pay: OnePaymentInfoElement) => {
															const links: any = pay?.additionalData?.tree;
															const link = links
																?.map((l: any) => l.alias)
																.join("/");
															return (
																<>
																	<div
																		className={`${s.reportTableContent}`}
																		key={pay.id + Math.random()}
																	>
																		<div
																			className={`${s.tableSubElement} ${s.tableInfoDataContainer}`}
																		>
																			<div className={s.tableInfoTime}>
																				<OnlyTime seconds={pay.created_at} />
																			</div>
																			<div className={s.tableInfoData}>
																				<SecondsToDate
																					seconds={pay.created_at}
																					full={true}
																				/>{" "}
																				<PublishedYear
																					seconds={pay.created_at}
																				/>
																			</div>
																		</div>
																		<MobileBalanceTableMin>
																			<div
																				className={`${s.tableSubElement} ${s.tableInfoTransaction}`}
																			>
																				{pay?.id}
																			</div>
																		</MobileBalanceTableMin>
																		<div
																			className={`${s.tableSubElement} ${s.tableInfoSum} ${s[pay?.type]} ${s[pay?.balanceType]}`}
																		>
																			{pay.type === "minus" ? "-" : ""}{" "}
																			{numberAddSpace(+pay?.sum)}
																			{pay.balanceType === "bonus"
																				? " бнс"
																				: " грн"}
																		</div>
																		<MobileBalanceTableMin>
																			<PurposeOfPayment pay={pay} link={link} />
																		</MobileBalanceTableMin>
																	</div>
																	<MobileBalanceTableMax>
																		<PurposeOfPayment pay={pay} link={link} />
																		<div
																			className={`${s.tableSubElement} ${s.tableTitleTransaction}`}
																		>
																			<div>{t("Транзакція")}</div>
																			<div>ID: {pay?.id}</div>
																		</div>
																	</MobileBalanceTableMax>
																</>
															);
														})}
													</div>
												))}
											<div className={s.buttonContainer}>
												{hasNextPage && (
													<button onClick={loadMore} disabled={!hasNextPage}>
														{t("Дивитися ще")}
													</button>
												)}
											</div>
											<div className={s.reportTableSavePanel}>
												{/*<PersonalCabinetSvgSelector id={"saveElement"}/>*/}
											</div>
										</div>
									</AccordionDetails>
								</Accordion>
							</div>
						</div>
					</>
				)
				/* :
                         <div className={s.topUpInsuranceAmountContainer}>
                             <div className={s.transferIn}>
                                 <h4>Перевести з балансу на страхову суму</h4>
                                 <span>
                                 <input
                                     onChange={(event) => setTransferIn(event.currentTarget.value)}
                                     value={formattingPrice(transferIn)}/>грн</span>
                                 <button>перевести</button>
                             </div>
                             <div className={s.transferOut}>
                                 <h4>Перевести зі страхової суми на баланс</h4>
                                 <span>
                                 <input
                                     onChange={(event) => setTransferOut(event.currentTarget.value)}
                                     value={formattingPrice(transferOut)}/>грн</span>
                                 <button>Перевести</button>
                             </div>
                             <div className={s.exitButtonContainer}>
                                 <button onClick={() => navigate(-1)}>Повернутися назад</button>
                             </div>
                         </div>*/
			}
		</div>
	);
};

export default MyBalance;
