import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	getFilteredNoticeList,
	getTopInRubrics,
	setFilteredNoticesAllList,
} from "../../redux/publicNotice_Reducer";
import { Pagination, StateType } from "../../redux/redux-store";
import { Desktop, Mobile } from "../../responsive";
import { publicOneNotice } from "../../types/publicNoticeTypes";
import ProductCardGrid from "../Cards/FavoriteSearchCard/ProductCardGrid";
import MobileProductCard from "../Cards/ProductCard/MobileProductCard";
import ExpandButton from "../ExpandButton/ExpandButton";
import ExpandModernButton from "../ExpandButton/ExpandModernButton";
import s from "./TopAdverts.module.css";

type Props = {
	title: string;
	categoryId: string;
	noticesPag?: Pagination;
	onClick?: () => void;
	notices?: publicOneNotice[];
};

export const TopAdverts = ({
	title,
	notices,
	onClick,
	noticesPag,
	categoryId,
}: Props) => {
	const [isExpanded, setExpanded] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const noticesAllList = useSelector(
		(state: StateType) => state.publicNotice.filteredNoticesAllList
	);
	const dispatch: any = useDispatch();
	const getTopInThisRubrics = useSelector(() => getTopInRubrics);

	useEffect(() => {
		if (notices && notices.length) {
			if (currentPage === 0 || currentPage === 1) {
				dispatch(setFilteredNoticesAllList(notices));
			} else {
				const newArr = [...noticesAllList, ...notices];
				dispatch(setFilteredNoticesAllList(newArr));
			}
		}
	}, [notices]);

	return (
		<div className="__container">
			<div className={`${s.newAdvertsContainer} `}>
				<h5>{title}</h5>
				<div className={s.newAdvertsContent}>
					<Desktop>
						{noticesAllList.map((i) => (
							<div key={i.id}>
								<ProductCardGrid grid={"3"} notice={i} />
							</div>
						))}
					</Desktop>
				</div>
				<div className={s.bestsellersContainerTopPanel}>
					<Mobile>
						{noticesAllList.map((i) => (
							<MobileProductCard notice={i} key={i.id} />
						))}
					</Mobile>
				</div>
				{noticesPag &&
					noticesPag.currentPage !== noticesPag.pageCount &&
					noticesPag.pageCount > 1 && (
						<div className={s.newAdvertsBottomPanel}>
							<Mobile>
								<ExpandModernButton
									onClickCallback={() => {
										dispatch(
											getTopInThisRubrics({
												page: `${currentPage + 1}`,
												category_id: +categoryId,
												perPage: "4",
											})
										);
										setCurrentPage((state) => state + 1);
									}}
								/>
							</Mobile>
							<Desktop>
								<ExpandButton
									isExpanded={isExpanded}
									setExpanded={setExpanded}
									onClick={() => {
										dispatch(
											getTopInThisRubrics({
												page: `${currentPage + 1}`,
												category_id: +categoryId,
												perPage: "4",
											})
										);
										setCurrentPage((state) => state + 1);
									}}
								/>
							</Desktop>
						</div>
					)}
			</div>
		</div>
	);
};
