import React, { useState } from "react";
import s from "./CategoryBoardFirstLevel.module.css";
import ArrowsAndCross from "../../../common/ArrowsAndCross/ArrowsAndCross";
import RubricsSvgSelector from "../../NewRubricsBoardSelector/RubricsSvgSelector";
import { useDispatch, useSelector } from "react-redux";
import { StateType } from "../../../redux/redux-store";
import { setThorCategoryList } from "../../../redux/category_Reducer";
import { Route, Routes, useNavigate } from "react-router";
import { OneCategoryType } from "../../../types/reduxStoreTypes";
import { useParams } from "react-router-dom";
import { OneRubricType } from "../../../types/categoriesTypes";

type Props = {
	thirdCategory: OneCategoryType | null;
	thirdStepElements: OneRubricType[];
	handleClickElement: (b: any, c: boolean) => void;
	setThirdCategoryAlias: (b: string) => void;
	currentCategory: OneCategoryType;
};
const CategoriesThirdThorStep = ({
	thirdCategory,
	thirdStepElements,
	handleClickElement,
	setThirdCategoryAlias,
	currentCategory,
}: Props) => {
	const dispatch: any = useDispatch();
	const [activeID, setActiveID] = useState<number | null>(null);
	const thorStepElements = useSelector(
		(state: StateType) => state.categoryData.thorLevelCategories
	);
	const set4LevelCategories = useSelector(() => setThorCategoryList);
	const navigate = useNavigate();
	const params = useParams();

	const handleClick = (r: OneRubricType) => {
		if (r.isForNotice === "0" && r.filter_link !== null) {
			handleClickElement(r, false);
		} else if (r.link_alias !== null) {
			const alias = r.link_alias;
			navigate(
				`/c/${currentCategory?.alias}/c2${Boolean(thirdCategory) ? "/" + thirdCategory?.alias : "/" + alias}${"/" + alias}`
			);
		} else if (r.isForNotice !== "0") {
			handleClickElement(r, false);
		}
	};
	return (
		<div className={s.subCotegoriesContainer}>
			<Routes>
				<Route
					path={`c2/:category2Name`}
					element={
						<div>
							<div className={s.categoriesTop}>
								<div className={s.categoriesTopTitle}>
									{thirdCategory?.name || ""}
								</div>
								<div className={s.categoriesTopClose}>
									<span
										onClick={() => {
											navigate(`${params?.slug ?? ""}`);
										}}
									>
										<ArrowsAndCross id={"closeCross"} />
									</span>
								</div>
							</div>
							<div className={s.categoriesRow}>
								<ul className={s.categoriesColumn}>
									{thirdStepElements.map((r: OneRubricType) => {
										return (
											<li
												key={r.id}
												className={`${s.categoriesItem} ${r.id === activeID ? s.active : ""}`}
												onClick={() => handleClick(r)}
												onMouseEnter={() => {
													dispatch(set4LevelCategories(r.id));
													setThirdCategoryAlias(r.alias);
													setActiveID(r.id);
												}}
											>
												<span className={s.name}>{r.name}</span>
												{r.isForNotice === "0" &&
													r.filter_link === null &&
													r.link_cat_id === null && (
														<RubricsSvgSelector id={"arrow"} />
													)}
											</li>
										);
									})}
								</ul>
								<ul className={s.categoriesColumn}>
									{thorStepElements.map((r: OneRubricType) => {
										return (
											<li
												key={r.id}
												onClick={() => handleClick(r)}
												className={s.categoriesItem}
											>
												<span className={s.name}>{r.name}</span>
											</li>
										);
									})}
								</ul>
							</div>
						</div>
					}
				/>
			</Routes>
		</div>
	);
};

export default CategoriesThirdThorStep;
