import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import style from "../CreateBoardADMobile.module.css";

interface Props {
	setFieldValue: any;
	values: any;
	setIsEditing?: (isEditing: boolean) => void;

}

const DontShowOtherAds: React.FC<Props> = ({ setFieldValue, values, setIsEditing }) => {
	const { t } = useTranslation();
	const [notShowOther, setNotShowOther] = useState(values.not_show_other);

	useEffect(() => {
		setNotShowOther(values.not_show_other);
	}, [values.not_show_other]);

	const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (setIsEditing) setIsEditing(true); 
		const newValue = e.currentTarget.checked ? 1 : 0;
		setNotShowOther(newValue);
		setFieldValue("not_show_other", newValue);
		if (setIsEditing) setIsEditing(false); 
	};

	const checkboxId = `checkBox_${values.noticeId}`;

	return (
	  <div className={style.otherAdsWrap}>
		 <input
			id={checkboxId}
			className={style.createAdCheckbox}
			checked={notShowOther}
			type="checkbox"
			onChange={handleCheckboxChange}
		 />
		 <label className={style.otherAds} htmlFor={checkboxId}>
			{t("Не показувати це оголошення з іншими оголошеннями автора")}
		 </label>
	  </div>
	);
};

export default DontShowOtherAds;
