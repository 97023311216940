import { ActionType } from "../types/reduxStoreTypes";
import { settlementAPI } from "../api/api";
import { Dispatch } from "redux";
import {
	OneDistrict,
	SearchingLocations,
	settlementDataType,
} from "../types/settlementTypes";

const SET_ALL_SETTLEMENT_LIST =
	"bago/settlementReducer/SET_ALL_SETTLEMENT_LIST";
const SET_SETTLEMENT_INDEX = "bago/settlementReducer/SET_SETTLEMENT_INDEX";
const SET_SETTLEMENT_BY_COORDINATES =
	"bago/settlementReducer/SET_SETTLEMENT_BY_COORDINATES";
const SET_SEARCHING_LOCATION_INFO =
	"bago/settlementReducer/SET_SEARCHING_LOCATION_INFO";
const SET_ALL_DISTRICT_LIST = "bago/settlementReducer/SET_ALL_DISTRICT_LIST";

const initialState: settlementDataType = {
	status: "",
	settlementByLocation: [],
	settlementByLocationOnlyDistrict: { id: "", district: "" },
	settlements: [],
	settlementsForSearch: [],
	searchingLocationInfo: { id: "", city: "", district: "" },
	allDistrictList: [],
	settlementIndex: [],
};

const settlement_Reducer = (state = initialState, action: ActionType) => {
	switch (action.type) {
		case SET_ALL_SETTLEMENT_LIST:
			type ActionItem = { id: string; text: string };
			const newOptions = action.data.map((item: ActionItem) => ({
				value: item.id,
				label: item.text,
			}));
			const formattingLocation = action.data.map((item: ActionItem) => {
				const parts = item.text.split(",").map((part) => part.trim());
				return {
					id: item.id,
					city: parts[0] || "",
					district: parts[1] || "",
				};
			});
			return {
				...state,
				settlements: newOptions,
				settlementsForSearch: formattingLocation,
			};
		case SET_SETTLEMENT_BY_COORDINATES:
			const currentUserLocation = action.data.map((item: any) => ({
				value: item.id,
				label: item.text,
			}));
			const districtsLocation = action.data;

			return {
				...state,
				settlementByLocation: currentUserLocation,
				settlementByLocationOnlyDistrict: {
					id: districtsLocation[0]?.area_id || "",
					district: districtsLocation[0]?.center_name || "",
				},
			};
		case SET_SEARCHING_LOCATION_INFO:
			return {
				...state,
				searchingLocationInfo: action.data,
			};
		case SET_ALL_DISTRICT_LIST:
			return {
				...state,
				allDistrictList: action.data,
			};
		case SET_SETTLEMENT_INDEX:
			return {
				...state,
				settlementIndex: action.data,
			};
		default:
			return state;
	}
};

export const setSettlementList = (data: any) => ({
	type: SET_ALL_SETTLEMENT_LIST,
	data,
});
export const setSettlementByLocation = (data: any) => ({
	type: SET_SETTLEMENT_BY_COORDINATES,
	data,
});
export const setSearchingLocationInfo = (data: SearchingLocations) => ({
	type: SET_SEARCHING_LOCATION_INFO,
	data,
});
export const setAllDistrictList = (data: OneDistrict[]) => ({
	type: SET_ALL_DISTRICT_LIST,
	data,
});
export const setSettlementIndexList = (data: any) => ({
	type: SET_SETTLEMENT_INDEX,
	data,
});

export const getSettlementList =
	(searchLetters: string, format?: number) => async (dispatch: Dispatch) => {
		try {
			const response: any = await settlementAPI.getSettlementsSearch(
				searchLetters,
				format
			);

			if (response.data?.settlements) {
				dispatch(setSettlementList(response.data?.settlements));
			}
		} catch (error: any) {
			console.log(error.message);
		}
	};
export const getSettlementIndex =
	(
		onlyCities?: number,
		description?: string | null,
		page?: string,
		perPage?: string
	) =>
	async (dispatch: Dispatch) => {
		try {
			const response: any = await settlementAPI.getSettlementIndex(
				onlyCities,
				description
			);
			if (response.data.items) {
				dispatch(setSettlementIndexList(response.data.items));
			}
		} catch (error: any) {
			console.log(error.message);
		}
	};

export const getSettlementByLocation =
	(location: { latitude: string; longitude: string }) =>
	async (dispatch: Dispatch) => {
		try {
			const response: any =
				await settlementAPI.getSettlementByLocation(location);
			if (response.data?.settlements) {
				dispatch(setSettlementByLocation(response.data?.settlements));
			}
		} catch (error: any) {
			console.log(error.message);
		}
	};
export const getAllDistrictList =
	(perPage: string, page?: string, name?: string) =>
	async (dispatch: Dispatch) => {
		try {
			const response: any = await settlementAPI.getAllDistrict(
				perPage,
				page,
				name
			);
			if (response.data?.items) {
				dispatch(setAllDistrictList(response.data?.items));
			}
		} catch (error: any) {
			console.log(error.message);
		}
	};

export default settlement_Reducer;
