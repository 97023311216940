import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	getFilteredNoticeList,
	setFilteredNoticesAllList,
} from "../../redux/publicNotice_Reducer";
import { Pagination, StateType } from "../../redux/redux-store";
import { Desktop, Mobile } from "../../responsive";
import { publicOneNotice } from "../../types/publicNoticeTypes";
import ProductCardGrid from "../Cards/FavoriteSearchCard/ProductCardGrid";
import MobileProductCard from "../Cards/ProductCard/MobileProductCard";
import ExpandButton from "../ExpandButton/ExpandButton";
import ExpandModernButton from "../ExpandButton/ExpandModernButton";
import s from "./NewAdverts.module.css";

type Props = {
	title: string;
	noticesPag?: Pagination;
	onClick?: () => void;
	notices?: publicOneNotice[];
};

const NewAdverts = ({ title, notices, onClick, noticesPag }: Props) => {
	const [isExpanded, setExpanded] = useState(false);
	const [currentPage, setCurrentPage] = useState(0);
	const getNotices: any = useSelector(() => getFilteredNoticeList);
	const noticesAllList = useSelector(
		(state: StateType) => state.publicNotice.filteredNoticesAllList
	);
	const dispatch: any = useDispatch();

	useEffect(() => {
		if (notices && notices.length) {
			if (currentPage === 0 || currentPage === 1) {
				dispatch(setFilteredNoticesAllList(notices));
			}
		}
	}, [notices]);

	return (
		<div className={`${s.newAdvertsContainer} __container`}>
			<h5>{title}</h5>
			<div className={s.newAdvertsContent}>
				<Desktop>
					{notices &&
						notices.map((i) => (
							<div key={i.id}>
								<ProductCardGrid grid={"3"} notice={i} isNew />
							</div>
						))}
				</Desktop>
			</div>
			<div className={s.bestsellersContainerTopPanel}>
				<Mobile>
					{notices &&
						notices.map((i) => (
							<MobileProductCard notice={i} key={i.id} isNew />
						))}
				</Mobile>
			</div>
			{noticesPag && noticesPag.pageCount > 1 && (
				<div className={s.newAdvertsBottomPanel}>
					<Mobile>
						<ExpandModernButton
							onClickCallback={() => {
								onClick?.();

								setCurrentPage((state) => state + 1);
							}}
						/>
					</Mobile>
					<Desktop>
						<ExpandButton
							isExpanded={isExpanded}
							setExpanded={setExpanded}
							onClick={() => {
								onClick?.();
								setCurrentPage((state) => state + 1);
							}}
						/>
					</Desktop>
				</div>
			)}
		</div>
	);
};

export default NewAdverts;
