import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { SuperSEO } from "react-super-seo";
import Bestsellers from "../../Components/Bestsellers/Bestsellers";
import CategoryBoardSecondLevel from "../../Components/Category/CategoryBoardSecondLevel/CategoryBoardSecondLevel";
import CategorySecondLevelSlider from "../../Components/Category/CategorySecondLevelSlider";
import MainSearch from "../../Components/MainSearch/MainSearch";
import NewAdverts from "../../Components/NewAdverts/NewAdverts";
import RecentlyWatched from "../../Components/RecentlyWatched/RecentlyWatched";
import HomeSeo from "../../Components/SEO/HomeSEO";
import Advertising from "../../Components/Сommercial/Advertising";
import { getCategoryView, getSomeCategory } from "../../redux/category_Reducer";
import { getFilteredNoticeList } from "../../redux/publicNotice_Reducer";
import { StateType } from "../../redux/redux-store";
import { Desktop, Mobile } from "../../responsive";
import s from "./MainCategory.module.css";


type PropsType = {
	open: boolean;
	setOpen: (b: boolean) => void;
};

const MainCategory: React.FC<PropsType> = ({ open, setOpen }) => {
	const { i18n, t } = useTranslation();
	const { slug } = useParams();
	const dispatch: any = useDispatch();
	const getCategories = useSelector(() => getSomeCategory);
	const getCategory = useSelector(() => getCategoryView);
	const getNewNotices = useSelector(() => getFilteredNoticeList);
	const categoriesList = useSelector(
		(state: StateType) => state.categoryData.categorySecondLevel
	);
	const categoriesListMobile = useSelector(
		(state: StateType) => state.categoryData.categorySecondLevelMobile
	);
	const currentCategory = useSelector(
		(state: StateType) => state.categoryData.view
	);
	const noticesNew = useSelector(
		(state: StateType) => state.publicNotice.filteredNotices
	);
	const prevCategoryIdRef = useRef<number | null>(null);
	const prevCategoryNameRef = useRef<string | null>(null);

	useEffect(() => {
		dispatch(getCategory("", slug || ""));
	}, [slug]);

	useEffect(() => {
		if (currentCategory.id) {
			if (currentCategory.id !== prevCategoryIdRef.current) {
				dispatch(
					getCategories(
						{
							level: "2",
							active: "1",
							parentId: currentCategory.id.toString(),
							perPage: "27",
						},
						2
					)
				);
				dispatch(
					getNewNotices({
						page: "1",
						perPage: "12",
						sortType: "1",
						category_id: +currentCategory.id,
					})
				);
				prevCategoryIdRef.current = currentCategory.id;
				prevCategoryNameRef.current = currentCategory.name;
			}
		}
	}, [currentCategory?.id, i18n.language]);
	const [categoryLink, setCategoryLink] = useState(``);
	useEffect(() => {
		if (location.pathname.includes("/c2/")) {
			setCategoryLink(
				`https://bago.ua${i18n.language === "ru" ? "" : "/ua/"}${location.pathname}`
			);
		} else {
			setCategoryLink(
				`https://bago.ua${i18n.language === "ru" ? "/" : "/ua/"}${currentCategory?.alias || ""}`
			);
		}
	}, [location.pathname, currentCategory]);

	return (
		<>
			<SuperSEO
				title={currentCategory.seo_title}
				description={currentCategory.seo_description}
				lang={`${i18n.language}-${i18n.language.toUpperCase()}`}
				openGraph={{
					ogImage: {
						ogImage: currentCategory?.icon ? currentCategory.icon : "",
						ogImageAlt: "bago",
						ogImageWidth: 100,
						ogImageHeight: 100,
						ogImageType: "image/webp",
					},
					ogTitle: currentCategory.seo_title,
					ogDescription: currentCategory.seo_description,
					ogSiteName: "Bago",
					ogUrl: categoryLink,
					ogLocale: i18n.language === "ru" ? "ru_Ru" : "uk_UK",
				}}
				robots={"index,follow"}
			>
				{/* <meta name='title' content={currentCategory.seo_title}/>
                <meta
                    property='description'
                    content={currentCategory.seo_description}
                />*/}
				<meta name="twitter:site" content="bago.ua" />
				<meta name="twitter:creator" content="bago.ua" />
			</SuperSEO>
			<div className={`${s.mainCategoryContainer}`}>
				<Mobile>

					<CategorySecondLevelSlider categoriesList={categoriesListMobile} />
				</Mobile>
				<Desktop>

					<CategoryBoardSecondLevel
						categoriesList={categoriesList}
						currentCategory={currentCategory}
						prevCategoryName={prevCategoryNameRef.current}
					/>
					<div className={s.mainSearchContainer}>
						<MainSearch open={open} setOpen={setOpen} />
					</div>
				</Desktop>
				<Bestsellers categoryId={Number(currentCategory.id)} />
				<Desktop>
					<div className={s.mainCategoryAds}>
						<Advertising idAD={3} />
					</div>
				</Desktop>
				<Mobile>
					<div className={s.mainCategoryAds}>
						<Advertising idAD={3} isMobile={1} />
					</div>
				</Mobile>
				<NewAdverts title={t("Нові оголошення")} notices={noticesNew} />
				<Desktop>
					<div className={s.mainCategoryAds}>
						<Advertising idAD={4} />
					</div>
				</Desktop>
				<Mobile>
					<div className={s.mainCategoryAds}>
						<Advertising idAD={4} isMobile={1} />
					</div>
				</Mobile>
				<RecentlyWatched />
				<Desktop>
					<HomeSeo seoTextHtml={currentCategory.seo_text || ""} />
				</Desktop>
			</div>
		</>
	);
};

export default MainCategory;
