import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { SuperSEO } from "react-super-seo";
import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import NoRegisterAdCard from "../../Components/AdCard/NoRegisterAdCard";
import RegisterAdCard from "../../Components/AdCard/RegisterAdCard";
import RegisterAdCardAdaptive from "../../Components/AdCard/RegisterAdCardAdaptive";
import Bestsellers from "../../Components/Bestsellers/Bestsellers";
import MainSearch from "../../Components/MainSearch/MainSearch";
import NewAdverts from "../../Components/NewAdverts/NewAdverts";
import PopularSectionOfBoard from "../../Components/PopularSectionOfBoard/PopularSectionOfBoard";
import RecentlyWatched from "../../Components/RecentlyWatched/RecentlyWatched";
import HomeSeo from "../../Components/SEO/HomeSEO";
import Advertising from "../../Components/Сommercial/Advertising";
import swiperImage from "../../images/swiperImage.jpg";
import { getSomeCategory } from "../../redux/category_Reducer";
import { getFilteredNoticeList } from "../../redux/publicNotice_Reducer";
import { StateType } from "../../redux/redux-store";
import { Desktop, Mobile } from "../../responsive";
import { stateUserType } from "../../types/reduxStoreTypes";
import s from "./Home.module.css";

type PropsType = {
	open: boolean;
	setOpen: (b: boolean) => void;
};

const HomePage: React.FC<PropsType> = ({ open, setOpen }) => {
	const dispatch: any = useDispatch();
	const getNotices = useSelector(() => getFilteredNoticeList);
	const getCategories = useSelector(() => getSomeCategory);
	const notices = useSelector(
		(state: StateType) => state.publicNotice.filteredNotices
	);
	const noticesPag = useSelector(
		(state: StateType) => state.publicNotice.filteredNoticesPagination
	);
	const categoriesList = useSelector(
		(state: StateType) => state.categoryData.categoryFirstLevel
	);
	const auth = useSelector((state: stateUserType) => state.userData.isAuth);
	const seoData = useSelector((state: StateType) => state.pagesData.seoPages);
	const { i18n, t } = useTranslation();
	const perPage = noticesPag.perPage ? noticesPag.perPage : 1;

	useEffect(() => {
		dispatch(getCategories({ level: "1", active: "1", perPage: "27" }, 1));
		dispatch(
			getNotices({ page: "1", perPage: "32", sortType: "1", excludeTop: 2 })
		);
		return () => {
			setOpen(false);
		};
	}, [i18n.language, dispatch]);

	return (
		<>
			<SuperSEO
				title={`${i18n.language === "uk" ? seoData[0]?.title_uk || "Bago Main" : seoData[0]?.title_ru || "Bago Main"}`}
				description={`${i18n.language === "uk" ? seoData[0]?.description_uk || "Bago Main" : seoData[0]?.description_ru || "Bago Main"}`}
				lang={`${i18n.language}-${i18n.language.toUpperCase()}`}
				robots={"bago.ua/robots.txt"}
				openGraph={{
					ogLocale: i18n.language === "ru" ? "ru_Ru" : "uk_UK",
					ogTitle: `${i18n.language === "uk" ? seoData[0]?.title_uk || "" : seoData[0]?.title_ru || ""}`,
					ogType: "website",
					ogUrl: `https://bago.ua${i18n.language === "ru" ? "/" : "/ua/"}`,
					ogImage: {
						ogImageSecureUrl: `https://bago.ua/opengraf_icon.png`,
						ogImage: `https://bago.ua/opengraf_icon.png`,
						ogImageAlt: "bago",
						ogImageWidth: 500,
						ogImageHeight: 500,
						ogImageType: "image/png",
					},
					ogDescription: `${
						i18n.language === "uk"
							? seoData[0]?.description_uk || ""
							: seoData[0]?.description_ru || ""
					}`,
					ogSiteName: "Bago",
				}}
			>
				<meta name="title" content={seoData[0].title_uk} />
				<meta property="description" content={seoData[0].description_uk} />
				<meta property="og:image" content="https://bago.ua/opengraf_icon.png" />
				<meta
					property="og:image:secure_url"
					content="https://bago.ua/opengraf_icon.png"
				/>
				<meta name="twitter:site" content="bago.ua" />
				<meta name="twitter:creator" content="@bago.ua" />
				<meta property="og:site_name" content="Bago" />
				<meta name="robots" content="index,follow" />
				<meta property="og:title" content={seoData[0].title_uk} />
				<meta property="og:description" content={seoData[0].description_uk} />
			</SuperSEO>

			<div className={`${s.homePageContainer}`}>
				<Desktop>
					<Swiper
						navigation={false}
						autoplay={true}
						modules={[Navigation]}
						className={s.homeSwiper}
					>
						<SwiperSlide>
							<img src={swiperImage} alt="swiper" />
						</SwiperSlide>
						{/*<SwiperSlide><img src={swiperImage2} alt="swiper"/></SwiperSlide>*/}
					</Swiper>
					<div className={s.mainSearchWrapper}>
						<MainSearch open={open} setOpen={setOpen} />
					</div>
				</Desktop>
				<PopularSectionOfBoard categories={categoriesList} />
				<Bestsellers />
				<Desktop>
					<Advertising idAD={1} />
				</Desktop>
				<Mobile>
					<Advertising idAD={1} isMobile={1} />
				</Mobile>
				{!auth && (
					<>
						<Desktop>
							<NoRegisterAdCard />
						</Desktop>
						<Mobile>{/* <NoRegisterAdCardAdaptive/> */}</Mobile>
					</>
				)}
				{auth && (
					<>
						<Desktop>
							<RegisterAdCard />
						</Desktop>
						<Mobile>
							<RegisterAdCardAdaptive />
						</Mobile>
					</>
				)}
				<Mobile>
					<Advertising idAD={2} isMobile={1} />
				</Mobile>
				<NewAdverts
					title={t("Нові оголошення")}
					notices={notices}
					noticesPag={noticesPag}
					onClick={() =>
						dispatch(
							getNotices({
								page: "1",
								perPage: `${perPage + 32}`,
								sortType: "1",
								excludeTop: 2,
							})
						)
					}
				/>
				<Desktop>
					<Advertising idAD={2} />

				</Desktop>
				{auth && <RecentlyWatched />}
				<HomeSeo
					seoTextHtml={
						i18n.language === "uk"
							? seoData[0].seo_uk || ""
							: seoData[0]?.seo_ru || ""
					}
				/>
			</div>
		</>
	);
};

export default HomePage;
