import { FilterForCreateNotice } from "../types/noticeTypes";
import i18n from "i18next";
import { useDispatch, useSelector } from "react-redux";
import {
	setFiltersManyAction,
	setFiltersOneIsAction,
} from "../redux/notice_Reducer";
import { StateType } from "../redux/redux-store";
import {
	getDependFiltersList,
	setDependFiltersList, setFiltersList,
	setFiltersVariables,
	setIsRedactionFilterAd,
} from "../redux/category_Reducer";

type Props = {
	element: any;
	/*element: { id: string; name: string; name_ru: string,apiUrlDependedFilters:string | null
	};*/
	filter: any;
};
const useCatIdsHandler = () => {
	const dispatch: any = useDispatch();
	const setVariablesFilters = useSelector(() => setFiltersVariables);

	const filtersMany = useSelector(
		(state: StateType) => state.noticeData.filtersMany
	);
	const filtersOneIs = useSelector(
		(state: StateType) => state.noticeData.filtersOneIs
	);
	const allFilters = useSelector(
		(state: StateType) => state.categoryData.filters
	);
	const dependFilters = useSelector(
		(state: StateType) => state.categoryData.dependFilters
	);
	const filtersVariables = useSelector(
		(state: StateType) => state.categoryData.filtersVariables
	);
	const handleFilter = ({ element, filter }: Props) => {

		if (filter?.type === 1 || filter?.type === 3 || filter?.type === 4) {
			const existingIndex = filtersOneIs?.findIndex(
				(item: any) =>
					item.id === element.id && item.filter === filter.inputName
			);

			if (existingIndex !== -1) {
				const updatedValue = [...filtersOneIs];

				// Удаляем элемент по индексу existingIndex
				if (existingIndex !== -1) {
					updatedValue.splice(existingIndex, 1);
				}
				// Фильтруем, удаляя элементы, у которых property_id совпадает с filter.parent_id
				const finalUpdatedValue = updatedValue.filter(
					(f: any) => f.parent_id !== filter.property_id
				);
				// Фильтруем, удаляя элементы, у которых property_id совпадает с filter.parent_id
				const finalUpdatedVariables: Record<string, any> = filtersVariables;
				Object.keys(finalUpdatedVariables).forEach((key) => {
					if (key.endsWith(`${filter.id}`)) {
						delete finalUpdatedVariables[key];
					}
				});
				// Фильтруем, удаляя зависимые фильтры, если не выбран основной

				const finalUpdatedDependFilters =
					dependFilters &&
					dependFilters.filter((f: any) => f.parent_id !== filter.id);

				dispatch(setDependFiltersList(finalUpdatedDependFilters));
				dispatch(setVariablesFilters(finalUpdatedVariables));
				// Обновляем состояние
				dispatch(setFiltersOneIsAction(finalUpdatedValue));
			} else {

				const existingFilter = filtersOneIs?.findIndex(
					(item: any) => item.filter === filter.inputName
				);
				if (existingFilter === -1) {
					const newValue = {
						filter: filter?.inputName,
						property_id: filter.property_id,
						id: element.id,
						name: filter.propertyName,
						value: i18n.language === "ru" ? element.name_ru : element.name,
						parent_id: filter.parent_id,
					};

					dispatch(setFiltersOneIsAction([...filtersOneIs, newValue]));
				} else {

					const updatedValue: any = [...filtersOneIs];

					// Фильтруем, удаляя зависимые фильтры, если не выбран основной
					const finalUpdatedFilters =
						dependFilters &&
						dependFilters.filter((f: any) => f.parent_id !== filter.id);

					dispatch(setDependFiltersList(finalUpdatedFilters));
					updatedValue[existingFilter].id = element.id;

					dispatch(setFiltersOneIsAction([...updatedValue]));
				}
			}
		} else {
			const existingIndex = filtersMany?.findIndex(
				(item: any) =>
					item.id === element.id && item.filter === filter.inputName
			);
			if (existingIndex !== -1) {
				const updatedValue: any = [...filtersMany];

				// Удаляем элемент по индексу existingIndex
				updatedValue.splice(existingIndex, 1);
				// Фильтруем, удаляя элементы, у которых property_id совпадает с filter.parent_id
				const finalUpdatedValue = updatedValue.filter(
					(f: any) => f.parent_id !== filter.property_id
				);
				// Обновляем состояние
				dispatch(setFiltersManyAction(finalUpdatedValue));
			} else {
				const newValue: FilterForCreateNotice = {
					filter: filter?.inputName,
					id: element.id,
					property_id: filter.property_id,
					name: filter.propertyName,
					value: i18n.language === "ru" ? element.name_ru : element.name,
					parent_id: filter.parent_id,
				};

				dispatch(setFiltersManyAction([...filtersMany, newValue]));
			}
		}
		dispatch(
			getDependFiltersList(
				filter.category_id,
				+element.id,
				filter.property_id,
				allFilters
			)
		);
	};

	return { handleFilter };
};

export default useCatIdsHandler;
