import { createStyles, makeStyles } from "@material-ui/core/styles";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
	useGetBusinessUserPageData,
	useSetDeliveryService,
} from "../../../CustomHooks/api/business-page/useBusinessPage";
import s from "./DeliveryServices.module.css";

const useStyles = makeStyles(() =>
	createStyles({
		accDetailsElement: {
			width: "100%",
			paddingTop: "20px",
			borderTop: "0.5px solid #E0E0E0",
			"& p": {
				maxWidth: "795px",
				/*width: "100%",*/
				fontFamily: "var(--myMainFontRegular)",
				fontSize: "16px",
				lineHeight: "20px",
				color: "#727272",
			},
			"& button.touched": {
				border: "none",
				background: "#FFDD66",
				color: "#3D3D3D",
				"&:hover": {
					background: "#FFEFB6",
				},
			},
		},
	})
);

export const DeliveryServices = () => {
	const { t } = useTranslation();
	const classes = useStyles();
	const { mutate: setDeliveryService } = useSetDeliveryService();
	const { data } = useGetBusinessUserPageData("");

	const [selectedOptions, setSelectedOptions] = useState<
		Record<string, boolean>
	>({});

	useEffect(() => {
		if (data?.deliveryServices) {
			setSelectedOptions(
				Object.entries(data?.deliveryServices ?? []).reduce(
					(acc, [key, service]) => ({
						...acc,
						[key]: service?.active === "1",
					}),
					{}
				)
			);
		}
	}, [data]);

	const toggleOption = (deliveryId: string): void => {
		const newStatus = !selectedOptions[deliveryId];
		setSelectedOptions((prev) => ({
			...prev,
			[deliveryId]: newStatus,
		}));

		setDeliveryService({
			deliveryId,
			active: newStatus ? 1 : 0,
		});
	};

	const isTouched = Object.values(data?.deliveryServices || {}).some(
		(service) =>
			service?.active === "1"
				? false
				: selectedOptions[service?.deliveryId as any]
	);

	return (
		<div className={classes.accDetailsElement}>
			<div className={s.deliveryDetails}>
				<h3>
					{t(
						"Виберіть зручні вам способи доставки. Ці налаштування будуть застосовані до всіх ваших оголошень."
					)}
				</h3>
				<div className={s.container}>
					<ul className={s.deliveryTable}>
						{Object.values(data?.deliveryServices ?? []).map((option) => {
							if (!option) return null;

							const isActive = selectedOptions[option.deliveryId];

							return (
								<li key={option.deliveryId} className={s.deliveryTableElement}>
									<div
										className={`${s.deliveryButton} ${isActive ? s.selected : ""}`}
										onClick={() => toggleOption(String(option.deliveryId))}
									>
										<div className={s.deliveryLogo}>
											<img src={option.logoUrl} alt="Logo delivery service" />
										</div>
										<div className={s.deliveryInfo}>
											<span className={s.deliveryName}>{option.title}</span>
											<span className={s.deliveryTime}>
												{isActive ? "Включено" : "Виключено"}
											</span>
										</div>
									</div>
								</li>
							);
						})}
					</ul>
				</div>

				<div className={s.btn}>
					<button type={"submit"} className={isTouched ? "touched" : ""}>
						{t(`Зберегти`)}
					</button>
				</div>
			</div>
		</div>
	);
};
