import { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import PreLoaderComponent from "../../Components/PreLoaderComponent/PreLoaderComponent";
import { useGetBusinessUserPageData } from "../../CustomHooks/api/business-page/useBusinessPage";
import type { StateType } from "../../redux/redux-store";
import s from "./BusinessPage.module.css";
import { NavigationTabs } from "./Components/NavigationTabs";
import { SellerContent } from "./Components/SellerContent/SellerContent";
import { TopBanner } from "./Components/TopBanner";
import NewPage404 from "../../pages/Page404/NewPage404";

const tabs = [
	{ key: "all", label: "Всі оголошення" },
	{ key: "about", label: "Про продавця" },
	{ key: "contacts", label: "Контакти" },
	// { key: "reviews", label: "Отзывы" },
	// { key: "portfolio", label: "Портфолио" },
	// { key: "articles", label: "Статьи" },
];

const BusinessPage = () => {
	const loc = useParams();
	const { data, isLoading, isError } = useGetBusinessUserPageData(
		loc?.slug ?? ""
	);
	const [grid3, setGrid3] = useState(false);
	const [row, setRow] = useState(true);
	const [sort, setSort] = useState("2");
	const [categoryId, setCategoryId] = useState<number>();

	const userView: any = useSelector((state: StateType) => state.userData.user);

	const isUserPage = userView.id === data?.user_id || data?.user_id === null;

	if (isLoading) {
		return <PreLoaderComponent type={"category"} size={"medium"} />;
	}
	if (isError || !data) {
		return <NewPage404 />;
	}

	return (
		<div className={s.wrapper}>
			<div className="__container">
				<TopBanner
					phone={data.main_phone}
					slogan={data.slogan}
					bannerUrl={data.bannerUrl}
					city={data.contacts.city.value}
					isUserPage={isUserPage}
				/>
				<NavigationTabs
					tabs={tabs}
					grid3={grid3}
					row={row}
					setGrid3={setGrid3}
					setRow={setRow}
					sort={sort}
					catIds={[]}
					userId={data.user_id ?? userView.id}
					perPage={48}
					page={1}
					category_id={categoryId}
				/>
				<SellerContent
					userRubrics={data.usersCategories}
					aboutSeller={data.aboutSeller}
					contacts={data.contacts}
					payment={data.payment}
					guaranteeConditions={data.guaranteeConditions}
					returnConditions={data.returnConditions}
					phones={data.phones}
					manageActivityBlock={data.manageActivityBloc}
					statusPage={data.status}
					slugPage={data.slug}
					deliveryServices={data.deliveryServices}
					mainPhone={data.main_phone}
					userId={data.user_id}
					grid3={grid3}
					row={row}
					setGrid3={setGrid3}
					setRow={setRow}
					sort={sort}
					setSort={setSort}
					catIds={[]}
					setCategoryId={setCategoryId}
					categoryId={categoryId ?? 0}
				/>
			</div>
		</div>
	);
};

export default BusinessPage;
