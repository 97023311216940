import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import { useGetBusinessUserPageData } from "../../../CustomHooks/api/business-page/useBusinessPage";
import { StateType } from "../../../redux/redux-store";
import { setLogoutUser } from "../../../redux/user_Reducer";
import { numberAddSpace } from "../../../supporting";
import Advertising from "../../Сommercial/Advertising";
import PersonalCabinetSvgSelector from "../PersonalCabinetSvgSelector";
import "./PersonalCabinetNavBar.css";
import s from "./PersonalCabinetNavBar.module.css";

// Функція для отримання класу статусу
export function getStatusClassName(status: string) {
  switch (status) {
    case "Активна":
    case "Активно":
    case "Активная":
      return s.active;
    case "На перевірці":
    case "Очікує на модерацію":
    case "На проверке":
      return s.pending;
    case "Відхилено":
    case "Отклонено":
      return s.rejected;
    case "Неактивна":
    case "Неактивная":
    case "Не активно":
      return s.inactive;
    default:
      return s.default;
  }
}

// Функція для отримання назви статусу з перекладом
export function getStatusName(status: string) {
  const { t } = useTranslation();
  switch (status) {
    case "Активно":
    case "Активная":
      return t("Активна");
    case "Очікує на модерацію":
    case "На проверке":
      return t("На перевірці");
    case "Відхилено":
    case "Отклонено":
      return t("Відхилено");
    case "Неактивна":
    case "Неактивная":
    case "Не активно":
      return t("Неактивна");
    default:
      return status;
  }
}


const PersonalCabinetNavBar: React.FC = () => {
	const { data: businessPageData } = useGetBusinessUserPageData("");
	const userView: any = useSelector((state: StateType) => state.userData.user);
	const userStatic = useSelector(
		(state: StateType) => state.userData.userStatic
	);
	const dispatch: any = useDispatch();
	const { t } = useTranslation();
	const logout = useSelector(() => setLogoutUser);

	const handleLogout = () => {
		dispatch(logout());
	};

	return (
		<div className={s.navBarContainer}>
			<NavLink to={"/personal_cabinet/settings"}>
				<div className={s.personalInfo}>
					<div className={s.personalInfo_left}>
						{userView?.photo?.photoUrl ? (
							<div className={s.imageContainer}>
								<img src={userView?.photo?.photoUrl} alt="avatar" />
							</div>
						) : (
							<PersonalCabinetSvgSelector id={"avatarLight"} />
						)}
						<div className={s.personalInfo_info}>
							<span>
								{userView?.last_name || ""} {userView?.name || ""}
							</span>
							<span>{userView?.phone || ""}</span>
							<span>{userView?.email || ""}</span>
						</div>
					</div>
					<div className={s.personalInfo_right}>
						<PersonalCabinetSvgSelector id={"settingsLight"} />
					</div>
				</div>
			</NavLink>
			<div className={s.navContainer}>
				<ul className={s.navMenu}>
					<li className={`${s.navMenuItem} navMenuItem`}>
						<Link
							to={`/business-page/`}
							className={s.linkBusinessProfile}
						>
							<span className={s.navMenuItemLeft}>
								<PersonalCabinetSvgSelector id={"businessProfile"} />
								<span>{t("Управління бізнес-профілем")}</span>
							</span>
						</Link>
						<p
							className={`${s.status} ${getStatusClassName(getStatusName(businessPageData?.status ?? ""))}`}
						>
							Статус: {getStatusName(businessPageData?.status ?? "")}
						</p>
					</li>
					<li className={`${s.navMenuItem} navMenuItem`}>
						<NavLink to={"ads"}>
							<span className={s.navMenuItemLeft}>
								<PersonalCabinetSvgSelector id={"myADs"} />
								<span>{t("Мої оголошення")}</span>
							</span>
							<span className={`${s.itemData} ${s.adsAmount}`}>
								{
									userStatic?.userCountNotices[0]?.summary || "0"
									/*{statusCountAssist()?.total || 0}*/
								}
							</span>
						</NavLink>
					</li>

					<li className={`${s.navMenuItem} navMenuItem`}>
						<NavLink to={"balance"}>
							<span className={s.navMenuItemLeft}>
								<PersonalCabinetSvgSelector id={"balance"} />
								<span>{t("Баланс")}</span>
							</span>
							<span className={s.itemDataBalance}>
								{userStatic?.userBonus === null
									? numberAddSpace(+userStatic.userBalance)
									: numberAddSpace(
											+userStatic.userBonus + +userStatic.userBalance
										)}{" "}
								грн
							</span>
						</NavLink>
					</li>
					<li className={`${s.navMenuItem} navMenuItem`}>
						<NavLink to={"favorite"}>
							<span className={s.navMenuItemLeft}>
								<PersonalCabinetSvgSelector id={"favorite"} />
								<span>{t("Обрані")}</span>
							</span>
						</NavLink>
					</li>
					<li className={`${s.navMenuItem} navMenuItem`}>
						<NavLink to={"recently_watched"}>
							<span className={s.navMenuItemLeft}>
								<PersonalCabinetSvgSelector id={"seeProducts"} />
								<span>{t("Переглянуті товари")}</span>
							</span>
						</NavLink>
					</li>
					<li className={`${s.navMenuItem} navMenuItem`}>
						<NavLink to={"/"} onClick={handleLogout}>
							<span className={s.navMenuItemLeft}>
								<PersonalCabinetSvgSelector id={"exitAcc"} />
								<span>{t("Вихід з акаунту")}</span>
							</span>
						</NavLink>
					</li>
				</ul>
				<div className={s.adsWrap}>
					<Advertising idAD={10} />
				</div>
				<div className={s.adsWrap}>
					<Advertising idAD={9} />
				</div>
			</div>
		</div>
	);
};

export default PersonalCabinetNavBar;
