import { UseMutateFunction } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setNotification } from "../../../../../../redux/notificationReducer";
import { AboutSellerField } from "../../AboutSeller/AboutSellerField";
import styles from "../SellerInfo.module.css";

type Props = {
	isEditAboutSeller: boolean;
	values: any;
	setAboutSeller: UseMutateFunction<
		AxiosResponse<any, any>,
		Error,
		string,
		unknown
	>;
	setFieldValue: (field: string, value: any) => void;
	status: string;
	setStatus: (value: string) => void;
	noticeDescriptionRef: React.RefObject<HTMLDivElement>;
};

export const AboutSellerSection = ({
	isEditAboutSeller,
	values,
	setAboutSeller,
	setFieldValue,
	status,
	setStatus,
	noticeDescriptionRef,
}: Props) => {
	const { t } = useTranslation();
	const dispatch: any = useDispatch();

	const onSubmitAboutSeller = () => {
		setAboutSeller(values.description, {
			onSuccess: ({ data }) => {
				if (data?.errors && Object.keys(data.errors).length) {
					for (const key in data.errors) {
						dispatch(setNotification(data.errors[key], "error"));
					}
					return;
				}

				dispatch(setNotification(t("Ваші зміни збережені."), "success"));
			},
		});
	};

	return (
		<>
			{/* <div id="about" className={styles.formElement}>
				<h3 className={styles.nameSide}>Назва компанії</h3>
				<div className={`${styles.fieldSide} ${styles.numberPhoneMessenger}`}>
					<div className={styles.emailInputWrapper}>
						{isEditAboutSeller ? (
							<Field
								type={"text"}
								name={"company"}
								placeholder="Введите Назву Компанії"
							/>
						) : (
							<p>{values.company}</p>
						)}
					</div>
				</div>
			</div> */}

			<AboutSellerField
				noticeDescriptionRef={noticeDescriptionRef}
				status={status}
				setStatus={setStatus}
				values={values}
				setFieldValue={setFieldValue}
				isEdit={isEditAboutSeller}
			/>

			{isEditAboutSeller ? (
				<div className={styles.footer}>
					<button
						type={"submit"}
						name={"save"}
						className={styles.button}
						onClick={onSubmitAboutSeller}
					>
						{t("Зберегти")}
					</button>
				</div>
			) : null}
		</>
	);
};
