import { memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useGetNoticesByUserId } from "../../CustomHooks/api/business-page/useBusinessPage";
import { Desktop, Mobile } from "../../responsive";
import type { publicOneNotice } from "../../types/publicNoticeTypes";
import ProductCardGrid from "../Cards/FavoriteSearchCard/ProductCardGrid";
import MobileProductCard from "../Cards/ProductCard/MobileProductCard";
import PreLoaderComponent from "../PreLoaderComponent/PreLoaderComponent";
import s from "./OtherAdsByAuthor.module.css";

type Props = {
	title: string;
	userId: number | null;
	notice?: {
		slug: string;
		status: number;
		statusName: string;
	};
};

const shuffleArray = (array: publicOneNotice[]) => {
	return [...array].sort(() => Math.random() - 0.5);
};

const ProductCardGridMemo = memo(ProductCardGrid);
const MobileProductCardMemo = memo(MobileProductCard);

export const OtherAdsByAuthor = memo(({ title, userId, notice }: Props) => {
	const { t } = useTranslation();
	const {
		data: notices,
		isLoading,
		isError,
	} = useGetNoticesByUserId({
		userId: userId ?? 0,
		perPage: 8,
		page: 1,
	});

	const shuffledNotices = useMemo(
		() => shuffleArray(notices?.items || []),
		[notices?.items]
	);

	if (isLoading) {
		return <PreLoaderComponent type="category" size="medium" />;
	}

	if (
		isError ||
		!notices ||
		notices.items.length === 0 ||
		notices.items.length === 1
	) {
		return null;
	}

	return (
		<div className="__container">
			<div className={s.newAdvertsContainer}>
				<div className={s.top}>
					<h5>{title}</h5>
					{notice?.slug && notice.statusName === "Активно" && (
						<Link to={`/business-page/${notice.slug}`}>
							{t("Дивитись всі")}
						</Link>
					)}
				</div>

				<div className={s.newAdvertsContent}>
					<Desktop>
						{shuffledNotices.map((item: publicOneNotice) => (
							<ProductCardGridMemo
								key={item.id}
								grid="3"
								notice={item}
							/>
						))}
					</Desktop>
				</div>

				<div className={s.bestsellersContainerTopPanel}>
					<Mobile>
						{shuffledNotices.map((item: publicOneNotice) => (
							<MobileProductCardMemo notice={item} key={item.id} />
						))}
					</Mobile>
				</div>
			</div>
		</div>
	);
});
