import React, { useState } from "react";
import s from "../../App.module.css";
import { Slide } from "@material-ui/core";
import NavBarSvgSelector from "../NavBar/NavBarSvgSelector";
import Logo from "../Logo/Logo";
import { Link } from "react-router-dom";
import LanguageSelector from "../LanguageSelector/LanguageSelector";
import { createStyles, withStyles } from "@material-ui/core/styles";
import Badge from "@material-ui/core/Badge";
import { Desktop } from "../../responsive";
//import HeaderSearchWindow from "./HeaderSearchWindow";
import HeaderAuthBlock from "./HeaderAuthBlock";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { stateUserType } from "../../types/reduxStoreTypes";
import { StateType } from "../../redux/redux-store";
import { setClearFiltersList } from "../../redux/category_Reducer";
import { setImagesList } from "../../redux/notice_Reducer";
import { useLocation, useNavigate } from "react-router";
import {
	setErrorApprovePhonePopup,
	setPhoneEnterPopup,
} from "../../redux/user_Reducer";
import EnterPhonePopup from "../CommonPopups/AttentionInfoPopup/EnterPhonePopup";
import BedResultChangePhonePopup from "../../Auth&RegNewPopups/BedResultChangePhonePopup";
import ApprovePhonePopup from "../../Auth&RegNewPopups/ApprovePhonePopup";

function Header({
	headerSee,
	openEnterDialog,
}: {
	headerSee: boolean;
	openEnterDialog: () => void;
}) {
	const [openLanguage, setOpenLanguage] = useState(false);
	const auth = useSelector((state: stateUserType) => state.userData.isAuth);
	const userView = useSelector((state: StateType) => state.userData.user);
	const clearFilters = useSelector(() => setClearFiltersList);
	const setImageList = useSelector(() => setImagesList);
	const handleEnterPopup = useSelector(() => setPhoneEnterPopup);
	const favoriteCount = useSelector(
		(state: StateType) => state.userData.userStatic.userCountFavorites
	);
	const { t } = useTranslation();

	const approvePhonePopup = useSelector(
		(state: StateType) => state.userData.openApprovePhonePopup
	);
	const errorApprovePhonePopup = useSelector(
		(state: StateType) => state.userData.errorApprovePhonePopup
	);
	const phoneEnterPopup = useSelector(
		(state: StateType) => state.userData.phoneEnterPopup
	);
	const handleOpenPopup = useSelector(() => setErrorApprovePhonePopup);
	const dispatch: any = useDispatch();
	const location = useLocation();
	const segmentsArray = location.pathname?.split("/").filter(Boolean);
	const currentPath = location.pathname;
	const navigate = useNavigate();

	const clearInputs = () => {
		dispatch(setImageList([]));
		dispatch(clearFilters());
	};
	const handleClosePopup = () => {
		dispatch(handleEnterPopup(false));
	};

	/*const handleOpenPhonePopup = () => {
        dispatch(handleOpenApprovePopup(true))
    }*/
	/*const StyledOrderBadge = withStyles(() =>
        createStyles({
            badge: {
                width: "8px",
                height: "18px",
                right: 10,
                top: 0,
                /!*padding: '3px',*!/
                color: "black",
                backgroundColor: "#98F000",
                fontSize:"10px",
                lineHeight:"normal"
            },
        }),
    )(Badge);*/
	const StyledFavoriteBadge = withStyles(() =>
		createStyles({
			badge: {
				width: "8px",
				height: "18px",
				right: 10,
				top: 0,
				/*padding: '3px',*/
				color: "white",
				backgroundColor: "#787777",
				fontSize: "10px",
				lineHeight: "normal",
			},
		})
	)(Badge);
	/*const StyledMessageBadge = withStyles(() =>
        createStyles({
            badge: {
                width: "8px",
                height: "18px",
                right: 10,
                top: 0,
                /!*padding: '3px',*!/
                color: "black",
                backgroundColor: "#FFD74A",
                fontSize:"10px",
                lineHeight:"normal"
            },
        }),
    )(Badge);*/
	/*const StyledCartBadge = withStyles(() =>
        createStyles({
            badge: {
                width: "8px",
                height: "18px",
                right: 5,
                top: 0,
                color: "black",
                backgroundColor: "#53E0FF",
                fontSize:"10px",
                lineHeight:"normal"
            },
        }),
    )(Badge);*/

	const handleNavigation = () => {
		if (currentPath == "/") {
			window.location.reload();
		} else if (currentPath == "/ua") {
			window.location.reload();
		} else {
			// navigate(i18n.language === "uk" ? "/ua":"")
		}
	};

	return (
		<>
			<Slide direction="down" in={headerSee} timeout={640}>
				<header className={`${s.header} mui-fixed`}>
					<div className={s.headerContainer}>
						<div className={s.headerContainer_leftSide}>
							<div className={s.menuLogoBlock}>
								{/*<div className={s.menuButton} >
                                <Desktop>
                                    <NavBarSvgSelector id="menu"/>
                                </Desktop>
                                <Mobile>
                                    <NavBarSvgSelector id="mobileMenu"/>
                                </Mobile>
                            </div>*/}
								<div className={s.logo}>
									<Desktop>
										<div onClick={handleNavigation}>
											<Logo />
										</div>
									</Desktop>
								</div>
							</div>
							{/*<div className={s.customCatalogBoard}>
                            <CatalogBoard/>
                        </div>*/}
						</div>
						<div className={s.headerContainer_central}>
							{/*Не показывать поиск на страницах 20.05.2024 мит - сообщил Антон*/}
							{/*{segmentsArray?.length >= 1 && segmentsArray[0] !== "c" && segmentsArray[1] !== "c" &&
                        <HeaderSearchWindow />}*/}
						</div>
						<div className={s.headerContainer_RightSide}>
							<div className={s.iconGroups}>
								<div className={s.languageGroupsElement}>
									<LanguageSelector
										open={openLanguage}
										setOpenLanguage={setOpenLanguage}
										position={"top"}
									/>
								</div>
								<div
									className={s.iconsGroupsElement}
									onClick={() => {
										!auth && openEnterDialog();
									}}
								>
									{auth && +favoriteCount > 0 ? (
										<StyledFavoriteBadge
											overlap="rectangular"
											badgeContent={favoriteCount}
											color="primary"
										>
											<Link to={auth ? "/personal_cabinet/favorite" : ""}>
												<div>
													<NavBarSvgSelector id="favorite" />
												</div>
												<p className={s.favoriteP}>{t(`обране`)}</p>
											</Link>
										</StyledFavoriteBadge>
									) : (
										<Link to={auth ? "/personal_cabinet/favorite" : ""}>
											<div>
												<NavBarSvgSelector id="favorite" />
											</div>
											<p className={s.favoriteP}>{t(`обране`)}</p>
										</Link>
									)}
								</div>
								{/*<div className={s.iconsGroupsElement}>
                                <StyledOrderBadge overlap="rectangular" badgeContent={6} color="primary">
                                    <Link to={"/orders"}>
                                        <div><NavBarSvgSelector id="orders"/></div>
                                        <p className={s.ordersText}>{t(`замовлення`)}</p>
                                    </Link>
                                </StyledOrderBadge>
                            </div>*/}

								{/*<div className={s.iconsGroupsElement}>
                                <StyledMessageBadge overlap="rectangular" badgeContent={12} color="secondary">
                                    <Link to={"/messages"}>
                                        <div><NavBarSvgSelector id="messages"/></div>
                                        <p className={s.messagesText}>{t(`повiдомлення`)}</p>
                                    </Link>
                                </StyledMessageBadge>
                            </div>*/}
								{/*<div className={s.iconsGroupsElement}>
                                <StyledCartBadge overlap="rectangular" badgeContent={2}>
                                    <Link to={"shopping_cart"}>
                                        <div><NavBarSvgSelector id="basket"/></div>
                                        <p className={s.cartText}>{t(`кошик`)}</p>
                                    </Link>
                                </StyledCartBadge>
                            </div>*/}
							</div>
							<div className={s.headerRightBlock}>
								<HeaderAuthBlock openEnterDialog={openEnterDialog} />
								<div
									onClick={() => {
										!auth && openEnterDialog();
									}}
									className={s.addAD}
								>
									<Link
										to={
											auth && userView.is_approve_phone === 1
												? `/create_ad`
												: ""
										}
										onClick={() => {
											clearInputs();
											if (auth && userView.is_approve_phone !== 1) {
												dispatch(handleEnterPopup(true));
											} else if (auth && userView.is_approve_phone === 1) {
												if (
													segmentsArray?.length > 0 &&
													(segmentsArray[0] === "create_ad" ||
														segmentsArray[1] === "create_ad")
												) {
													alert(
														t(
															"Оголошення, яке ви розпочали подавати," +
																" ще не було опубліковано. Бажаєте повернутися до цього оголошення?"
														)
													);
												} else {
													navigate(`/create_ad`);
												}
											}
										}}
									>
										<h3>+ {t(`додати оголошення`)}</h3>
									</Link>
								</div>
							</div>
						</div>
					</div>
				</header>
			</Slide>
			{approvePhonePopup && <ApprovePhonePopup />}
			{errorApprovePhonePopup && (
				<BedResultChangePhonePopup close={handleOpenPopup} />
			)}
			{phoneEnterPopup && (
				<EnterPhonePopup
					closePopup={handleClosePopup}
					title={t("Додайте та підтвердіть номер телефону")}
				/>
			)}
		</>
	);
}

export default Header;
