import { useState, useEffect } from "react";
import s from "./AutomaticSelectionOfCategories.module.css";
import AutomaticSelectionOfCategoriesSvgSelector from "./AutomaticSelectionOfCategoriesSvgSelector";
import { removeOneAutoSelection } from "../../redux/autoSelection_Reducer";
import { useDispatch, useSelector } from "react-redux";
import { StateType } from "../../redux/redux-store";
import { removeOneColor } from "../../redux/autoSelection_Reducer";
import {
	getFiltersList,
	getCategoryBlocks,
} from "../../redux/category_Reducer";
import { useTranslation } from "react-i18next";

interface Props {
	change: boolean;
	chose: boolean;
	handleAction?: (value: boolean) => void;
	categoryName?: string | undefined;
	/*tree?: string[] | undefined,*/
	names?: string[] | undefined;
	setFieldValue?: any;
	categoryId?: number | null;
	setIsOpen?: (value: boolean) => void;
	image?: string | null;
	/*color?: string*/
}

const AutomaticSelectionOfCategoriesTemplate = ({
	change,
	chose,
	handleAction,
	categoryName,
	setFieldValue,
	categoryId,
	setIsOpen,
	image,
	names,
}: Props) => {
	const dispatch: any = useDispatch();
	const [colorMainAutomaticSelection, setColorMainAutomaticSelection] =
		useState("");
	const autoSelectionColors = useSelector(
		(state: StateType) => state.autoSelection.autoSelectionColors
	);
	const removeOneColorRedux = useSelector(() => removeOneColor);
	const removeOne = useSelector(() => removeOneAutoSelection);
	const getFilters = useSelector(() => getFiltersList);
	const getBlocks = useSelector(() => getCategoryBlocks);
	const { t } = useTranslation();

	useEffect(() => {
		const randomColorIndex = Math.floor(
			Math.random() * autoSelectionColors.length
		);
		const currentColor = autoSelectionColors[randomColorIndex];
		setColorMainAutomaticSelection(currentColor);
		dispatch(removeOneColorRedux(currentColor));
	}, []);

	const handleChoseCategory = () => {
		dispatch(getFilters(categoryId ?? null));
		dispatch(getBlocks(categoryId ?? null));
		setFieldValue("category_id_value", categoryId);
		setFieldValue("category_id_label", categoryName);
		if (setIsOpen) setIsOpen(false);
	};

	const handleClose = () => {
		dispatch(removeOne(categoryId || null));
	};
	return (
		<div
			className={s.container}
			style={{ background: colorMainAutomaticSelection }}
		>
			<div className={s.topBlock}>
				<p>{categoryName}</p>
				{chose && (
					<div onClick={handleClose} style={{ cursor: "pointer" }}>
						<AutomaticSelectionOfCategoriesSvgSelector id="closeBlock" />
					</div>
				)}
			</div>
			<div className={s.mainContent}>
				{image ? (
					<div className={s.imageBlock}>
						<img src={image || ""} alt={categoryName} />
					</div>
				) : (
					<div className={s.imageBlockTemplate}>
						<img src={image || ""} alt={"category"} />
					</div>
				)}
				<div className={s.textBlock}>
					{names &&
						names.map((r, idx) => (
							<p style={{ paddingLeft: `${idx * 3}px` }} key={r}>
								{r}
							</p>
						))}
				</div>
				{change && (
					<div
						className={s.buttonBlock}
						onClick={() => handleAction && handleAction(true)}
					>
						<p>{t("Змінити")}</p>
					</div>
				)}
				{chose && (
					<div className={s.buttonBlock} onClick={handleChoseCategory}>
						<p>{t("Вибрати")}</p>
					</div>
				)}
			</div>
		</div>
	);
};

export default AutomaticSelectionOfCategoriesTemplate;
