import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import Sticky from "react-stickynode";
import SearchResultSvgSelector from "../../../Components/SearchResultComponents/SearchResultSvgSelector";
import styles from "./NavigationTabs.module.css";

interface Tab {
	key: string;
	label: string;
}

interface NavigationTabsProps {
	tabs: Tab[];
	grid3: boolean;
	row: boolean;
	setGrid3: (val: boolean) => void;
	setRow: (val: boolean) => void;
	sort: string;
	catIds: number[];
	userId: number;
	perPage: number;
	page: number;
	category_id?: number;
}

export const NavigationTabs: React.FC<NavigationTabsProps> = ({
	tabs,
	grid3,
	row,
	setGrid3,
	setRow,
	sort,
	catIds,
	userId,
	perPage,
	page,
	category_id
}) => {
	const isMobile = useMediaQuery({ maxWidth: 1024 });
	const { t } = useTranslation();
	const queryClient = useQueryClient();

	const [activeTab, setActiveTab] = useState(tabs[0].key);

	const handleTabClick = (tab: Tab) => {
		setActiveTab(tab.key);
		const element = document.getElementById(tab.key);
		if (element) {
			element.scrollIntoView({ behavior: 'smooth' });
		}
	};

	const handleClick = (id: string) => {
		setGrid3(false);
		setRow(false);
		
		queryClient.invalidateQueries({
			queryKey: ["get/noticesByUserId", userId, perPage, page, sort],
			refetchType: 'all'
		});
		
		switch (id) {
			case "grid3":
				setGrid3(true);
				break;
			case "row":
				setRow(true);
		}
	};

	return (
		<Sticky
			top={isMobile ? 65 : 0}
			innerClass={styles.sticky__block}
			innerActiveClass={styles.isSticky}
		>
			<nav className={styles.navContainer}>
				{tabs.map((tab) => (
					<a
						href={`#${tab.key}`}
						key={tab.key}
						className={`${styles.tabButton} ${activeTab === 'all' ? styles.activeAll : ""} ${activeTab === tab.key ? styles.activeTab : ""}`}
						onClick={(e) => {
							e.preventDefault();
							handleTabClick(tab);
						}}
					>
						{t(tab.label)}
					</a>
				))}

				<div className={styles.resultFilterPanelViewButton}>
					<button
						onClick={() => handleClick("grid3")}
						className={grid3 ? styles.active : ""}
					>
						<SearchResultSvgSelector id={"gridButton"} />
					</button>
					<button
						onClick={() => handleClick("row")}
						className={row ? styles.active : ""}
					>
						<SearchResultSvgSelector id={"menu"} />
					</button>
				</div>
			</nav>
		</Sticky>
	);
};
