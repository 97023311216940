import React, { forwardRef } from "react";
import s from "../CreateBoardAD.module.css";
import ChangeSettlementSelect from "./ChangeSettlementSelect";
import { useTranslation } from "react-i18next";

interface Props {
	status: any;
	setStatus: any;
	setFieldValue: any;
	values: any;
}

const ChangeSettlement = forwardRef<HTMLDivElement, Props>(
	({ status, setStatus, setFieldValue, values }, ref) => {
		const { t } = useTranslation();
		return (
			<>
				<div className={`${s.nameSide} ${s.select} ${status && status.settlement_id ? s.error : ''}`}>
					<span className={s.elementName}>
						{t(`Місцезнаходження`)}
						<span className={s.required}> * </span>
					</span>
				</div>
				<div ref={ref} className={`${s.fieldSide} ${s.select} ${status && status.settlement_id ? s.error : ''}`}>
					<ChangeSettlementSelect
						setStatus={setStatus}
						setFieldValue={setFieldValue}
						values={values}
						error={status && status.settlement_id}
					/>
					{status && status.settlement_id && (
						<span className={s.error}>{status.settlement_id}</span>
					)}
				</div>
			</>
		);
	}
);

export default ChangeSettlement;
