import { reduxActionTypes } from "../types/reduxStoreTypes";
import { IAutomaticSelectionList } from "../types/autoSelectionTypes";
import { Dispatch } from "redux";
import { autoSelectionAPI } from "../api/api";

const SET_ALL_AUTO_SELECTION_LIST =
	"bago/autoSelection_Reducer/SET_ALL_AUTO_SELECTION_LIST";
const SET_ONE_AUTO_SELECTION_LIST =
	"bago/autoSelection_Reducer/SET_ONE_AUTO_SELECTION_LIST";
const SET_ONE_AUTO_SELECTION_LIST_CHOOSING =
	"bago/autoSelection_Reducer/SET_ONE_AUTO_SELECTION_LIST_CHOOSING";
const SET_BOOLEAN_AUTO_SELECTION_LIST_CHOOSING =
	"bago/autoSelection_Reducer/SET_BOOLEAN_AUTO_SELECTION_LIST_CHOOSING";
const SET_NEW_COLORS_LIST = "bago/autoSelection_Reducer/SET_NEW_COLORS_LIST";

const initialState: IAutomaticSelectionList = {
	autoSelectionList: [],
	autoSelectionOne: {
		catParams: "",
		categoryAlias: "",
		categoryId: null,
		categoryInfo: {
			tree: [""],
			names: [""],
			urlIconLevel2: null,
		},
		categoryName: "",
		countItems: null,
	},
	autoSelectionOneChoosing: {
		catParams: "",
		categoryAlias: "",
		categoryId: null,
		categoryInfo: {
			tree: [""],
			names: [""],
			urlIconLevel2: null,
		},
		categoryName: "",
		countItems: null,
	},
	isCloseAutoSelection: true,
	autoSelectionColors: [
		"#F2EAE2",
		"#E3EEFF",
		"#DEF6E8",
		"#FFEAFF",
		"#EAFAFF",
		"#FFF6D9",
		"#EAF2FF",
		"#FFFBEF",
		"#FFEEEA",
		"#F1FFEA",
		"#F5EEFE",
		"#FFFEEA",
		"#FFEAEE",
	],
};

const autoSelection_Reducer = (
	state = initialState,
	action: reduxActionTypes
) => {
	switch (action.type) {
		case SET_ALL_AUTO_SELECTION_LIST:
			return {
				...state,
				autoSelectionList: action.data,
			};
		case SET_ONE_AUTO_SELECTION_LIST:
			return {
				...state,
				autoSelectionOne: action.data,
			};
		case SET_ONE_AUTO_SELECTION_LIST_CHOOSING:
			return {
				...state,
				autoSelectionOneChoosing: action.data,
			};
		case SET_BOOLEAN_AUTO_SELECTION_LIST_CHOOSING:
			return {
				...state,
				isCloseAutoSelection: action.data,
			};
		case SET_NEW_COLORS_LIST:
			return {
				...state,
				autoSelectionColors: action.data,
			};
		default:
			return state;
	}
};

export const setAllAutoSelection = (data: any) => ({
	type: SET_ALL_AUTO_SELECTION_LIST,
	data,
});
export const setOneAutoSelection = (data: any) => ({
	type: SET_ONE_AUTO_SELECTION_LIST,
	data,
});
export const setOneAutoSelectionChoosing = (data: any) => ({
	type: SET_ONE_AUTO_SELECTION_LIST_CHOOSING,
	data,
});
export const setNewColorsList = (data: any) => ({
	type: SET_NEW_COLORS_LIST,
	data,
});
export const setBooleanAutoChoosing = (data: boolean) => ({
	type: SET_BOOLEAN_AUTO_SELECTION_LIST_CHOOSING,
	data,
});

export const getAutoSelection =
	(value: string) => async (dispatch: Dispatch) => {
		try {
			const response = await autoSelectionAPI.callAutoSelection(value);

			if (response?.data?.categories) {
				dispatch(setAllAutoSelection(response?.data?.categories));
				dispatch(setOneAutoSelection(response?.data?.categories[0]));
			}
		} catch (error: any) {
			console.log(error.message);
		}
	};

export const removeOneAutoSelection =
	(categoryId: number | null) => async (dispatch: Dispatch, getState: () => any) => {
		try {
			const { autoSelectionList } = getState().autoSelection;

			const updatedAutoSelectionList = autoSelectionList.filter(
				(item: any) => item.categoryId !== categoryId
			);

			dispatch(setAllAutoSelection(updatedAutoSelectionList));
		} catch (error: any) {
			console.log(error.message);
		}
	};
export const removeOneColor =
	(value: string) => async (dispatch: Dispatch, getState: () => any) => {
		try {
			const { autoSelectionColors } = getState().autoSelection;

			const updatedAutoSelectionColors = autoSelectionColors.filter(
				(item: any) => item !== value
			);

			dispatch(setNewColorsList(updatedAutoSelectionColors));
		} catch (error: any) {
			console.log(error.message);
		}
	};

export const setDefaultAutoSelection =
	() => async (dispatch: Dispatch, getState: () => any) => {
		const defaultAutoSelectionState = {
			catParams: "",
			categoryAlias: "",
			categoryId: "",
			categoryInfo: {
				tree: [""],
				urlIconLevel2: null,
			},
			categoryName: "",
			countItems: null,
		};

		try {
			dispatch(setOneAutoSelection(defaultAutoSelectionState));
			dispatch(setOneAutoSelectionChoosing(defaultAutoSelectionState));
			dispatch(setAllAutoSelection([]));
		} catch (error: any) {
			console.log(error.message);
		}
	};

export default autoSelection_Reducer;
