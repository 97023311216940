import { Dispatch } from "react";
import { publicNotice } from "../api/api";
import { ActionType } from "../types/reduxStoreTypes";
import {
	NoticeIndexFilter,
	publicNoticeType,
} from "../types/publicNoticeTypes";
import { getUserStaticView, setFavoriteCount } from "./user_Reducer";
import { StateType } from "./redux-store";
import { setCategoriesList, setCategoryCounters } from "./search_Reducer";

const SET_ALL_NOTICE_LIST = "bago/publicNotice_Reducer/SET_ALL_NOTICE_LIST";
const SET_BEST_NOTICE_LIST = "bago/publicNotice_Reducer/SET_BEST_NOTICE_LIST";
const SET_BEST_NOTICE_ALL_LIST =
	"bago/publicNotice_Reducer/SET_BEST_NOTICE_ALL_LIST";
const SET_NOTICE_VIEW = "bago/publicNotice_Reducer/SET_NOTICE_VIEW";
const SET_FILTERED_NOTICE_LIST =
	"bago/publicNotice_Reducer/SET_FILTERED_NOTICE_LIST";
const SET_FILTERED_NOTICE_All_LIST =
	"bago/publicNotice_Reducer/SET_FILTERED_NOTICE_All_LIST";
const SET_CLEAR_NOTICE_LIST = "bago/publicNotice_Reducer/SET_CLEAR_NOTICE_LIST";
const SET_FILTERED_NOTICE_PAG =
	"bago/publicNotice_Reducer/SET_FILTERED_NOTICE_PAG";
const SET_CATEGORIES_IDS = "bago/publicNotice_Reducer/SET_CATEGORIES_IDS";

const SET_ALL_FAVORITE_LIST = "bago/publicNotice_Reducer/SET_ALL_FAVORITE_LIST";
const SET_ADD_DEL_FAVORITE = "bago/publicNotice_Reducer/SET_ADD_DEL_FAVORITE";
const SET_ADD_DEL_FAVORITE_IN_LIST =
	"bago/publicNotice_Reducer/SET_ADD_DEL_FAVORITE_IN_LIST";
const SET_ADD_DEL_FAVORITE_IN_FAVORITE_LIST =
	"bago/publicNotice_Reducer/SET_ADD_DEL_FAVORITE_IN_FAVORITE_LIST";
const SET_FAVORITE_NOTICE_LIST =
	"bago/publicNotice_Reducer/SET_FAVORITE_NOTICE_LIST";
const SET_DELETE_ALL_NOTICE_LIST =
	"bago/publicNotice_Reducer/SET_DELETE_ALL_NOTICE_LIST";
const SET_RECENTLY_WATCHED_LIST =
	"bago/publicNotice_Reducer/SET_RECENTLY_WATCHED_LIST";
const SET_CLEAR_RECENTLY_WATCHED_LIST =
	"bago/publicNotice_Reducer/SET_CLEAR_RECENTLY_WATCHED_LIST";
const SET_CLEAR_FAVORITE_LIST =
	"bago/publicNotice_Reducer/SET_CLEAR_FAVORITE_LIST";
const SET_TOP_RUBRICS_LIST = "bago/publicNotice_Reducer/SET_TOP_RUBRICS_LIST";
const SET_TOP_RUBRICS_ALL_LIST =
	"bago/publicNotice_Reducer/SET_TOP_RUBRICS_ALL_LIST";
const SET_CURRENT_COUNTERS = "bago/publicNotice_Reducer/SET_CURRENT_COUNTERS";
const SET_LOADER_SHOW = "bago/publicNotice_Reducer/SET_LOADER_SHOW";

const SET_PRICE_RANGE = "bago/publicNotice_Reducer/SET_PRICE_RANGE";
const SET_SLIDER_PRICE = "bago/publicNotice_Reducer/SET_SLIDER_PRICE";
const SET_PRICE_RANGE_FILTER =
	"bago/publicNotice_Reducer/SET_PRICE_RANGE_FILTER";

const SET_NO_ACTIVE_NOTICE_ERROR =
	"bago/publicNotice_Reducer/SET_NO_ACTIVE_NOTICE_ERROR";

const initialState: publicNoticeType = {
	notices: [],
	bestNotices: [],
	bestNoticesAllList: [],
	topInRubrics: [],
	topInRubricsAllList: [],
	topInRubricsPag: {
		currentPage: 1,
		pageCount: 1,
		perPage: 1,
		totalCount: 1,
	},
	noticeListFavorite: [],
	noticeAllListFavorite: [],
	favoriteStatByCategory: [],
	viewedStatistic: [],
	favoriteStatByCategoryIds: [0],
	viewedStatisticIds: [0],
	favoriteNoticeMeta: {
		currentPage: 1,
		pageCount: 1,
		perPage: 1,
		totalCount: 1,
	},
	recentlyWatchedNotices: [],
	noticeView: {
		id: null,
		name: "",
		slug: "",
		description: "",
		price: "",
		price_type: null,
		price_typeName: "",
		status: null,
		statusName: "",
		type: null,
		typeName: "",
		users_id: null,
		userName: "",
		category_id: null,
		category_name: "",
		settlement_id: null,
		settlement_name: "",
		image_dir: "",
		views: 0,
		phone_shows: null,
		not_show_other: null,
		state: "",
		stateName: "",
		trade: null,
		exchange: null,
		delivery_ids: null,
		amount: null,
		start_at: null,
		finished_at: null,
		auto_continue: null,
		auto_lift_days: null,
		auto_lift_at: null,
		top_days: null,
		top_at: null,
		created_at: null,
		created_by: null,
		updated_at: null,
		updated_by: null,
		published_at: null,
		seeNow: null,
		noticeImages: [
			{
				name: "",
				tmpName: "",
				size: null,
				type: "",
				accepted: null,
				imageUrl: "",
				originalImageUrl: "",
			},
		],
		tree: [],
		short_settlement_name: "",
		moderation_description: "",
		imagePath: "",
		isFavorite: 0,
		ownerData: {
			online: false,
			time_last_visit: null,
			reputation: null,
			photoUrl: null,
		},
		ownerBPData: {
			slug: "",
			status: 0,
			statusName: "",
		},
		ownerContacts: {
			ownerPhone: "",
			time_registration: null,
			viber: 0,
			whatsapp: 0,
			telegram: 0,
		},
	},
	filteredNotices: [],
	filteredNoticesAllList: [],
	filteredNoticesPagination: {
		currentPage: 1,
		pageCount: 1,
		perPage: 1,
		totalCount: 1,
	},
	filtersRangeData: { min: 0, max: 0 },
	countersForNotices: [],
	showLoader: false,
	priceRange: [],
	priceRangeFilter: [],
	notice_price_diapason: { minPrice: "", maxPrice: "" },
	noActiveNoticeError: false,
};

const publicNotice_Reducer = (state = initialState, action: ActionType) => {
	switch (action.type) {
		case SET_SLIDER_PRICE:
			return {
				...state,
				notice_price_diapason: action.data,
			};
		case SET_PRICE_RANGE:
			return {
				...state,
				priceRange: action.data,
			};
		case SET_PRICE_RANGE_FILTER:
			return {
				...state,
				priceRangeFilter: action.data,
			};
		case SET_ALL_NOTICE_LIST:
			return {
				...state,
				notices: action.data.data,
			};
		case SET_BEST_NOTICE_LIST:
			return {
				...state,
				bestNotices: action.data,
			};
		case SET_BEST_NOTICE_ALL_LIST:
			return {
				...state,
				bestNoticesAllList: action.data,
			};
		case SET_NOTICE_VIEW:
			return {
				...state,
				noticeView: action.data,
			};
		case SET_FILTERED_NOTICE_LIST:
			return {
				...state,
				filteredNotices: action.data.data.items,
				favoriteStatByCategory: action.data.favoriteStatByCategory,
				viewedStatistic: action.data.viewedStatistic,
			};
		case SET_FILTERED_NOTICE_All_LIST:
			return {
				...state,
				filteredNoticesAllList: action.data,
			};
		case SET_CLEAR_NOTICE_LIST:
			return {
				...state,
				filteredNotices: [],
			};
		case SET_TOP_RUBRICS_LIST:
			return {
				...state,
				topInRubrics: action.data.data.items,
				topInRubricsPag: action.data.data._meta,
			};
		case SET_TOP_RUBRICS_ALL_LIST:
			return {
				...state,
				topInRubricsAllList: action.data,
			};
		case SET_CATEGORIES_IDS:
			return {
				...state,
				favoriteStatByCategoryIds: action.data,
			};
		case SET_FILTERED_NOTICE_PAG:
			return {
				...state,
				filteredNoticesPagination: action.data,
			};
		case SET_ADD_DEL_FAVORITE:
			return {
				...state,
				noticeView: {
					...state.noticeView,
					isFavorite: action.data,
				},
			};
		case SET_ADD_DEL_FAVORITE_IN_FAVORITE_LIST:
			return {
				...state,
				noticeListFavorite: state.noticeListFavorite.filter(
					(n) => n.id !== action.data.id
				),
			};

		case SET_ADD_DEL_FAVORITE_IN_LIST:
			const updateSingleArray = (originalArray: any) => {
				const noticeIndex = originalArray.findIndex(
					(item: any) => item.id === action.data.id
				);
				if (noticeIndex !== -1) {
					const updatedArray = [...originalArray];
					updatedArray[noticeIndex] = {
						...updatedArray[noticeIndex],
						isFavorite: +action.data.action,
					};
					return updatedArray;
				}
				return originalArray;
			};

			return {
				...state,
				filteredNotices: updateSingleArray(state.filteredNotices),
				recentlyWatchedNotices: updateSingleArray(state.recentlyWatchedNotices),
				bestNotices: updateSingleArray(state.bestNotices),
			};

		case SET_FAVORITE_NOTICE_LIST:
			return {
				...state,
				noticeListFavorite: action.data,
			};
		case SET_ALL_FAVORITE_LIST:
			return {
				...state,
				noticeAllListFavorite: action.data,
			};
		case SET_CLEAR_FAVORITE_LIST:
			return {
				...state,
				noticeListFavorite: [],
				favoriteStatByCategory: [],
			};

		case SET_DELETE_ALL_NOTICE_LIST:
			return {
				...state,
				noticeListFavorite: [],
				viewedStatistic: [],
				filteredNotices: state.filteredNotices.map((n) => ({
					...n,
					isFavorite: 0,
				})),
			};
		case SET_RECENTLY_WATCHED_LIST:
			return {
				...state,
				recentlyWatchedNotices: action.data,
			};
		case SET_CLEAR_RECENTLY_WATCHED_LIST:
			return {
				...state,
				filteredNotices: [],
				viewedStatistic: [],
			};
		case SET_CURRENT_COUNTERS:
			return {
				...state,
				countersForNotices: action.data,
			};
		case SET_LOADER_SHOW:
			return {
				...state,
				showLoader: action.bool,
			};
		case SET_NO_ACTIVE_NOTICE_ERROR:
			return {
				...state,
				noActiveNoticeError: action.bool,
			};
		default:
			return state;
	}
};

export const setAllNoticeList = (data: any) => ({
	type: SET_ALL_NOTICE_LIST,
	data,
});
export const setAllFavoriteList = (data: any) => ({
	type: SET_ALL_FAVORITE_LIST,
	data,
});
export const setBestNoticeList = (data: any) => ({
	type: SET_BEST_NOTICE_LIST,
	data,
});
//export const setBestNoticeAllList = (data: any) => ({type: SET_BEST_NOTICE_ALL_LIST, data});
export const setNoticeView = (data: any) => ({ type: SET_NOTICE_VIEW, data });
export const setFilteredNotices = (data: any) => ({
	type: SET_FILTERED_NOTICE_LIST,
	data,
});
export const setFilteredNoticesAllList = (data: any) => ({
	type: SET_FILTERED_NOTICE_All_LIST,
	data,
});
export const setClearFilteredNotices = () => ({ type: SET_CLEAR_NOTICE_LIST });
export const setCategoriesIdsList = (data: any) => ({
	type: SET_CATEGORIES_IDS,
	data,
});
export const setFavoriteNotices = (data: any) => ({
	type: SET_FAVORITE_NOTICE_LIST,
	data,
});
export const setFilteredPagination = (data: any) => ({
	type: SET_FILTERED_NOTICE_PAG,
	data,
});
export const setAddDelFavorite = (data: any) => ({
	type: SET_ADD_DEL_FAVORITE,
	data,
});
export const setAddDelFavoriteInFavoriteList = (data: any) => ({
	type: SET_ADD_DEL_FAVORITE_IN_FAVORITE_LIST,
	data,
});
export const setAddDelFavoriteInList = (data: any) => ({
	type: SET_ADD_DEL_FAVORITE_IN_LIST,
	data,
});
export const setDelAllFavoriteInList = () => ({
	type: SET_DELETE_ALL_NOTICE_LIST,
});
export const setRecentlyWatchedList = (data: any) => ({
	type: SET_RECENTLY_WATCHED_LIST,
	data,
});
export const setClearRecentlyWatchedList = (data: any) => ({
	type: SET_CLEAR_RECENTLY_WATCHED_LIST,
	data,
});
export const setClearFavoriteList = (data: any) => ({
	type: SET_CLEAR_FAVORITE_LIST,
	data,
});
export const setTopRubrics = (data: any) => ({
	type: SET_TOP_RUBRICS_LIST,
	data,
});
export const setTopRubricsAllList = (data: any) => ({
	type: SET_TOP_RUBRICS_ALL_LIST,
	data,
});
export const setCurrentCounters = (data: []) => ({
	type: SET_CURRENT_COUNTERS,
	data,
});
export const setLoaderShow = (bool: boolean) => ({
	type: SET_LOADER_SHOW,
	bool,
});
export const setNoActiveNoticeError = (bool: boolean) => ({
	type: SET_NO_ACTIVE_NOTICE_ERROR,
	bool,
});

export const setPriceRange = (data: number | number[]) => ({
	type: SET_PRICE_RANGE,
	data,
});
export const setPriceRangeFilter = (data: number | number[]) => ({
	type: SET_PRICE_RANGE_FILTER,
	data,
});
export const setSliderPrice = (data: any) => ({ type: SET_SLIDER_PRICE, data });

export const getNoticeView =
	(id?: string, itemSlug?: string, expand?: string) =>
	async (dispatch: Dispatch<any>) => {
		try {
			dispatch(setNoActiveNoticeError(false));
			dispatch(setLoaderShow(true));
			const response: any = await publicNotice.getPublicNoticeView(
				id,
				itemSlug,
				expand
			);

			if (response.data.notice) {
				dispatch(setNoticeView(response.data.notice));
			}
			dispatch(setLoaderShow(false));
		} catch (error: any) {
			dispatch(setNoActiveNoticeError(true));
			console.log(error.message);
		}
	};
export const getFilteredNoticeList =
	(props: NoticeIndexFilter) => async (dispatch: Dispatch<any>) => {
		dispatch(setLoaderShow(true));
		try {
			const response: any = await publicNotice.getFilteredNotices(props);

			if (response.data.priceRange) {
				const min = response.data.priceRange.minPrice;
				const max = response.data.priceRange.maxPrice;
				dispatch(setPriceRange([+min, +max]));
			}
			if (response.data.data.items) {
				dispatch(setFilteredNotices(response.data));
				dispatch(setFilteredPagination(response.data.data._meta));
				dispatch(setCurrentCounters(response.data.counters));
				if (props.onlyFavorite === "1") {
					dispatch(setFavoriteNotices(response.data.data.items));
					//dispatch(setFilteredPagination(response.data._meta))
				}
				if (props.onlyViewed === "1") {
					dispatch(setFilteredNotices(response.data.data.items));
				}
			}
			if (response.data.searchedCategories) {
				dispatch(setCategoriesList(response.data.searchedCategories));
			}
			if (response.data.counters) {
				dispatch(setCategoryCounters(response.data.counters));
			}
			dispatch(setLoaderShow(false));
		} catch (error: any) {
			console.log(error.message);
		}
	};
/*export const getBestNoticeList = (props: NoticeIndexFilter) => async (dispatch: Dispatch<any>) => {
    try {
        const response: any = await publicNotice.getFilteredNotices(props)

        if (response.data.data.items) {
            dispatch(setBestNoticeList(response.data.data.items))
            dispatch(setFilteredPagination(response.data.data._meta))

        }
    } catch (error: any) {
        console.log(error.message)
    }
}*/

export const getAllNoticeList =
	(type?: number) => async (dispatch: Dispatch<any>) => {
		try {
			const response: any = await publicNotice.getAllPublicNotices();
			if (response.data.items) {
				dispatch(setAllNoticeList(response.data.items));
			}
			if (type) {
				const response: any = await publicNotice.getAllPublicNotices(type);
				if (response.data.items) {
					dispatch(setBestNoticeList(response.data.items));
				}
			}
		} catch (error: any) {
			console.log(error.message);
		}
	};
export const favoriteHandler =
	(id: number, action: string) =>
	async (dispatch: Dispatch<any>, getState: any) => {
		const state: StateType = getState();
		const currentCountFavorites = +state.userData.userStatic.userCountFavorites;
		const currentAction = action === "add" ? 1 : 0;
		try {
			const response: any = await publicNotice.addOrRemoveNoticeInFavorite(
				id,
				action
			);
			if (response.data.result) {
				dispatch(setAddDelFavorite(currentAction));
				dispatch(setAddDelFavoriteInList({ action: currentAction, id }));
				dispatch(
					setAddDelFavoriteInFavoriteList({ action: currentAction, id })
				);
				dispatch(
					setFavoriteCount(
						currentAction === 1
							? currentCountFavorites + 1
							: currentCountFavorites - 1
					)
				);
				dispatch(getUserStaticView());
			}
		} catch (error: any) {
			console.log(error.message);
		}
	};
export const deleteAllFromFavorite = () => async (dispatch: Dispatch<any>) => {
	try {
		const response: any = await publicNotice.deleteAllFromFavorite();
		if (response.data.result) {
			dispatch(setDelAllFavoriteInList());
			dispatch(setFavoriteCount(0));
			dispatch(setClearFavoriteList([]));
		}
	} catch (error: any) {
		console.log(error.message);
	}
};
export const deleteAllFromRecentlyWatched =
	() => async (dispatch: Dispatch<any>) => {
		try {
			const response: any = await publicNotice.deleteAllFromRecentlyWatched();
			if (response.data.result) {
				dispatch(setClearRecentlyWatchedList([]));
			}
		} catch (error: any) {
			console.log(error.message);
		}
	};
export const getTopInRubrics =
	(props: NoticeIndexFilter) => async (dispatch: Dispatch<any>) => {
		try {
			const response: any = await publicNotice.getFilteredNotices(props);
			if (response.data) {
				dispatch(setTopRubrics(response.data));
				dispatch(setFilteredPagination(response.data.data._meta));
			}
		} catch (error: any) {
			console.log(error.message);
		}
	};

export default publicNotice_Reducer;
