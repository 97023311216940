import { DevSupport } from "@react-buddy/ide-toolbox";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import React from "react";
import ReactDOM from "react-dom/client";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { HelmetProvider } from "react-helmet-async";
import { initReactI18next } from "react-i18next";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import Notification from "./Components/Notification/Notification";
import { ComponentPreviews, useInitial } from "./dev";
import "./index.css";
import store from "./redux/redux-store";
import reportWebVitals from "./reportWebVitals";
import { ru } from "./translate";

/*6LfTBfAUAAAAAPqIrFmQ24dE4oKhU7F8efdxSpeZ */


i18n
	.use(LanguageDetector)
	.use(Backend)
	.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		debug: false,
		fallbackLng: "uk",
		resources: {
			ru: ru,
		},
		react: {
			useSuspense: true,
		},
	});

const queryClient = new QueryClient({});

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);

root.render(
	<React.StrictMode>
		<HelmetProvider>
			<GoogleReCaptchaProvider
				reCaptchaKey="6LfTBfAUAAAAAPqIrFmQ24dE4oKhU7F8efdxSpeZ"
				language={i18n.language}
			>
				<Provider store={store}>
					<BrowserRouter>
						<QueryClientProvider client={queryClient}>
							<GoogleOAuthProvider clientId="61015268502-7412vjh0im1v90db9uek7kvvq8m1tpi7.apps.googleusercontent.com">
								<DevSupport
									ComponentPreviews={ComponentPreviews}
									useInitialHook={useInitial}
								>
									<App />
								</DevSupport>
							</GoogleOAuthProvider>
							{/* <ReactQueryDevtools
								buttonPosition="bottom-left"
								initialIsOpen={false}
							/> */}
						</QueryClientProvider>
					</BrowserRouter>
					<Notification />
				</Provider>
			</GoogleReCaptchaProvider>
		</HelmetProvider>
	</React.StrictMode>
);

reportWebVitals();
