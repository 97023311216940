import React from "react";
import styles from "./Checkbox.module.css";

interface CheckboxProps {
	label: string;
	checked?: boolean;
	onChange?: (checked: boolean) => void;
	disabled?: boolean;
	placeholder?: string;
	onChangeText?: (value: string) => void;
	value?: string;
}

const Checkbox: React.FC<CheckboxProps> = ({
	label,
	checked = false,
	onChange,
	disabled = false,
	placeholder,
	onChangeText,
	value,
}) => {
	const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		const newValue = e.target.value;
		onChangeText?.(newValue);
		// Automatically check the checkbox when text is entered
		if (newValue && !checked) {
			onChange?.(true);
		}
	};

	return (
		<div className={styles.checkBoxContainer}>
			<label className={label === "Власний варіант" ? styles.label : styles.labelText}>
				<input
					className={styles.checkboxInput}
					type="checkbox"
					checked={checked}
					onChange={(e) => {
						if (disabled) return;
						onChange?.(e.target.checked);
					}}
					disabled={disabled}
				/>
				<span className={styles.customCheckbox}></span>
				{label === "Власний варіант" ? (
					<div className={`${styles.fieldSide} ${styles.textArea} `}>
						<textarea
							name={"description"}
							value={value}
							onChange={handleTextChange}
							placeholder={placeholder}
						/>
						<div className={styles.descriptionOptions}>
							<div className={styles.descriptionOptions_Size}>
								{value ? value.length : 0}/8000
							</div>
						</div>
					</div>
				) : (
					<span className={styles.labelText}>{label}</span>
				)}
			</label>
		</div>
	);
};

export default Checkbox;
