import React, { useEffect, useState } from "react";
import s from "./ProductCard.module.css";
import {
	publicOneNotice,
	TreeLinksType,
} from "../../../types/publicNoticeTypes";
import { formattingPrice, testActualityService } from "../../../supporting";
import FavoriteHeartSelector from "../FavoriteHeartSelector/FavoriteHeartSelector";
import SearchCardSvgSelector from "../FavoriteSearchCard/SearchCardSvgSelector";
import { useNavigate } from "react-router";
import PreLoaderComponent from "../../PreLoaderComponent/PreLoaderComponent";
import { useTranslation } from "react-i18next";

const ProductCard = ({
	inSwiper,
	notice,
}: {
	inSwiper: boolean;
	notice: publicOneNotice;
}) => {
	const { i18n } = useTranslation();
	const noticeTree = notice?.tree?.map((s: TreeLinksType) => s.alias);
	const noticeUrl = noticeTree?.join("/");
	const navigate = useNavigate();
	const [isImageLoad, setIsImageLoad] = useState(false);
	const currentLanguage = i18n.language === "ru" ? "" : "/ua";
	const navigateOnClick = () => {
		navigate(
			`${currentLanguage}/o/${noticeUrl ? noticeUrl + "/" : ""}${notice?.slug}`
		);
	};
	const imageUrl = notice?.noticeImages?.[0]?.originalImageUrl ?? "";

	useEffect(() => {
		const image = new Image();
		image.src = imageUrl;

		image.onload = () => {
			// Когда изображение загружено, устанавливаем состояние isLoading в false
			setIsImageLoad(true);
		};

		// Обработка ошибок загрузки изображения
		image.onerror = () => {
			// Можно добавить логику для обработки ошибок
			setIsImageLoad(true);
		};
	}, [imageUrl]);
	return (
		<div key={notice.id} className={s.productCardWrapper}>
			<div className={`${s.productCardContainer} ${inSwiper && s.inSwiper}`}>
				<div className={s.cardImage} onClick={navigateOnClick}>
					{isImageLoad ? (
						<img
							loading={"lazy"}
							src={notice?.noticeImages[0]?.originalImageUrl}
							alt={notice?.slug || "image"}
						/>
					) : (
						<PreLoaderComponent type={"image"} size={"compact"} />
					)}

					{testActualityService(notice?.top_at ? notice.top_at : null) && (
						<span>
							<SearchCardSvgSelector id={"greenTop"} />
						</span>
					)}
				</div>
				<div className={s.cardTitle}>
					<p>{notice?.name || ""}</p>
					<FavoriteHeartSelector
						id={notice?.id || 0}
						isFavorite={notice?.isFavorite || 0}
						userId={notice?.users_id || 0}
					/>
				</div>
				<div className={s.cardPrice}>
					<span>
						{notice?.price && notice.price !== "0.00"
							? `${formattingPrice(notice?.price)} грн`
							: "Безкоштовно"}
					</span>
					{/*<SearchCardSvgSelector id={'greenCart'} />*/}
				</div>
				<div className={s.date}>
					<span>
						{notice?.short_settlement_name !== ""
							? notice?.short_settlement_name
							: notice?.settlement_name || ""}
					</span>
				</div>
			</div>
		</div>
	);
};

export default ProductCard;
