import { useEffect, useRef, useState } from "react";
import s from "./OutputBoardAD.module.css";
// import style from './ListOutputTemplateForProductCardMobile.module.css';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import NoRegisterAdCardAdaptive from "../../Components/AdCard/NoRegisterAdCardAdaptive";
import FavoriteHeartSelector from "../../Components/Cards/FavoriteHeartSelector/FavoriteHeartSelector";
import MobileAdGallery from "../../Components/ImageGallery/MobileAdGallery";
import ListOutputTemplateForProductCardMobile from "../../Components/ListOutputTemplateForProductCardMobile/ListOutputTemplateForProductCardMobile";
import OutputAdFooterInfoPanel from "../../Components/OutputBoardAdComponents/OutputADFooterInfoPanel";
import OutputADSvgSelector from "../../Components/OutputBoardAdComponents/OutputADSvgSelector";
import VerticalScrollBar from "../../Components/OutputBoardAdComponents/VerticalScrollBar";
import Advertising from "../../Components/Сommercial/Advertising";
import { getCategoryBlocks } from "../../redux/category_Reducer";
import {
	getNoticeView,
	getTopInRubrics,
	setTopRubricsAllList,
} from "../../redux/publicNotice_Reducer";
import { StateType } from "../../redux/redux-store";
import { Mobile } from "../../responsive";
import { formattingPrice } from "../../supporting";
import { stateUserType } from "../../types/reduxStoreTypes";
import { OtherAdsByAuthor } from "../../Components/OtherAdsByAuthor/OtherAdsByAuthor";
import WorkNotice from "./WorkNotice/WorkNotice";

interface Props {
	scrollBarData?: {
		title: string;
		description: string;
		properties: any;
		state: string;
	};
	topInRubrics: any;
	isWorkNotice?: boolean;
	card: any;
}

const OutputBoardAdMobile = ({ scrollBarData, topInRubrics, isWorkNotice, card }: Props) => {
	const targetRef = useRef<HTMLDivElement>(null);
	const [isVisible, setIsVisible] = useState(false);
	const noticeView = useSelector(
		(state: StateType) => state.publicNotice.noticeView
	);
	const getNotice = useSelector(() => getNoticeView);
	const { itemId, itemSlug } = useParams();
	const dispatch: any = useDispatch();
	const auth = useSelector((state: stateUserType) => state.userData.isAuth);
	const getBlocks = useSelector(() => getCategoryBlocks);
	const blockState = useSelector(
		(state: StateType) => state.categoryData.blockState
	);
	const blockDelivery = useSelector(
		(state: StateType) => state.categoryData.blockDelivery
	);
	const userView = useSelector((state: StateType) => state.userData.user);
	const notices = useSelector(
		(state: StateType) => state.publicNotice.recentlyWatchedNotices
	);
	const topInRubricsStore = useSelector(
		(state: StateType) => state.publicNotice.topInRubrics
	);
	const topInRubricsAllList = useSelector(
		(state: StateType) => state.publicNotice.topInRubricsAllList
	);
	const getTopInThisRubrics = useSelector(() => getTopInRubrics);
	const { t } = useTranslation();
	const [currentPage, setCurrentPage] = useState(1);

	useEffect(() => {
		if (topInRubricsStore && topInRubricsStore.length) {
			if (currentPage == 0 || currentPage == 1) {
				dispatch(setTopRubricsAllList(topInRubricsStore));
			} else {
				const newArr = [...topInRubricsAllList, ...topInRubricsStore];
				dispatch(setTopRubricsAllList(newArr));
			}
		}
	}, [topInRubricsStore]);

	useEffect(() => {
		const id = noticeView.category_id;
		if (id) {
			dispatch(getBlocks(id));
			dispatch(
				getTopInThisRubrics({
					category_id: +id,
					perPage: "4",
					page: currentPage,
				})
			);
		}
	}, [noticeView]);

	useEffect(() => {
		if (itemId) {
			dispatch(getNotice(itemId));
		}
		const observer = new IntersectionObserver((entries) => {
			entries.forEach((entry) => {
				setIsVisible(entry.isIntersecting);
			});
		});

		if (targetRef.current) {
			observer.observe(targetRef.current);
		}

		return () => {
			if (targetRef.current) {
				observer.unobserve(targetRef.current);
			}
		};
	}, []);

	return (
		<div className={s.mobileAdContainer}>
			{isWorkNotice
			? <WorkNotice card={card} />
			:	<>
				<div className={s.topRelativeWrapper}>
					<div className={s.mobileGalleryContainer}>
						<MobileAdGallery
							items={noticeView?.noticeImages || []}
							alt={noticeView?.slug}
							itemSlug={itemSlug ?? ""}
						/>
					</div>
					<div className={s.topTitleContainer}>
						<div className={s.titlePrice}>
							<span className={s.price}>
								{noticeView?.price && noticeView.price !== "0.00"
									? `${formattingPrice(noticeView?.price)} грн`
									: t("Безкоштовно")}
							</span>
							<div className={s.orangeHeart}>
								{noticeView?.users_id && +noticeView?.users_id !== userView.id ? (
									<>
										<FavoriteHeartSelector
											isFavorite={noticeView?.isFavorite || 0}
											id={noticeView?.id || 0}
											userId={noticeView?.users_id || 0}
											isOrange={true}
										/>
									</>
								) : (
									<></>
								)}
							</div>
						</div>
						<div className={s.titleName}>
							<span className={s.name}>{scrollBarData?.title}</span>
						</div>
						{/* <div className={s.blackButtonContainer}>
													<button>
															Оформити замовлення з BAGO
													</button>
											</div> */}
					</div>
				</div>
				<div className={s.topTitle_infoPanelBottom}>
					<span className={s.nowSeen}>
						{t("Зараз дивляться")}: {noticeView?.seeNow}
					</span>
					<span className={s.allSeen}>
						{t("Переглядів")}: {noticeView?.views}
					</span>
				</div>
				<div ref={targetRef} className={s.scrollBarMobileContainer}>
					<VerticalScrollBar
						scrollBarData={scrollBarData}
						marginTop={"0"}
						isShowStateProduct={blockState}
						isShowDelivery={blockDelivery}
					/>
				</div>
			</>}
			<div className={s.warningIcon}>
				<OutputADSvgSelector id={"warningComplain"} />
				<span>{t("Поскаржитись")}</span>
			</div>
			<div style={{ display: isVisible ? "" : "none" }}>
				<OutputAdFooterInfoPanel />
			</div>
			<Mobile>
				<div className={s.mainCategoryAds}>
					<Advertising idAD={5} isMobile={1} />
				</div>
			</Mobile>
			{/*<div className={s.advertisingContainer}></div>*/}
			<div className={s.withSeeContainer}>
				{/* <NewAdverts title={"З цим переглядають"}/> */}
			</div>
			{!auth && <NoRegisterAdCardAdaptive />}
			<OtherAdsByAuthor
				notice={noticeView.ownerBPData}
				userId={noticeView ? noticeView.users_id : 0}
				title={t("Інші оголошення автора")}
			/>

			<div className={s.withSeeContainer}>
				{/* <NewAdverts title={"Схожі оголошення"}/> */}
			</div>
			{/*<div className={s.advertisingContainerLittle}></div>*/}
			<div className={s.withSeeContainer}>
				<ListOutputTemplateForProductCardMobile
					pageCount={currentPage}
					setPageCount={setCurrentPage}
					title={(isWorkNotice ? t("Інші вакансії в цій рубриці") : t("Інші товари в цій рубриці")) + ":"}
					notices={topInRubricsAllList}
					categoryId={noticeView.category_id?.toString() ?? ""}
				/>
			</div>
			<div className={s.withSeeContainer}>
				<ListOutputTemplateForProductCardMobile
					title={t("Раніше переглянуті") + ":"}
					notices={notices}
					categoryId={noticeView.category_id?.toString() ?? ""}
				/>
			</div>
			<Mobile>
				<div className={s.mainCategoryAds}>
					<Advertising idAD={6} isMobile={1} />
				</div>
			</Mobile>
		</div>
	);
};

export default OutputBoardAdMobile;
