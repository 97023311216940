import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setDependFiltersList, setFiltersList } from "../redux/category_Reducer";
import { Filter, FiltersValue } from "../types/publicNoticeTypes";
import i18n from "i18next";
import { StateType } from "../redux/redux-store";

const useSearchFilterHandle = () => {
	const dispatch = useDispatch();
	
	const handleClickSearchFilter = useCallback(
		(
			id: number,
			filtersValues: FiltersValue[],
			filter: Filter,
			setFiltersValues: React.Dispatch<React.SetStateAction<FiltersValue[]>>
		) => {
			const existingIndex = filtersValues?.findIndex(
				(item) =>
					item.value === id.toString() && item.filter === filter.inputName
			);

			if (existingIndex !== -1) {
				const updatedValue = [...filtersValues];
				updatedValue.splice(existingIndex, 1);
				const filterId = filtersValues.find(
					(item) =>
						item.value === id.toString() && item.filter === filter.inputName
				)?.id;

				if (filter.parent_id === null) {
					const filteredUpdate = updatedValue.filter(
						(uV) => uV.parent_id !== filter.id
					);
					setFiltersValues(filteredUpdate);
				} else {
					setFiltersValues(updatedValue);
				}
				// Проверяем необходимость dispatch, чтобы уменьшить количество вызовов
				if (updatedValue.length === 0 || filterId) {
					dispatch(setDependFiltersList([]));
				}
			} else {
				const parent_name = filter.propertyValues.find((pV) => +pV.id === id);
				const newValue: FiltersValue = {
					filter: filter?.inputName,
					value: id.toString(),
					parent_id: filter.parent_id,
					name: parent_name
						? i18n.language === "ru"
							? parent_name.name_ru
							: parent_name.name
						: null,
					id: filter.id,
				};
				setFiltersValues([...filtersValues, newValue]);
			}
		},
		[dispatch]
	); // Используем useCallback с зависимостью от dispatch

	return { handleClickSearchFilter };
};

export default useSearchFilterHandle;
