import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import OutputADSvgSelector from "../../../Components/OutputBoardAdComponents/OutputADSvgSelector";
import { StateType } from "../../../redux/redux-store";
import { Desktop, Mobile } from "../../../responsive";
import { formattingPrice } from "../../../supporting";
import s from "./WorkNotice.module.css";
import { stateUserType } from "../../../types/reduxStoreTypes";
import PublishedTime from "../../../Components/TimeAndDate/PublishedTime";
import SellerCardPopup from "../../../Components/SellerCard/SellerCardPopup";
import FavoriteHeartSelector from "../../../Components/Cards/FavoriteHeartSelector/FavoriteHeartSelector";
import { useNavigate } from "react-router";
import { favoriteHandler } from "../../../redux/publicNotice_Reducer";

type Props = {
	handleOpenLogin?: () => void;
	handleOpenCompainModal?: () => void;

	card?: {
		sellerName: string | null;
		sellerId: string;
		price?: string;
		maxSalary?: string;
		location?: string | null;
		ownerBPData: {
			slug?: string;
			status?: number;
			statusName?: string;
		};
		ownerData?: {
			online: boolean;
			time_last_visit: number | null;
			photoUrl: string | null;
		};
		views: number | null;
		isFavorite: number;
		ownerPhone: string | null;
		time_registration: number | null;
		viber: number;
		whatsapp: number;
		telegram: number;
		reputation: number | null;
		seeNow: number | null;
	} | null | any;
};

const WorkNotice: React.FC<Props> = ({
	handleOpenLogin,
	handleOpenCompainModal,
	card
}) => {
  const { t } = useTranslation();
	
	const navigate = useNavigate();
	
	const dispatch: any = useDispatch();
	
	const [dialogOpen, setDialogOpen] = useState(false);
	const handleDialogOpen = () => {
		/*document.body.classList.add('dialog-open');*/
		setDialogOpen(true);
	};
	const handleDialogClose = () => {
		setDialogOpen(false);
	};
	
	const addDelInFavorite = useSelector(() => favoriteHandler);
	const isFavoriteHandler = (action: string) => {
		dispatch(addDelInFavorite(noticeView?.id || 0, action));
	};
	
	const auth = useSelector((state: stateUserType) => state.userData.isAuth);
	const noticeView = useSelector((state: StateType) => state.publicNotice.noticeView);
	const userView = useSelector((state: StateType) => state.userData.user);

	type PropertyType = {
		filterPosition: string,
		propertyName: string,
		propertyValues: string
	}
	const info = {
		title: noticeView?.name,
		description: noticeView?.description,
		vacancyType: noticeView?.noticeType?.vacancyType,
		workLocation: noticeView?.settlement_name,
		properties: noticeView?.notice_properties as PropertyType[],
		views: noticeView?.views,
		logo: noticeView?.noticeImages?.[0],
    users_id: noticeView?.users_id,
    id: noticeView?.id,
    isFavorite: noticeView?.isFavorite,
    seeNow: noticeView?.seeNow
	}

	const { title, description, vacancyType, workLocation, properties, views, logo,
		users_id, id, isFavorite, seeNow } = info;
	const { price, maxSalary } = card;

	const salary = maxSalary
		? `${t('від')} ${formattingPrice(price)} ${t('грн')} ${t('до')} ${formattingPrice(maxSalary)}` 
		: `${formattingPrice(price)} ${t('грн')}`

	const vacancyTypeList: { [key: string]: { icon: JSX.Element, color: string } } = {
		"Гаряча вакансія": {
			icon: <OutputADSvgSelector id={"fire"} />,
			color: '#E25528'
		},
		"Горячая вакансия": {
			icon: <OutputADSvgSelector id={"fire"} />,
			color: '#E25528'
		},
		"Відкрита вакансія": {
			icon: <OutputADSvgSelector id={"blueClock"} />,
			color: '#00A9CE'
		},
		"Открытая вакансия": {
			icon: <OutputADSvgSelector id={"blueClock"} />,
			color: '#00A9CE'
		}
	}

	const workInfoList = [
		{
			value: salary,
			icon: 'moneybag'
		},
		{
			value: workLocation,
			icon: 'location'
		},
	];

	const sellerInfo = {
		name: card?.sellerName || "",
		sellerId: card?.sellerId || "",
		ownerPhone: card?.ownerPhone || "",
		time_registration: card?.time_registration || 0,
		viber: card?.viber || 0,
		whatsapp: card?.whatsapp || 0,
		telegram: card?.telegram || 0,
		time_last_visit: card?.ownerData?.time_last_visit || 0,
		reputation: card?.reputation || null,
		ownerImage: card?.ownerData?.photoUrl || "",
		ownerBPData: card?.ownerBPData,
	};

  return (
	<>
		<Desktop>
			<div className={s.workContent}>
				<div className={s.workContentLeft}>
					<div className={s.workTop}>
						<div className={s.workTitle}>{title}</div>
						<div className={s.heart}>
							{card?.sellerId && +card?.sellerId !== userView.id && (
								<>
									{card?.isFavorite.toString() === "1" ? (
										<button
											onClick={() => {
												isFavoriteHandler("remove");
											}}
										>
											<OutputADSvgSelector id={"yellowHeart"} />
										</button>
									) : (
										<button
											onClick={() => {
												isFavoriteHandler("add");
											}}
										>
											<OutputADSvgSelector id={"heart"} />
										</button>
									)}
								</>
							)}
						</div>
					</div>

					<ul className={s.list}>
						{workInfoList.map(item => {
							if (!item.value) return null;
							return (
								<li key={item.value}>
									<OutputADSvgSelector id={item.icon} /> {item.value}
								</li>
							)
						})}
					</ul>

					{properties?.length ?
						<ul className={s.properties}>
							{properties.map((p: PropertyType) => 
								<li key={p.propertyValues}>{p.propertyValues}</li>
							)}
						</ul>
					: null}

					{description && (
						<div className={s.description}>
							{description}
						</div>
					)}

					<div className={s.workBottom}>
						{vacancyType && (
							<span className={s.vacancyType} style={{ color: vacancyTypeList[vacancyType as string]?.color }}>
								{vacancyTypeList[vacancyType as string]?.icon} {vacancyType}
							</span>
						)}
						<span>
							ID: {noticeView.id}
						</span>
						<span className={s.vies}>
							<OutputADSvgSelector id="eye" /> {views}
						</span>
						{auth ? (
							<div
								className={s.warning}
								onClick={handleOpenCompainModal}
							>
								<OutputADSvgSelector id={"warningComplain"} />
								{t("Поскаржитись")}
							</div>
						) : (
							<div className={s.warning} onClick={handleOpenLogin}>
								<OutputADSvgSelector id={"warningComplain"} />
								{t("Поскаржитись")}
							</div>
						)}
					</div>
				</div>

				<div className={s.workContentRight}>
					<div className={s.owner}>
						<h4
							className={card?.ownerData?.online ? s.onLineIndicator : ""}
							onClick={handleDialogOpen}
						>
							{card?.sellerName || ""}
						</h4>
						{!card?.ownerData?.online && (
							<div className={s.seeNow}>
								{t("Останній візит")}{" "}
								<PublishedTime
									time={card?.ownerData?.time_last_visit || 0}
								/>
							</div>
						)}
					</div>
					
					<div className={s.logoWrap}>
						{logo &&
							<img className={s.logo} src={logo.imageUrl} alt='logo'/>
						}
					</div>

					{card?.seeNow && (
						<div className={s.seeNow}>
								{t("Зараз дивляться")}: {card.seeNow}
						</div>
					)}

					<div className={s.buttonWrapper}>
						<button onClick={handleDialogOpen}>
							<OutputADSvgSelector id={"phone"} />
							<span>{t("Подзвонити")}</span>
						</button>
						<button>
							<OutputADSvgSelector id={"message"} />
							<span>{t("Відгукнутись")}</span>
						</button>
					</div>
				</div>

				
				{dialogOpen && (
					<SellerCardPopup
						sellerInfo={sellerInfo}
						dialogOpen={dialogOpen}
						dialogClose={handleDialogClose}
					/>
				)}
			</div>
		</Desktop>
		<Mobile>
			<div className={s.workTopMobile}>
				<button className={s.buttonGrey} onClick={() => navigate(-1)}>
					<OutputADSvgSelector id={"backMobileArrow"} />
				</button>
				{vacancyType && (
					<span style={{ color: vacancyTypeList[vacancyType as string]?.color }} className={`${s.vacancyType} ${s.mobile}`}>
						{vacancyTypeList[vacancyType as string]?.icon} {vacancyType}
					</span>
				)}
			</div>
			<div className={s.workHeadMobile}>
				<div className={s.workTitleWrap}>
					<h4 className={`${s.workTitle} ${s.mobile}`}>{title}</h4>
					{users_id && +users_id !== userView.id && (
						<FavoriteHeartSelector
							isFavorite={isFavorite || 0}
							id={id || 0}
							userId={users_id || 0}
							isOrange={true}
						/>
					)}
				</div>
				
				{logo &&
					<div className={s.logoWrap}>
						<img className={s.logo} src={logo.imageUrl} alt='logo'/>
					</div>
				}

				{seeNow && (
					<div className={s.seeNowMobile}>
							{t("Зараз дивляться")}: {seeNow}
					</div>
				)}
			</div>

			<ul className={`${s.list} ${s.mobile}`}>
				{workInfoList.map(item => {
					if (!item.value) return null;
					return (
						<li key={item.value}>
							<OutputADSvgSelector id={item.icon} /> {item.value}
						</li>
					)
				})}
			</ul>

			<div className={s.workContentMobile}>
				{properties?.length ?
					<ul className={`${s.properties} ${s.mobile}`}>
						{properties.map((p: PropertyType) => 
							<li key={p.propertyValues}>{p.propertyValues}</li>
						)}
					</ul>
				: null}

				<div className={`${s.workTitle} ${s.mobile}`}>{t('Описание вакансии')}</div>
				<div className={s.descriptionMobile}>{description}</div>
			</div>
		</Mobile>
	</>
  );
};

export default WorkNotice;