export const QueryKeys = {
	businessPage: (slug?: string) => ["get/business-page", slug],
	anotherBusinessPage: ["get/another-business-page"],
};

export const MutationKeys = {
	setAboutSeller: ["create/setAboutSeller"],
	setContactsSeller: ["create/setContactsSeller"],
	uploadBusinessImage: ["create/uploadBusinessImage"],
	setManageActivityBlock: ["create/setManageActivityBlock"],
	setPublishPage: ["create/setPublishPage"],
	setPublishPageSlug: ["create/setPublishPageSlug"],
	setPhoneSeller: ["setPhoneSeller"],
	setPhoneSMS: ["setPhoneSMS"],
	setApplySMS: ["setApplySMS"],
	setSloganPage: ["setSloganPage"],
};
