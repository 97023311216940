import { instance } from "../api";
import {
	BusinessPageData,
	ContactsSellerPayload,
	PaymentParams,
	SetManageActivityBlock,
} from "./types";

export const getBusinessPageData = async () => {
	return await instance.get<BusinessPageData>("business-page/data");
};

export const getBusinessUserPageData = async (slug: string) => {
	return await instance.get<BusinessPageData>("business-page/user-page", {
		params: {
			slug,
		},
	});
};

export const setAboutSeller = (about_seller: string) => {
	return instance.post("business-page/common/set-about-seller", {
		about_seller,
	});
};

export const setSlogan = (slogan: string) => {
	return instance.post("business-page/common/set-slogan", {
		slogan,
	});
};

export const setPhoneSeller = (phoneNumber: string, order: number) => {
	return instance.post("business-page/contacts/set-phone", {
		phoneNumber,
		order,
	});
};

export const setPhoneSMS = (phoneNumber: string) => {
	return instance.post("business-page/contacts/send-sms", { phoneNumber });
};

export const setApplySMS = (phoneNumber: string, smsCode: number) => {
	return instance.post("business-page/contacts/apply-sms", {
		phoneNumber,
		smsCode,
	});
};

export const setContactsSeller = async (contacts: ContactsSellerPayload) => {
	return await instance.post("business-page/contacts/set-contacts", {
		...contacts,
	});
};

export const setPayAndGuaranteeSetPayment = async (contacts: PaymentParams) => {
	return await instance.post("business-page/pay-and-guarantee/set-payment", {
		...contacts,
	});
};

export const setPayAndGuaranteeGuaranteeConditions = async (contacts: {
	var1: number;
	var2: number;
	var3: number;
	var4?: string | number | undefined;
	var5?: string | number | undefined;
}) => {
	return await instance.post(
		"business-page/pay-and-guarantee/set-guarantee-conditions",
		{ ...contacts }
	);
};

export const setPayAndGuaranteeReturnConditions = async (contacts: {
	var1: number;
	var2: number;
	var3: number;
	var4?: string | number;
	var5?: string | number;
}) => {
	return await instance.post(
		"business-page/pay-and-guarantee/set-return-conditions",
		{ ...contacts }
	);
};

export const uploadBusinessImage = async (params: {
	image: File;
	scenario: string;
}): Promise<void> => {
	const response = await instance.post(
		"/business-page/load-image",
		{ ...params },
		{
			headers: {
				"Content-Type": "multipart/form-data",
			},
		}
	);
	return response.data;
};

export const setManageActivityBlock = async (
	params: SetManageActivityBlock
) => {
	return await instance.post("business-page/common/manage-activity-bloc", {
		...params,
	});
};

export const setPublishPage = async (status?: number) => {
	return await instance.post("business-page/publish", { status });
};

export const setPublishPageSlug = async (slug: string) => {
	return await instance.post("business-page/set-slug", { slug });
};

export const setDeliveryService = (deliveryId: string, active: number) => {
	return instance.post("business-page/delivery/set-delivery-service", {
		deliveryId,
		active,
	});
};
