import React, { useEffect, useState } from "react";
import { FileRejection, useDropzone } from "react-dropzone";
import { Droppable, Draggable, DragDropContext } from "react-beautiful-dnd";
import s from "../CreateBoardADMobile.module.css";
import { useDispatch, useSelector } from "react-redux";
import fish from "../../../images/itemCardImage.jpg";
import { useTranslation } from "react-i18next";
import CreateBoardADSvg from "./CreateBoardADSvg";
import {
	removeImagesToTmp,
	sendImagesToTmp,
	setImagesList,
} from "../../../redux/notice_Reducer";
import { StateType } from "../../../redux/redux-store";
import { ActionCreator } from "redux";
type Props = {
	setFieldValue: (
		field: string,
		value: any,
		shouldValidate?: boolean | undefined
	) => void;
	isShowDefaultWindow: any;
	setIsShowDefaultWindow: any;
	singleImage?: boolean;
};

const ChangePhotoDropZoneMobile = ({
	setFieldValue,
	isShowDefaultWindow,
	setIsShowDefaultWindow,
	singleImage = false
}: Props) => {
	//const [isShowUploadModal, setIsShowUploadModal] = useState(false);
	// const [isShowTakeAPhotoModal, setIsShowTakeAPhotoModal] = useState(false);
	const [error, setError] = useState("");
	const { t } = useTranslation();
	const dispatch: any = useDispatch();
	const setImageListTmp = useSelector(() => sendImagesToTmp);
	const setRemoveImg = useSelector(() => removeImagesToTmp);
	const images = useSelector((state: StateType) => state.noticeData.images);
	const imagesTmp = useSelector(
		(state: StateType) => state.noticeData.tmpImages
	);
	const setImageList: ActionCreator<any> = useSelector(() => setImagesList);

	useEffect(() => {
		if (images.length > 0) {
			setIsShowDefaultWindow(true);
		} else {
			setIsShowDefaultWindow(false);
		}
	}, [images]);

	/* useEffect(() => {
        return () => {
            for (let i = 0; i < imagesTmp.length; i++) {
                dispatch(setRemoveImg(imagesTmp[i]?.tmpFileName, setError));
            }
            dispatch(setImageListTmp([]));
        }
    }, []);*/

	const handleModalClose = () => {
		// setIsShowUploadModal(false);
	};

	const onDrop = (acceptedFiles: any, fileRejections: FileRejection[]) => {
		const filteredFile = fileRejections.find(file =>
			file.errors.length === 1 && file.errors[0].code === 'too-many-files'
		);
		const files: File[] = singleImage && filteredFile
			? [filteredFile.file]
			: acceptedFiles;
		const newImages = files.map((file: File) => ({
			id: new Date().getTime().toString() + Math.random(),
			file,
		}));
		// Фильтруем новые изображения, чтобы исключить уже загруженные изображения
		const uniqueNewImages = newImages.filter((newImage: any) => {
			return !images.some(
				(existingImage: any) => existingImage.id === newImage.id
			);
		});
		// Добавляем только уникальные новые изображения к текущему списку images
		const updatedImages = singleImage ? uniqueNewImages : [...images, ...uniqueNewImages];
		// Обновляем список изображений в Redux хранилище и в значении формы
		dispatch(setImageListTmp(updatedImages, setError, images));
		setFieldValue("imageNames", updatedImages);
		//handleModalClose()
	};

	const removeImage = (id: string, indexImg: number) => {
		const newImages = images.filter((image) => image.id !== id);
		dispatch(setImageListTmp(newImages));
		const removableImg = imagesTmp.filter((img, index) => index === indexImg);
		dispatch(setRemoveImg(removableImg[0].tmpFileName, setError));
	};
	const { getRootProps, getInputProps } = useDropzone({
		onDrop,
		accept: {
			"image/png": [".png"],
			"image/jpg": [".jpg"],
			"image/jpeg": [".jpeg"],
			"image/gif": [".gif"],
			"image/webp": [".webp"],
		},
		maxFiles: singleImage ? 1 : undefined,
	});
	const onDragEnd = (result: any) => {
		// function for Drag n drop
		if (!result.destination) return; // Если перемещение не имеет места назначения, ничего не делаем
		const newImages = Array.from(images);
		const [movedImage] = newImages.splice(result.source.index, 1);
		// Удаляем изображение из исходной позиции

		newImages.splice(result.destination.index, 0, movedImage);
		// Вставляем изображение в новую позицию
		dispatch(setImageList(newImages)); // Обновляем состояние
	};
	const clearZone = () => {
		dispatch(setImagesList([]));
		setFieldValue("imageNames", []);
	};

	// Clear dropzone when change from multiple to single image
	useEffect(() => {
		if (singleImage && images.length > 1) {
			clearZone();
		}
	}, [singleImage]);

	return (
		<DragDropContext onDragEnd={onDragEnd}>
			{isShowDefaultWindow ? (
				<Droppable droppableId="imageListDesc" direction="horizontal">
					{(provided, snapshot) => {
						return (
							<div className={s.dropZoneImagesContainer}>
								<div
									{...provided.droppableProps}
									ref={provided.innerRef}
									className={s.imageList}
									id={"imageList"}
									style={{
										pointerEvents: snapshot?.isDraggingOver ? "none" : "auto",
									}}
								>
									{images.map((image, index) => {
										return (
											<Draggable
												key={image.id}
												draggableId={image.id}
												index={index}
											>
												{(provided, snapshot) => {
													return (
														<div
															key={image.id}
															ref={provided.innerRef}
															{...provided.draggableProps}
															{...provided.dragHandleProps}
															className={`${s.imageItem} ${
																snapshot.isDragging ? "dragging" : ""
															}`}
														>
															<div className={s.imageContainer}>
																<img
																	src={
																		image?.file instanceof File
																			? URL.createObjectURL(image?.file)
																			: fish
																	}
																	alt=""
																	onClick={(e) => {
																		e.stopPropagation();
																	}}
																/>
															</div>
															<div
																className={s.deleteButton}
																onClick={(e) => {
																	e.stopPropagation();
																	removeImage(image.id, index);
																	//removeImage(image.id, image?.file?.name, index);
																}}
															>
																{t("Видалити")}
															</div>
														</div>
													);
												}}
											</Draggable>
										);
									})}
									{provided.placeholder}
								</div>
								{!singleImage && (
									<div className={s.addNewPhotoBlock} {...getRootProps()}>
										<p>+ {t("Додати фото")}</p>
										<input {...getInputProps()} accept="image/*" />
									</div>
								)}
							</div>
						);
					}}
				</Droppable>
			) : (
				<div className={s.dropZoneContainer} {...getRootProps()}>
					<input {...getInputProps()} accept="image/*" />
					<div className={s.dropZoneBlock}>
						<div className={s.checkPhotoSvgContainer}>
							<div className={s.photoSvgBlock}>
								<CreateBoardADSvg id={"mobileCameraPicture"} />
								<span className={s.svgPlus}>+</span>
							</div>
						</div>
						<div className={s.checkPhotoLogoContainer}>
							<p>
								{t("Цікаво, а")} <span>ТУТ ФОТО</span>чки {t("будуть")}?
							</p>
							<CreateBoardADSvg id={"bagoLogo"} />
						</div>
					</div>
				</div>
			)}
			{error && (
				<span className={s.error}>
					{error || t("Помилка під час завантаження файлу")}
				</span>
			)}
		</DragDropContext>
	);
};

export default ChangePhotoDropZoneMobile;
