import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import s from "../SearchFilters/SearchFilters.module.css";
import ArrowsAndCross from "../../common/ArrowsAndCross/ArrowsAndCross";
import style from "./FilterComponent.module.css";
import useFilterHandle from "../../CustomHooks/useFilterHandle";

type Props = {
	filter: any;
	filtersMany: [];
	filtersOneIs: [];
};
const FilterTypeDropdown = ({ filter, filtersMany, filtersOneIs }: Props) => {
	const { i18n, t } = useTranslation();
	const [collapsed, setCollapsed] = useState(true);
	const { handleFilter } = useFilterHandle();

	useEffect(() => {
		if (filter?.showOpened) {
			setCollapsed(false);
		}
	}, [filter]);

	return (
		<div className={s.filterContainer}>
			<div className={`${s.filterTitle}`}>
				<div className={s.name}>
					{!filter?.needs ? (
						filter?.propertyName
					) : (
						<p>
							{filter?.propertyName} <span style={{ color: "red" }}>*</span>
						</p>
					)}
					{!filter && (
						<span
							className={s.colorFilterArrow}
							onClick={() => setCollapsed(!collapsed)}
						></span>
					)}
				</div>
				{!filter && <span>{t("Скинути")}</span>}
				{filter && (
					<span
						style={{
							transform: collapsed ? "" : "rotate(180deg)",
							transition: "0.5s",
						}}
						className={s.colorFilterArrow}
						onClick={() => setCollapsed(!collapsed)}
					>
						<ArrowsAndCross id={"arrowForSelect"} />
					</span>
				)}
			</div>
			{!collapsed && (
				<div
					className={`${s.filerContent} ${style.dropdownContent}`}
					style={{ borderTop: filter ? "1px solid #909090" : "" }}
				>
					<ul className={`${style.dropdownMenu}`}>
						{filter.propertyValues.map((f: any) => (
							<li
								key={f.id}
								className={` ${style.dropdownEl} ${
									filtersMany.findIndex(
										(item: any) =>
											item.id === f.id && item.filter === filter.inputName
									) !== -1 ||
									filtersOneIs.findIndex(
										(item: any) =>
											item.id === f.id && item.filter === filter.inputName
									) !== -1
										? style.active
										: ""
								}`}
								onClick={() => {
									handleFilter({ element: f, filter });
								}}
							>
								{(i18n.language === "ru" && f.name_ru) ||
									(i18n.language === "uk" && f.name) ||
									""}
							</li>
						))}
					</ul>
				</div>
			)}
		</div>
	);
};

export default FilterTypeDropdown;
