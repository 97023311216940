import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import itemCardImage from "../../../images/itemCardImage.jpg";
import style from "../../../pages/SearchResults/SearchResult.module.css";
import { formattingPrice, testActualityService } from "../../../supporting";
import {
	publicOneNotice,
	TreeLinksType,
} from "../../../types/publicNoticeTypes";
import PersonalCabinetSvgSelector from "../../PersonalCabinet/PersonalCabinetSvgSelector";
import PreLoaderComponent from "../../PreLoaderComponent/PreLoaderComponent";
import PublishedTime from "../../TimeAndDate/PublishedTime";
import FavoriteHeartSelector from "../FavoriteHeartSelector/FavoriteHeartSelector";
import s from "./ProductCardGrid.module.css";

const ProductCardGrid = ({
	hot,
	grid,
	notice,
	isNew = false,
	sort = "2",
	page = 1,
	perPage = 48
}: {
	hot?: boolean;
	grid: string;
	notice: publicOneNotice;
	isNew?: boolean;
	sort?: string;
	page?: number;
	perPage?: number;

}) => {
	const noticeTree = notice?.tree?.map((s: TreeLinksType) => s.alias);
	const noticeUrl = noticeTree?.join("/");
	const { i18n, t } = useTranslation();
	const currentLanguage = i18n.language === "ru" ? "" : "/ua";

	return (
		<Link
			to={`${currentLanguage}/o/${noticeUrl ? noticeUrl + "/" : ""}${notice?.slug}`}
		>
			<div
				className={`${s.searchCardContainer} ${style.border}`}
				style={{
					width: grid === "3" ? "307px" : grid === "5" ? "265px" : "",
					height: grid === "3" ? "425px" : "",
					padding: grid === "3" ? "10px" : "",
				}}
			>
				{!notice.noticeImages ? (
					<PreLoaderComponent type={"image"} size={"compact"} />
				) : (
					<div
						className={s.cardImage}
						style={{
							height: grid === "3" ? "280px" : "206px",
						}}
					>
						<img
							src={notice.noticeImages[0]?.originalImageUrl || itemCardImage}
							alt={notice?.slug || "image"}
						/>
						<div className={s.topFlag}>
							{notice.top_at && testActualityService(notice.top_at) && (
								<PersonalCabinetSvgSelector id={"top100"} />
							)}
						</div>
					</div>
				)}

				<div className={s.cardTitle}>
					<h4>{notice.name}</h4>

					<FavoriteHeartSelector
						id={notice?.id || 0}
						isFavorite={notice?.isFavorite || 0}
						userId={notice?.users_id || 0}
						sort={sort}
							page={page}
							perPage={perPage}
					/>
				</div>
				{/*<div
          className={`${s.cardPrice} ${hot && s.hot}`}
          style={{
            fontSize: grid === "3" ? "16px" : "",
          }}
        >
          <FavoriteHeartSelector
            id={notice?.id || 0}
            isFavorite={notice?.isFavorite || 0}
            userId={notice?.users_id || 0}
          />
        </div>*/}
				<div
					className={`${s.cardPrice} ${hot && s.hot}`}
					style={{
						fontSize: grid === "3" ? "16px" : "",
					}}
				>
					<span>
						{notice?.price && notice.price !== "0.00"
							? formattingPrice(notice?.price)
							: t("Безкоштовно")}
						<span
							style={{
								fontSize: grid === "3" ? "" : "16px",
							}}
						>
							{notice?.price && notice.price !== "0.00" ? " грн" : ""}
						</span>
					</span>
				</div>
				<div
					className={s.cardLocation}
					style={{
						fontSize: grid === "3" ? "14px" : "",
					}}
				>
					<span>
						{notice.short_settlement_name !== ""
							? notice.short_settlement_name
							: notice.settlement_name}
					</span>
				</div>
				<div className={s.cardTime}>
					<span>
						{" "}
						<PublishedTime
							time={isNew ? notice?.created_at || 0 : notice?.published_at || 0}
						/>
					</span>
					{/*<span className={s.hiddenInfo} onClick={(event)=>{
                        event.preventDefault();
                    }}>
                              <SearchCardSvgSelector id={"cardMenuIcon"}/>
                              <FavoriteSearchCardPopup/>
                    </span>*/}
				</div>
			</div>
		</Link>
	);
};

export default ProductCardGrid;
