import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import s from "../SearchFilters/SearchFilters.module.css";
import { FiltersValue } from "../../types/publicNoticeTypes";
import style from "../FilterComponent/FilterComponent.module.css";
import FilterFrontHead from "./FilterFrontHead/FilterFrontHead";
import useSearchFilterHandle from "../../CustomHooks/useSearchFilterHandle";
import useClearSearchFilter from "../../CustomHooks/useClearSearchFilter";


type Props = {
	filter: any;
	setFiltersValues: any;
	filtersValues: FiltersValue[];
	showOpened: number;
	setLastChangedFilter: (b: number) => void;
	lastChangedFilter: number;
};
const FilterFrontDropdown = ({
	filter,
	filtersValues,
	setFiltersValues,
	showOpened,
	setLastChangedFilter,
	lastChangedFilter,
}: Props) => {
	const { i18n } = useTranslation();
	const [isExpanded, setExpanded] = useState(showOpened === 1);
	const {handleClickSearchFilter} = useSearchFilterHandle()
	const {clearFilter} = useClearSearchFilter(filtersValues,filter,setFiltersValues)
	const [isNeedClear, setIsNeedClear] = useState(false);
	const checked = filtersValues.findIndex(
		(f: FiltersValue) => f.filter === filter.inputName
	);

	useEffect(() => {
		if (checked !== -1) {
			setIsNeedClear(true);
		} else {
			setIsNeedClear(false);
		}
	}, [checked]);
	return (
		<div
			className={s.filterContainer}
			style={{
				background: isExpanded ? "#F8F8F8" : "",
			}}
		>
			<FilterFrontHead
				name={filter.propertyName}
				parentName={filter?.parent_name}
				isExpanded={isExpanded}
				setExpanded={setExpanded}
				isNeedClear={isNeedClear}
				clearFilter={clearFilter}
			/>
			{isExpanded && (
				<div
					className={`${s.filerContent} ${style.dropdownContent}`}
					style={{ borderTop: filter ? "1px solid #909090" : "" }}
				>
					<ul
						className={`${style.dropdownMenu}`}
						style={{ gridTemplateColumns: `repeat(${filter.columns},1fr)` }}
					>
						{filter.propertyValues.map((f: any) => {
							//if(+f.countItem.countItems > 0 || filter.id ===lastChangedFilter)
							const isActive =
								filtersValues.findIndex(
									(item: any) =>
										item.value === f.id.toString() &&
										item.filter === filter.inputName
								) !== -1 ||
								filtersValues.findIndex(
									(item: any) =>
										item.value === f.id.toString() &&
										item.filter === filter.inputName
								) !== -1;
							return (
								<li
									key={f.id}
									className={` ${style.dropdownEl} ${isActive ? style.active : ""}`}
									onClick={() => {
										setLastChangedFilter(filter.id);
										handleClickSearchFilter(
											f.id,
											filtersValues,
											filter,
											setFiltersValues
										);
									}}
								>
									{(i18n.language === "ru" && f.name_ru) ||
										(i18n.language === "uk" && f.name) ||
										""}
									<span className={s.dropdownCounter}>
										{!isActive ? f.countItem.countItems : ""}
									</span>
								</li>
							);
						})}
					</ul>
				</div>
			)}
		</div>
	);
};

export default FilterFrontDropdown;
