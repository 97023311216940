import { Field } from "formik";
import { forwardRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { numberAddSpace } from "../../../supporting";
import s from "../CreateBoardAD.module.css";

interface Props {
	status: any;
	setStatus: any;
	setFieldValue: any;
	values: any;
}
const PriceField = forwardRef<HTMLDivElement, Props>(({ status, setStatus, setFieldValue, values }, ref) => {
	const [diapason, setDiapason] = useState(Boolean(values?.maxSalary));
	const { t } = useTranslation();

	useEffect(() => {
		if (Boolean(values?.maxSalary)) {
			setDiapason(true);
		}
	}, [values?.maxSalary])

	return (
		<div ref={ref}>
			<span className={s.elementName}>
				{t(`Заробітна плата`)}
				<span className={s.required}> * </span>
			</span>
			<div className={s.fieldSide}>
				<div className={s.salaryField}>
					<div className={status?.price ? s.error : ""}>
						<Field
							onFocus={() => setStatus({})}
							value={values.price}
							name={"price"}
							placeholder={diapason ? t("від грн.") : t("Сума грн.") }
							onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
								const formattedEvent = numberAddSpace(parseFloat(e.target.value));
								setFieldValue("price_type", "0");
								setFieldValue("price", formattedEvent);
							}}
						/>
					</div>
					{diapason && (
						<div className={status?.maxSalary ? s.error : ""}>
							<Field
								onFocus={() => setStatus({})}
								value={values.maxSalary}
								name={"maxSalary"}
								placeholder={t("до грн.")}
								onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
									const formattedEvent = numberAddSpace(parseFloat(e.target.value));
									if (formattedEvent === "") {
										setFieldValue("maxSalary", null);
									} else {
										setFieldValue("maxSalary", formattedEvent);
									}
								}}
							/>
						</div>
					)}
				</div>
				
				{status && status.price && (
					<span className={`${s.error} ${s.priceError}`}>{status.price}</span>
				)}
				{status && status.maxSalary && (
					<span className={`${s.error} ${s.priceError}`}>{status.maxSalary}</span>
				)}
				
				<div className={`${s.stateButtons} ${s.priceButtons}`}>
					<button
						type={"button"}
						onClick={() => {
							setDiapason(false)
							setFieldValue("maxSalary", null);
						}}
						className={!diapason ? s.active : undefined}
					>
						{t(`Одне значення`)}
					</button>
					<button
						type={"button"}
						onClick={() => { setDiapason(true) }}
						className={diapason ? s.active : undefined}
					>
						{t(`Діапазон`)}
					</button>
				</div>
			</div>
		</div>
	);
});


export default PriceField;
