import { useState } from "react";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useDispatch } from "react-redux";
import PersonalCabinetSvgSelector from "../../../../../../Components/PersonalCabinet/PersonalCabinetSvgSelector";
import {
	useSetApplySMS,
	useSetPhoneSeller,
	useSetPhoneSMS,
} from "../../../../../../CustomHooks/api/business-page/useBusinessPage";
import { setNotification } from "../../../../../../redux/notificationReducer";
import s from "./AddPhoneModal.module.css";

type Props = {
	onClose: () => void;
	currentPhonesCount: number;
	// onPhoneAdded: (phoneNumber: string) => void;
};

export const AddPhoneModal = (props: Props) => {
	const { onClose } = props;
	const { t } = useTranslation();
	const dispatch: any = useDispatch();
	const [phoneNumber, setPhoneNumber] = useState("");
	const [isSmsSent, setIsSmsSent] = useState(false);
	const [smsCode, setSmsCode] = useState("");
	const [error, setError] = useState("");

	const { mutate: setPhoneSellerMutate } = useSetPhoneSeller();
	const { mutate: setPhoneSMSMutate } = useSetPhoneSMS();
	const { mutate: setApplySMSMutate } = useSetApplySMS();

	const handlePhoneChange = (
		value: string,
		data: any,
		event: any,
		formattedValue: string
	) => {
		setPhoneNumber(formattedValue);
	};

	// Step 1 & 2: Set phone and send SMS
	const handleSendSms = () => {
		setError("");

		setPhoneSellerMutate(
			{
				phoneNumber,
				order: props.currentPhonesCount,
			},
			{
				onSuccess: ({ data }) => {
					if (data.errors && Object.keys(data.errors).length > 0) {
						for (const key in data.errors) {
							dispatch(setNotification(data.errors[key], "error"));
						}
						return;
					}

					setPhoneSMSMutate(phoneNumber, {
						onSuccess: ({ data }) => {
							if (data.result) {
								setIsSmsSent(true);
								return;
							}

							if (Object.keys(data.errors).length) {
								for (const key in data.errors) {
									dispatch(setNotification(data.errors[key], "error"));
									return;
								}
							}
						},
					});
				},
		
			}
		);
	};

	// Step 3: Apply SMS code
	const handleVerifyCode = () => {
		setError("");

		setApplySMSMutate(
			{ phoneNumber, smsCode: Number(smsCode) },
			{
				onSuccess: ({ data }) => {
					if (data.errors && Object.keys(data.errors).length > 0) {
						for (const key in data.errors) {
							dispatch(setNotification(data.errors[key], "error"));
						}
						return;
					}

					// if (data.errors && data.errors.smsCode) {
					// 	setError(data.errors.smsCode);
					// } else {
					// 	// Success
					// 	setError("");
					// }
					dispatch(setNotification("Ваш номер успішно доданий.", "success"));
					onClose();
				},
				onError: (error) => {
					setError("Сталася помилка. Спробуйте ще раз.");
					console.error("Verification error:", error);
				},
			}
		);
	};

	return (
		<div className={s.modalContainer}>
			<div className={s.topTitle}>
				<h4>{t("Додати новий номер телефону")}</h4>
				<span onClick={onClose}>
					<PersonalCabinetSvgSelector id={"closeCrossBig"} />
				</span>
			</div>
			<div className={s.modalContent}>
				{!isSmsSent ? (
					<>
						<div className={s.inputWrapper}>
							<label htmlFor="phoneNumber">{t("Номер телефону")}</label>
							<PhoneInput
								country={"ua"}
								value={phoneNumber}
								onChange={handlePhoneChange}
								inputProps={{
									name: "phoneNumber",
									required: true,
									autoFocus: true,
								}}
								countryCodeEditable={false}
								placeholder="+380 (__) ___ __ __"
								containerClass={s.phoneInputContainer}
								inputClass={s.phoneInput}
								enableAreaCodes={true}
								disableDropdown={true}
							/>
						</div>
						<button
							type="button"
							className={s.button}
							onClick={handleSendSms}
							disabled={phoneNumber.length < 12} // Adjust length as per format
						>
							{t("Надіслати SMS")}
						</button>
						{error && <p className={s.errorMessage}>{error}</p>}
					</>
				) : (
					<>
						<div className={s.smsVerificationContainer}>
							<p className={s.smsInstruction}>
								{t("Введіть код з SMS, надісланий на номер")} {phoneNumber}
							</p>
							<div className={s.inputGroup}>
								<div className={s.inputWrapper}>
									<label htmlFor="smsCode">Код з SMS</label>
									<input
										type="text"
										id="smsCode"
										className={s.smsCodeInput}
										placeholder="Код з SMS"
										value={smsCode}
										onChange={(e) => {
											setSmsCode(e.target.value);
											if (error) {
												setError("");
											}
										}}
									/>
								</div>
								<button
									type="button"
									className={s.button}
									onClick={handleVerifyCode}
									disabled={!smsCode}
								>
									{t("Підтвердити")}
								</button>
							</div>
							{error && <p className={s.errorMessage}>{error}</p>}
						</div>
					</>
				)}
				{/* {error && <p className={s.errorMessage}>{error}</p>} */}
			</div>
		</div>
	);
};
