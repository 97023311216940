import React, { useEffect } from 'react';
import s from "../CreateBoardAD.module.css";
import { useTranslation } from 'react-i18next';
import useFilterHandle from '../../../CustomHooks/useFilterHandle';
import { type NoticeFilterType, type NoticeProperyValueType } from '../../../types/noticeTypes';
import { useSelector } from 'react-redux';
import { StateType } from '../../../redux/redux-store';
import { capitalizeFirstLetter } from '../../../supporting';

type Props = {
	filter: NoticeFilterType;
	values?: any;
  setFieldValue: (
		field: string,
		value: any,
		shouldValidate?: boolean | undefined
	) => void;
};

const ChangeWorkType: React.FC<Props> = ({ filter, values, setFieldValue }: Props) => {
	const { t, i18n } = useTranslation()
  const { handleFilter } = useFilterHandle();
  
	const filtersOneIs: Array<{ filter: string; id: number | string }> = useSelector((state: StateType) => state.noticeData.filtersOneIs);

  const noticeFilter = filtersOneIs.find((f: { filter: string; id: number | string }) => f?.filter === `filter_${process.env.REACT_APP_WORK_FILTER_TYPE_ID}`) // dev=2759 stage=8072

  const proposeWorkId = process.env.REACT_APP_WORK_PROPOSE_FILTER_ID // dev = 472, stage = 26782
  const initialFilter =  filter?.propertyValues?.find(filter => 
    noticeFilter ? filter.id === noticeFilter?.id : filter.id.toString() === proposeWorkId)


  useEffect(() => {
    if (initialFilter) {
      const { id, name, name_ru } = initialFilter
      handleFilter({ element: { id: id.toString(), name, name_ru }, filter})
    }
  }, [filter]);
  
	useEffect(() => {
		if (values && setFieldValue) {
			setFieldValue("filters", [...filtersOneIs]);
			setFieldValue("notice_properties", [...filtersOneIs]);
		}
	}, [filtersOneIs, i18n.language]);

  const nameField = `name${i18n.language !== 'uk' ? `_${i18n.language}` : ''}`

  return (
    <div className={s.tabWrapper}>
      {filter?.propertyValues.map((property: NoticeProperyValueType) => {
        const { id, name, name_ru } = property
        const element = { id: id.toString(), name, name_ru };
        const buttonName = capitalizeFirstLetter(property[nameField as keyof NoticeProperyValueType])
        const isActive = filtersOneIs.findIndex(
          (item: { filter: string, id: number | string }) =>
            item.id === property.id.toString() &&
            item.filter === filter.inputName
        ) !== -1;

        return (
          <button
            key={property.id}
            className={`${s.tabItem} ${isActive ? s.active : ''}`}
            onClick={() => {
              if (!isActive) { // Проверяем, не активна ли кнопка
                handleFilter({ element, filter });
              }
            }}
            type="button"
          >
            {buttonName} {t('роботу')}
          </button>
        )
      })}
    </div>
  );
};

export default ChangeWorkType;