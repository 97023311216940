import { useDispatch, useSelector } from "react-redux";
import { Filter, FiltersValue } from "../types/publicNoticeTypes";
import { StateType } from "../redux/redux-store";
import { setDependFiltersList } from "../redux/category_Reducer";





const useClearSearchFilter = (filtersValues:FiltersValue[],filter:Filter,setFiltersValues: any) => {
	const dispatch:any = useDispatch();
	const dependFilters = useSelector((state:StateType) => state.categoryData.dependFilters)
 	const clearFilter = () => {
	 const updateFiltersValues = [...filtersValues].filter((f: FiltersValue) => {
		 return f.filter !== filter.inputName && f.parent_id !== filter.id;
	 });

	 if(!filter.parent_id){
		 const filteredDepend = dependFilters?.filter((f: Filter) => f.parent_id !== filter.id);
		 dispatch(setDependFiltersList(filteredDepend))
	 }
	 setFiltersValues(updateFiltersValues);

	};

	return { clearFilter }
};

export default useClearSearchFilter;
