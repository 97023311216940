import { Backdrop } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import { Field, FieldArray } from "formik"; // Import FieldArray for handling arrays
import { useState } from "react";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import ArrowsAndCross from "../../../../../../common/ArrowsAndCross/ArrowsAndCross";
import MessengersIcons from "../../../../../../Components/PersonalCabinet/MessengersIcons/MessengersIcons";
import {
	MyModal,
	useStyles,
} from "../../../../../../Components/PersonalCabinet/My Ads/MyAds";
import { useSetContactsSeller } from "../../../../../../CustomHooks/api/business-page/useBusinessPage";
import { setNotification } from "../../../../../../redux/notificationReducer";
import { StateType } from "../../../../../../redux/redux-store";
import { getSettlementList } from "../../../../../../redux/settlement_Reducer";
import { AddPhoneModal } from "../AddPhoneModal/AddPhoneModal";
import styles from "../SellerInfo.module.css";
import { FormSeller } from "../types";

type Props = {
	isEditContactsSeller: boolean;
	values: FormSeller;
	setFieldValue: (field: string, value: any) => void;
};

export const SellerContactsSection = ({
	isEditContactsSeller,
	values,
	setFieldValue,
}: Props) => {
	const { mutate: setContactsSeller } = useSetContactsSeller("");
	const { t } = useTranslation();
	const [isAddPhone, setIsAddPhone] = useState(false);

	const classes = useStyles();
	const dispatch: any = useDispatch();
	const getSettlement = useSelector(() => getSettlementList);
	const resultSettlement = useSelector(
		(state: StateType) => state.settlementData.settlements
	);
	const userLocation = useSelector(
		(state: StateType) => state.settlementData.settlementByLocation
	);
	const handlePhoneChange = (index: number, value: string) => {
		const updatedPhones = [...values.contactPhone];
		updatedPhones[index] = value;
		setFieldValue("contactPhone", updatedPhones);
	};

	const customNoOptionsMessage = () => t("Не знайдено");

	const onSave = () => {
		setContactsSeller(
			{
				viber: values.viber,
				telegram: values.telegram,
				whatsapp: values.whatsapp,
				city_id: Number(values.city) === 0 ? "" : Number(values.city),
				email: values.email ? values.email : undefined,
				site: values.site ? values.site : undefined,
				street: values.street ? values.street : undefined,
			},
			{
				onSuccess: ({ data }) => {
					if (data?.errors && Object.keys(data.errors).length) {
						for (const key in data.errors) {
							dispatch(setNotification(data.errors[key], "error"));
						}
						return;
					}
					dispatch(setNotification(t("Ваші зміни збережені."), "success"));
				},
			}
		);
	};

	return (
		<div>
			<div
				id="contacts"
				className={styles.formElement}
				style={{ alignItems: "flex-start" }}
			>
				<div className={styles.nameSide}>{t("Телефони")}:</div>
				<div className={`${styles.fieldSide} ${styles.numberPhoneMessenger}`}>
					{isEditContactsSeller ? (
						<div>
							<FieldArray name="contactPhone">
								{({ push, remove }) => (
									<>
										{values.contactPhone.map((phone, index) => (
											<div key={phone} className={styles.phoneInputWrapper}>
												<PhoneInput
													inputClass={styles.phoneInputStyle}
													country={"ua"}
													value={phone}
													disabled
												/>
											</div>
										))}
										<MessengersIcons
											placement="contacts"
											values={{
												viber: values.viber,
												telegram: values.telegram,
												whatsapp: values.whatsapp,
											}}
											setFieldValue={setFieldValue}
										/>
										{values.contactPhone.length < 3 ? (
											<button
												className={styles.addPhone}
												type="button"
												onClick={() => setIsAddPhone(true)}
											>
												+ {t("Додати телефон")}
											</button>
										) : null}
									</>
								)}
							</FieldArray>
						</div>
					) : (
						<div className={styles.phones}>
							{values.contactPhone.map((phone, index) => (
								<a key={phone} href={`tel:${phone}`}>
									{phone}
								</a>
							))}
						</div>
					)}
				</div>
			</div>
			{values.email || isEditContactsSeller ? (
				<div className={styles.formElement}>
					<div className={styles.nameSide}>Email:</div>
					<div className={`${styles.fieldSide} ${styles.numberPhoneMessenger}`}>
						<div className={styles.emailInputWrapper}>
							{isEditContactsSeller ? (
								<Field
									type={"email"}
									name={"email"}
									placeholder={t("Введіть ваш контактний email")}
								/>
							) : (
								<p>{values.email}</p>
							)}
						</div>
					</div>
				</div>
			) : null}

			{values.site || isEditContactsSeller ? (
				<div className={styles.formElement}>
					<div className={styles.nameSide}>{t("Сайт компанії:")}</div>
					<div className={`${styles.fieldSide} ${styles.numberPhoneMessenger}`}>
						<div className={styles.emailInputWrapper}>
							{isEditContactsSeller ? (
								<Field
									type={"text"}
									name={"site"}
									placeholder="https://example.com.ua"
								/>
							) : (
								<a href={values.site} target="_blank" rel="noopener noreferrer">
									{values.site}
								</a>
							)}
						</div>
					</div>
				</div>
			) : null}

			{values.cityLabel || isEditContactsSeller ? (
				<div className={styles.formElement}>
					<div className={styles.nameSide}>{t("Місто")}:</div>
					<div className={`${styles.fieldSide} ${styles.numberPhoneMessenger}`}>
						<div className={styles.fieldSide}>
							{isEditContactsSeller ? (
								<Select
									isClearable={false}
									isSearchable
									noOptionsMessage={customNoOptionsMessage}
									options={[...userLocation, ...resultSettlement]}
									value={[
										{
											value: values.city || "0",
											label:
												values.cityLabel ||
												t("Введіть назву міста українською"),
										},
									]}
									components={{
										IndicatorSeparator: () => null,
										DropdownIndicator: ({ selectProps }) => (
											<span
												// className={s.dropdownArrow}
												style={{
													transform: selectProps.menuIsOpen
														? "rotate(180deg)"
														: "",
													marginTop: selectProps.menuIsOpen ? "5px" : "",
													transition: "400ms",
													stroke: "#797979",
												}}
											>
												<ArrowsAndCross id={"arrowForSelect"} />
											</span>
										),
									}}
									styles={{
										control: (baseStyles, state) => ({
											...baseStyles,
											padding: state.menuIsOpen
												? "1px 15px 0 18px "
												: "0 15px 0 18px ",
											borderColor: state.menuIsOpen ? "#E7E7E7" : "#E7E7E7",
											borderBottom: state.menuIsOpen
												? "2px solid #00A9CE"
												: "1px solid #E7E7E7",
											//borderBottomColor:state.isFocused ?"" : "00A9CE",
											boxShadow: "none",
											width: "514px",
											maxWidth: "100%",
											// height: "40px",
											fontSize: "16px",
											borderRadius: "4px",
											"&:hover": {
												borderBottom: state.menuIsOpen
													? "2px solid #00A9CE"
													: "1px solid #E7E7E7",
											},
										}),
										singleValue: (styles, state) => {
											return {
												...styles,
												borderRadius: "4px",
												fontFamily: "var(--myMainFontRegular)",
												fontSize: "16px",
												color: "#727272",
											};
										},
										input: (styles, state) => {
											return {
												...styles,
												borderColor: "red",
												margin: "0",
												padding: "0",
											};
										},
										option: (base) => ({
											...base,
											boxShadow: "0px 4px 30px rgba(0, 0, 0, 0.06)",
											borderRadius: "5px",
										}),
									}}
									onInputChange={(newValue) => {
										// setStatus({});
										if (newValue.length >= 3) {
											dispatch(getSettlement(newValue));
										}
									}}
									onChange={(newValue) => {
										// setStatus({});
										setFieldValue("city", newValue?.value);
										setFieldValue("cityLabel", newValue?.label);
									}}
								/>
							) : (
								<p>{values.cityLabel}</p>
							)}
						</div>
					</div>
				</div>
			) : null}

			{values.street || isEditContactsSeller ? (
				<div className={styles.formElement}>
					<div className={styles.nameSide}>{t("Адреса")}:</div>
					<div className={`${styles.fieldSide} ${styles.numberPhoneMessenger}`}>
						<div className={styles.emailInputWrapper}>
							{isEditContactsSeller ? (
								<Field
									type={"text"}
									name={"street"}
									placeholder={t("Адреса")}
								/>
							) : (
								<p>{values.street}</p>
							)}
						</div>
					</div>
				</div>
			) : null}

			{isEditContactsSeller ? (
				<div className={styles.footer}>
					<button className={styles.button} onClick={onSave}>
						{t("Зберегти")}
					</button>
				</div>
			) : null}

			<MyModal>
				<div>
					<Modal
						aria-labelledby="transition-modal-title"
						aria-describedby="transition-modal-description"
						className={classes.modalRenewal}
						open={isAddPhone}
						onClose={() => setIsAddPhone(false)}
						closeAfterTransition
						BackdropComponent={Backdrop}
						BackdropProps={{
							timeout: 500,
						}}
					>
						<AddPhoneModal
							onClose={() => setIsAddPhone(false)}
							currentPhonesCount={values.contactPhone.length}
						/>
					</Modal>
				</div>
			</MyModal>
		</div>
	);
};
