import {
	keepPreviousData,
	useInfiniteQuery,
	useMutation,
	useQuery,
	useQueryClient,
} from "@tanstack/react-query";
import { publicNotice } from "../../../api/api";
import {
	getBusinessPageData,
	getBusinessUserPageData,
	setAboutSeller,
	setApplySMS,
	setContactsSeller,
	setDeliveryService,
	setManageActivityBlock,
	setPayAndGuaranteeGuaranteeConditions,
	setPayAndGuaranteeReturnConditions,
	setPayAndGuaranteeSetPayment,
	setPhoneSeller,
	setPhoneSMS,
	setPublishPage,
	setPublishPageSlug,
	setSlogan,
	uploadBusinessImage,
} from "../../../api/business-page";
import type {
	ContactsSellerPayload,
	PaymentParams,
	SetManageActivityBlock,
	UseGetNoticesParams,
} from "../../../api/business-page/types";
import { MutationKeys, QueryKeys } from "../../../api/QueryKeys";

export const useGetBusinessUserPageData = (slug?: string) => {
	return useQuery({
		queryKey: QueryKeys.businessPage(slug),
		queryFn: async () =>
			slug !== ""
				? await getBusinessUserPageData(slug ?? "")
				: await getBusinessPageData(),
		select: (data) => data.data,
		staleTime: 0,
	});
};

export const useSetAboutSeller = (slug?: string) => {
	const queryClient = useQueryClient();

	const { mutate, isPending, isError, isSuccess } = useMutation({
		mutationKey: MutationKeys.setAboutSeller,
		mutationFn: async (about_seller: string) =>
			await setAboutSeller(about_seller),
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: QueryKeys.businessPage(slug),
			});
		},
	});

	return {
		mutate,
		isPending,
		isError,
		isSuccess,
	};
};

export const useSetContactsSeller = (slug?: string) => {
	const queryClient = useQueryClient();

	const { mutate, isPending, isError, isSuccess } = useMutation({
		mutationKey: MutationKeys.setContactsSeller,
		mutationFn: async (contactsPayload: ContactsSellerPayload) =>
			await setContactsSeller(contactsPayload),
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: QueryKeys.businessPage(slug),
			});
		},
	});

	return {
		mutate,
		isPending,
		isError,
		isSuccess,
	};
};

export const useSetPayAndGuaranteeSetPayment = (slug?: string) => {
	const queryClient = useQueryClient();

	const { mutate, isPending, isError, isSuccess } = useMutation({
		mutationKey: MutationKeys.setContactsSeller,
		mutationFn: async (payment: PaymentParams) =>
			await setPayAndGuaranteeSetPayment(payment),
		// onSuccess: () => {
		// 	queryClient.invalidateQueries({
		// 		queryKey: QueryKeys.businessPage(slug),
		// 	});
		// },
	});

	return {
		mutate,
		isPending,
		isError,
		isSuccess,
	};
};

export const useSetPayAndGuaranteeGuaranteeConditions = (slug?: string) => {
	const queryClient = useQueryClient();

	const { mutate, isPending, isError, isSuccess } = useMutation({
		mutationKey: MutationKeys.setContactsSeller,
		mutationFn: async (payment: {
			var1: number;
			var2: number;
			var3: number;
			var4?: string | number | undefined;
			var5?: string | number | undefined;
		}) => await setPayAndGuaranteeGuaranteeConditions(payment),
		// onSuccess: () => {
		// 	queryClient.invalidateQueries({
		// 		queryKey: QueryKeys.businessPage(slug),
		// 	});
		// },
	});

	return {
		mutate,
		isPending,
		isError,
		isSuccess,
	};
};

export const useSetPayAndGuaranteeReturnConditions = (slug?: string) => {
	const queryClient = useQueryClient();

	const { mutate, isPending, isError, isSuccess } = useMutation({
		mutationKey: MutationKeys.setContactsSeller,
		mutationFn: async (payment: {
			var1: number;
			var2: number;
			var3: number;
			var4?: string | number;
			var5?: string | number;
		}) => await setPayAndGuaranteeReturnConditions(payment),
		// onSuccess: () => {
		// 	queryClient.invalidateQueries({
		// 		queryKey: QueryKeys.businessPage(slug),
		// 	});
		// },
	});

	return {
		mutate,
		isPending,
		isError,
		isSuccess,
	};
};

export const useUploadBusinessImage = (slug?: string) => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationKey: MutationKeys.uploadBusinessImage,
		mutationFn: async (data: { image: File; scenario: string }) =>
			await uploadBusinessImage(data),
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: QueryKeys.businessPage(slug),
			});
		},
	});
	// return useMutation(uploadBusinessImage, {
	// 	 onSuccess: () => {
	// 		  console.log('Image uploaded successfully');
	// 	 },
	// 	 onError: (error) => {
	// 		  console.error('Error uploading image:', error);
	// 	 },
	// });
};

export const useSetManageActivityBlock = (slug?: string) => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationKey: MutationKeys.setManageActivityBlock,
		mutationFn: async (params: SetManageActivityBlock) =>
			await setManageActivityBlock(params),
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: QueryKeys.businessPage(slug),
			});
		},
	});
};

export const useSetPublishPage = (slug?: string) => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationKey: MutationKeys.setPublishPage,
		mutationFn: async (status?: number) => await setPublishPage(status),
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: QueryKeys.businessPage(slug),
			});
		},
	});
};

export const useSetPublishPageSlug = (slug?: string) => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationKey: MutationKeys.setPublishPageSlug,
		mutationFn: async (slug: string) => await setPublishPageSlug(slug),
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: QueryKeys.businessPage(slug),
			});
		},
	});
};

export const useSetSlogan = (slug?: string) => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationKey: MutationKeys.setSloganPage,
		mutationFn: async (slogan: string) => await setSlogan(slogan),
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: QueryKeys.businessPage(slug),
			});
		},
	});
};

export const useSetPhoneSeller = (slug?: string) => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationKey: MutationKeys.setPhoneSeller,
		mutationFn: async (params: { phoneNumber: string; order: number }) =>
			await setPhoneSeller(params.phoneNumber, params.order),
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: QueryKeys.businessPage(slug),
			});
		},
	});
};

export const useSetPhoneSMS = () => {
	return useMutation({
		mutationKey: MutationKeys.setPhoneSMS,
		mutationFn: async (phoneNumber: string) => await setPhoneSMS(phoneNumber),
	});
};

export const useSetApplySMS = (slug?: string) => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationKey: MutationKeys.setApplySMS,
		mutationFn: async (params: { phoneNumber: string; smsCode: number }) =>
			await setApplySMS(params.phoneNumber, params.smsCode),
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: QueryKeys.businessPage(slug),
			});
		},
	});
};

export const useSetDeliveryService = (slug?: string) => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationKey: MutationKeys.setApplySMS,
		mutationFn: async (params: { deliveryId: string; active: number }) =>
			await setDeliveryService(params.deliveryId, params.active),
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: QueryKeys.businessPage(slug),
			});
		},
	});
};

export const useGetNoticesByUserId = ({
	userId,
	perPage = 48,
	page,
	sortType,
	category_id,
	catIds
}: UseGetNoticesParams) => {
	return useQuery({
		queryKey: ["get/noticesByUserId", userId, perPage, page, sortType, category_id, catIds],
		queryFn: async () =>
			await publicNotice.getAllNoticesByUserId(userId, perPage, page, sortType, category_id, catIds),
		select: ({ data }) => data.data,
		enabled: userId !== 0,
		placeholderData: keepPreviousData,
		staleTime: 0,
	});
};
export const useInfiniteGetNoticesByUserId = ({
	userId,
	perPage = 5,
	sortType
}: UseGetNoticesParams) => {
	return useInfiniteQuery({
		queryKey: ["getInfinite/noticesByUserId", userId, sortType],
		queryFn: async ({ pageParam = 1 }) =>
			await publicNotice.getAllNoticesByUserId(userId, perPage, pageParam, sortType),
		initialPageParam: 1,
		getNextPageParam: (lastPage: any) => {
			const currentPage = lastPage?.data.data._meta?.currentPage || 1;
			const pageCount = lastPage?.data.data._meta?.pageCount || 1;
			if (currentPage < pageCount) {
				return currentPage + 1;
			}
			return undefined;
		},
		enabled: userId !== 0,
		select: ({ pages }) => pages.flatMap((page) => page.data.data.items || []),
		staleTime: 0,
	});
};
