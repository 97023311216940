import React, {
	ElementType,
	Fragment,
	useEffect,
	useRef,
	useState,
} from "react";
import s from "../AutomaticSelectionOfCategories/AutomaticSelectionOfCategories.module.css";
import TopTitleBlockSvg from "../TopTitleBlockMobileAllPages/TopTitleBlockSvg";
import AutomaticSelectionOfCategoriesLevelThreeAndFour from "../AutomaticSelectionOfCategories/AutomaticSelectionOfCategoriesLevelThreeAndFour";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { StateType } from "../../redux/redux-store";
import {
	getCategoryBlocks,
	getCategoryView,
	getFiltersList,
	setClearThirdCategoryList,
	setThirdCategoryList,
	setThorCategoryList,
} from "../../redux/category_Reducer";
import { setBooleanAutoChoosing } from "../../redux/autoSelection_Reducer";
import { OneRubricType } from "../../types/categoriesTypes";

interface Props {
	setFieldValue: any;
	setIsOpen?: (value: boolean) => void;
}

const NewRubricsBoardSelectorDesktop: React.FC<Props> = ({
	setIsOpen,
	setFieldValue,
}) => {
	const { t } = useTranslation();
	const dispatch: any = useDispatch();
	const getFilters = useSelector(() => getFiltersList);
	const getBlocks = useSelector(() => getCategoryBlocks);
	const getCategory = useSelector(() => getCategoryView);
	const setBooleanAutoChoosingStore = useSelector(() => setBooleanAutoChoosing);
	const arrayRubrics = useSelector(
		(state: StateType) => state.categoryData.allRubrics
	);
	const [firstStepElements, setFirstStepElements] = useState([]);
	const [groupedElements, setGroupedElements] = useState<ElementType[][]>([]);
	const set3LevelCategories = useSelector(() => setThirdCategoryList);
	const set4LevelCategories = useSelector(() => setThorCategoryList);
	const [currentCategoryInfo, setCurrentCategoryInfo] = useState<any>({
		name: "",
		alias: "",
		id: null,
	});
	const [currentIdx, setCurrentIdx] = useState(0);
	const thirdStepElements = useSelector(
		(state: StateType) => state.categoryData.thirdLevelCategories
	);
	const [slugState, setSlugState] = useState("");
	//const [thirdCategoryAlias, setThirdCategoryAlias] = useState("")
	const selectionCategoryRef = useRef<HTMLDivElement | null>(null);
	const clearThirdCategoryAlias = useSelector(() => setClearThirdCategoryList);

	const scrollToElement = () => {
		if (selectionCategoryRef.current) {
			selectionCategoryRef.current.scrollIntoView({ behavior: "smooth" });
		}
	};
	useEffect(() => {
		scrollToElement();
	}, [slugState]);

	const handleClickCategory = (id: number | null, name: string, alias: string) => {
		
		setCurrentCategoryInfo({ name, alias, id });
		dispatch(set3LevelCategories(id));
		dispatch(set4LevelCategories(null));
	};
	const handleClick = (rubric: any) => {
		if (rubric?.isForNotice === "1" || rubric?.isForNotice === true) {
			dispatch(setBooleanAutoChoosingStore(false));
			dispatch(getFilters(rubric?.id));
			dispatch(getBlocks(rubric?.id));
			setFieldValue("category_id_value", rubric?.id);
			setFieldValue("category_id_label", rubric?.name);
			if (setIsOpen) setIsOpen(false);
		} else {
			dispatch(setBooleanAutoChoosingStore(false));
			handleClickCategory(rubric.id.toString(), rubric.name, rubric.alias);
		}
	};
	const handleFirstStep = (rubric: OneRubricType) => {
		const data = arrayRubrics.level2.filter(
			(r2: OneRubricType) => r2.parent_id === rubric?.id
		);
		setFirstStepElements(data);
	};
	/* useEffect(() => {
         if(thirdStepElements){
             scrollToElement()
         }
     }, [thirdStepElements]);*/
	useEffect(() => {
		if (firstStepElements.length) {
			const result: ElementType[][] = firstStepElements.reduce(
				(acc: ElementType[][], item: ElementType, index: number) => {
					const chunkIndex = Math.floor(index / 8);

					if (!acc[chunkIndex]) {
						acc[chunkIndex] = [];
					}

					acc[chunkIndex].push(item);

					return acc;
				},
				[]
			);
			setGroupedElements(result);
			return () => {
				console.log("");
			};
		}
	}, [firstStepElements]);

	useEffect(() => {
		dispatch(getCategory("", slugState || ""));
	}, [slugState]);

	const handleClickArrowBack = () => {
		dispatch(clearThirdCategoryAlias());
		setSlugState("");
	};
	return (
		<div className={s.modalContainerContentBottom}>
			<div className={s.modalContainerContentBottom_title}>
				{slugState && (
					<div
						onClick={handleClickArrowBack}
						className={s.modalContainerContentBottom_arrowBack}
					>
						<TopTitleBlockSvg id="arrowBack" />
					</div>
				)}
				<p>{t(`Оберіть рубрику`)}</p>
			</div>
			<div>
				<div className={s.boardWrapper}>
					{slugState ? (
						<div className={s.boardContainer} ref={selectionCategoryRef}>
							<div className={s.boardContainerRow}>
								{groupedElements.map((arr: any, index: number) => (
									<Fragment key={index}>
										{arr.map((item: any) => (
											<div key={item.id} className={s.catalogItem}>
												<div
													onClick={() => {
														setSlugState(item?.alias);
														handleClick(item);
														//setSteep(0)
														setCurrentIdx(index);
													}}
												>
													<div className={s.imageContainer}>
														<div className={s.imageContainerFon} />
														<img
															src={item?.iconUrl || ""}
															alt={item?.alias}
															className={s.squarePhoto}
														/>
													</div>
													<p>{item?.name || ""}</p>
												</div>
											</div>
										))}
										{currentIdx == index && (
											<div style={{ width: "100%" }}>
												{!!thirdStepElements.length && (
													<AutomaticSelectionOfCategoriesLevelThreeAndFour
														setIsOpen={setIsOpen}
														setFieldValue={setFieldValue}
														currentCategoryInfo={currentCategoryInfo}
														thirdStepElements={thirdStepElements}
														/*setThirdCategoryAlias={setThirdCategoryAlias}*/
													/>
												)}
											</div>
										)}
									</Fragment>
								))}
							</div>
						</div>
					) : (
						<div className={s.boardContainer}>
							<div className={s.boardContainerRow}>
								{arrayRubrics?.level1?.map((item: OneRubricType) => {
									return (
										<div
											key={item.id}
											className={s.catalogItem}
											onClick={() => {
												setSlugState(item?.alias);
												//setSteep(1)
												// handleFirstStep()
												handleFirstStep(item);
											}}
										>
											<div className={s.imageContainer}>
												<div className={s.imageContainerFon} />
												<img
													src={item?.iconUrl || ""}
													alt={item?.alias}
													className={s.roundPhoto}
												/>
											</div>
											<p>{item?.name || ""}</p>
										</div>
									);
								})}
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default NewRubricsBoardSelectorDesktop;
