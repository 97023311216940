import { Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
	AboutSeller,
	Contacts,
	DeliveryService,
	GuaranteeConditions,
	ManageActivityBloc,
	Payment,
	PhoneObj,
	ReturnConditions,
	SetManageActivityBlock,
} from "../../../../../api/business-page/types";
import {
	useGetNoticesByUserId,
	useSetAboutSeller,
	useSetManageActivityBlock,
	useSetPayAndGuaranteeSetPayment,
} from "../../../../../CustomHooks/api/business-page/useBusinessPage";
import { getCategoryBlocks } from "../../../../../redux/category_Reducer";
import { StateType } from "../../../../../redux/redux-store";
import Collapse from "../Collapse/Collapse";
import DeliverySeller from "../DeliverySeller/DeliverySeller";
import { AboutSellerSection } from "./AboutSellerSection/AboutSellerSection";
import { PaymentAndGuaranteeSection } from "./PaymentAndGuaranteeSection/PaymentAndGuaranteeSection";
import { SellerContactsSection } from "./SellerContactsSection/SellerContactsSection";
import styles from "./SellerInfo.module.css";
import { FormSeller } from "./types";

type Props = {
	aboutSeller: AboutSeller;
	contacts: Contacts;
	payment: Payment;
	guaranteeConditions: GuaranteeConditions;
	returnConditions: ReturnConditions;
	phones: Record<string, PhoneObj | null>;
	manageActivityBlock: ManageActivityBloc;
	deliveryServices: Record<string, DeliveryService | null>;
	slugPage: string | null;
	mainPhone: string | null;
	isUserPage: boolean;
};

export const SellerInfo = (props: Props) => {
	const { t } = useTranslation();
	const {
		aboutSeller,
		contacts,
		payment,
		guaranteeConditions,
		returnConditions,
		phones,
		manageActivityBlock,
		deliveryServices,
		slugPage,
		mainPhone,
		isUserPage,
	} = props;
	const dispatch: any = useDispatch();
	const getBlocks = useSelector(() => getCategoryBlocks);
	const blockDelivery = useSelector(
		(state: StateType) => state.categoryData.blockDelivery
	);
	const userView = useSelector((state: StateType) => state.userData.user);
	const noticeDescriptionRef = useRef<HTMLDivElement>(null);
	const [status, setStatuss] = useState<string>("inactive");
	const { mutate: setAboutSeller } = useSetAboutSeller();
	const { mutate: setPayAndGuaranteeSetPayment } =
		useSetPayAndGuaranteeSetPayment();

	const { mutate: setManageActivityBlock } = useSetManageActivityBlock(
		slugPage ?? ""
	);

	const { data: userAds } = useGetNoticesByUserId({
		userId: userView.id ?? 0,
		perPage: 48,
		page: 1,
	});

	useEffect(() => {
		if (userAds?.items?.length > 0) {
			for (const item of userAds.items) {
				if (item.category_id) {
					dispatch(getBlocks(item.category_id));
				}
			}
		}
	}, [userAds?.items, dispatch]);

	// const hasAdsWithDelivery = useMemo(() => {
	// 	if (!userAds?.items) return false;
	// 	return userAds.items.some((ad: any) => ad.delivery_ids !== null);
	// }, [userAds]);

	const [isEditAboutSeller, setIsEditAboutSeller] = useState(false);
	const [isEditContactsSeller, setIsEditContactsSeller] = useState(false);
	const [isEditDeliverySeller, setIsEditDeliverySeller] = useState(false);
	const [isEditPaymentSeller, setIsEditPaymentSeller] = useState(false);
	const [localManageActivityBlock, setLocalManageActivityBlock] =
		useState(manageActivityBlock);

	const initialValuesCreate: FormSeller = {
		company: contacts.site.value,
		contactPhone: [
			mainPhone ?? userView.phone,
			...Object.values(phones)
				.filter(
					(phoneObj) =>
						(isUserPage && phoneObj?.isApproved === 0) ||
						phoneObj?.isApproved === 1
				)
				.map((phoneObj) => phoneObj?.phoneNumber),
		].filter((phone) => phone),
		deliveryService: deliveryServices,
		newContactPhone: [],
		viber: Number(contacts.viber.value),
		telegram: Number(contacts.telegram.value),
		whatsapp: Number(contacts.whatsapp.value),
		description: aboutSeller.about_seller.value,
		city: contacts.city_id.value,
		cityLabel: contacts.city.value,
		email: contacts.email.value,
		site: contacts.site.value,
		street: contacts.street.value,

		paymentVar1: !!Number(payment.var1.value),
		paymentVar2: !!Number(payment.var2.value),
		paymentVar3: !!Number(payment.var3.value),
		paymentVar4: !!Number(payment.var4.value),
		paymentVar5: !!Number(payment.var5.value),

		guaranteeConditionsVar1: !!Number(guaranteeConditions.var1.value),
		guaranteeConditionsVar2: !!Number(guaranteeConditions.var2.value),
		guaranteeConditionsVar3: !!Number(guaranteeConditions.var3.value),
		guaranteeConditionsVar4: !!Number(guaranteeConditions.var4.value),
		guaranteeConditionsVar5: guaranteeConditions.var5.value || "",

		returnConditionsVar1: !!Number(returnConditions.var1.value),
		returnConditionsVar2: !!Number(returnConditions.var2.value),
		returnConditionsVar3: !!Number(returnConditions.var3.value),
		returnConditionsVar4: returnConditions.var4.value || "",
	};

	const handleCheckBox = (
		name: string,
		checked: boolean,
		setFieldValue: any
	) => {
		setFieldValue(name, checked);
	};

	const onChangeVisible = (
		block: keyof SetManageActivityBlock,
		visible: 0 | 1
	) => {
		const updatedValues = {
			...localManageActivityBlock,
			[block]: {
				...localManageActivityBlock[block],
				value: visible.toString(),
			},
		};

		setLocalManageActivityBlock(updatedValues);
		setManageActivityBlock({
			aboutSeller: Number(updatedValues.aboutSeller.value),
			contacts: Number(updatedValues.contacts.value),
			payGuarantee: Number(updatedValues.payGuarantee.value),
			delivery: Number(updatedValues.delivery.value),
		});
	};

	const isAboutSellerEmpty = !aboutSeller.about_seller.value;
	const isContactsEmpty =
		!contacts.email.value &&
		!contacts.site.value &&
		!contacts.street.value &&
		!contacts.city.value &&
		!contacts.viber.value &&
		!contacts.telegram.value &&
		!contacts.whatsapp.value &&
		!mainPhone &&
		Object.values(phones).every((phone) => !phone || phone.isApproved !== 1);

	const isDeliveryEmpty = Object.values(deliveryServices).every(
		(service) => !service
	);

	return (
		<Formik
			enableReinitialize
			initialValues={initialValuesCreate}
			onSubmit={(values, submitProps) => void 0}
		>
			{({
				values,
				status: formikStatus,
				handleSubmit,
				setFieldValue,
				setStatus,
			}) => (
				<div className={styles.containerContacts}>
					{isUserPage ||
					(!isAboutSellerEmpty &&
						manageActivityBlock.aboutSeller.value === "1") ? (
						<Collapse
							title={t("Про продавця")}
							isEdit={isEditAboutSeller}
							onChangeEdit={setIsEditAboutSeller}
							isUserPage={isUserPage}
							block="aboutSeller"
							isVisible={Number(manageActivityBlock.aboutSeller.value) === 1}
							onChangeVisible={onChangeVisible}
						>
							<AboutSellerSection
								isEditAboutSeller={isEditAboutSeller}
								values={values}
								setAboutSeller={setAboutSeller}
								setFieldValue={setFieldValue}
								status={status}
								setStatus={setStatuss}
								noticeDescriptionRef={noticeDescriptionRef}
							/>
						</Collapse>
					) : null}

					{(isUserPage || manageActivityBlock.contacts.value === "1") &&
					!isContactsEmpty ? (
						<Collapse
							title={t("Контакти")}
							isEdit={isEditContactsSeller}
							onChangeEdit={setIsEditContactsSeller}
							isUserPage={isUserPage}
							isVisible={Number(manageActivityBlock.contacts.value) === 1}
							block="contacts"
							onChangeVisible={onChangeVisible}
						>
							<SellerContactsSection
								isEditContactsSeller={isEditContactsSeller}
								values={values}
								setFieldValue={setFieldValue}
							/>
						</Collapse>
					) : null}

					{isUserPage || manageActivityBlock.payGuarantee.value === "1" ? (
						<Collapse
							title={t("Оплата та гарантія")}
							isEdit={isEditPaymentSeller}
							onChangeEdit={setIsEditPaymentSeller}
							isUserPage={isUserPage}
							onChangeVisible={onChangeVisible}
							block="payGuarantee"
							isVisible={Number(manageActivityBlock.payGuarantee.value) === 1}
						>
							<PaymentAndGuaranteeSection
								isEditPaymentSeller={isEditPaymentSeller}
								handleCheckBox={handleCheckBox}
								setPayAndGuaranteeSetPayment={setPayAndGuaranteeSetPayment}
								payment={payment}
								guaranteeConditions={guaranteeConditions}
								returnConditions={returnConditions}
							/>
						</Collapse>
					) : null}

					{isUserPage ||
					(isUserPage && manageActivityBlock.delivery.value === "1") ||
					(!isUserPage &&
						manageActivityBlock.delivery.value === "1" &&
						blockDelivery) ? (
						<Collapse
							title={t("Доставка")}
							isEdit={isEditDeliverySeller}
							onChangeEdit={setIsEditDeliverySeller}
							isUserPage={isUserPage}
							onChangeVisible={onChangeVisible}
							block="delivery"
							isVisible={Number(manageActivityBlock.delivery.value) === 1}
						>
							<DeliverySeller
								isEditDeliverySeller={isEditDeliverySeller}
								setFieldValue={setFieldValue}
								block="delivery"
								onChangeVisible={onChangeVisible}
								deliveryServices={deliveryServices}
								isUserPage={isUserPage}
							/>
						</Collapse>
					) : null}
				</div>
			)}
		</Formik>
	);
};
